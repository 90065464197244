import React from "react"
import PropTypes from "prop-types"

class asagitamaFreeMint extends React.Component {
  state = {
    isTransaction: false,
    isFirstMinted: false,
    wooOfAsagi: [],

    // White List
    isWhiteList: false,
    merkleProof: null,
  }

  async checkWhitelist() {
    if (!this.props.account || !this.props.contract || this.state.isFirstMinted) return

    const uri = `https://ea2eduf711.execute-api.ap-northeast-1.amazonaws.com/pna_merkleproof?slug=asagitama&address=${this.props.account}`

    let isWhiteList = false
    let merkleProof = []

    await fetch(uri)
      .then(response => response.json())
      .then(data => {
        isWhiteList = data.isWhiteList,
        merkleProof = data.merkleProof
      })

    this.setState({
      isWhiteList: isWhiteList,
      merkleProof: merkleProof
    })
  }

  async isFirstMinted() {
    if (!this.props.account || !this.props.contract) return
    const isFirstMinted = await this.props.contract.isFirstMinted(this.props.account)
    this.setState({ isFirstMinted: isFirstMinted })
  }

  async wooOfAsagi() {
    if (!this.props.account || !this.props.contract) return

    let wooOfAsagi = await this.props.contract.wooOfAsagi(this.props.account)
    wooOfAsagi = wooOfAsagi.map(a => Number(a))
    this.setState({ wooOfAsagi: wooOfAsagi })
  }

  // WriteContract
  async firstMint(e) {
    if (!this.props.account || !this.props.mintStart || !this.state.isWhiteList) return
    e.preventDefault()

    const contract = this.props.contract

    let setGasLimit = this.props.walletBalance
    let gasLimit = ''

    gasLimit = await contract.estimateGas.firstMint(this.state.merkleProof, this.state.wooOfAsagi, {
      gasLimit: setGasLimit,
    }).catch(error => {
      console.error(error)
      alert('Failed to get a gas estimate.')
    })

    if (!gasLimit) return

    this.setState(({
      isTransaction: true
    }), async () => {
      try {
        const transaction = await contract.firstMint(this.state.merkleProof, this.state.wooOfAsagi, {})

        console.log(`https://${ this.props.network.name !== 'homestead' ? `${this.props.network.name}.` : '' }etherscan.io/tx/${transaction.hash}`)
        await transaction.wait() // トランザクション完了まで待つ
        this.setState({ isTransaction: false })
        this.viewUpdate()
      } catch (error) {
        console.error(error)
        this.setState({ isTransaction: false })
        alert('MINT failed.')
        this.viewUpdate()
      }
    })
  }

  async initialLoading() {
    await this.isFirstMinted()
  }

  async viewUpdate() {
    await this.checkWhitelist()
    await this.wooOfAsagi()
  }

  async componentDidMount() {
    if (!this.props.account) return

    await this.initialLoading()
    await this.viewUpdate()
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.account) return
    if (this.props.account === prevProps.account) return

    await this.initialLoading()
    await this.viewUpdate()
  }


  render() {
    // mintBtn
    let mintBtn = ''
    if (this.props.mintStart) {
      if (this.state.isFirstMinted) {
        mintBtn = <p className="alert-txt"><span className="marker">Already free minted!</span></p>
      } else if (this.state.isWhiteList) {
        mintBtn = <button className="btn free-mint" onClick={this.firstMint.bind(this)}>MINT</button>
      } else {
        mintBtn = <p className="alert-txt"><span className="marker">You are not included in the snapshot users</span></p>
      }
    } else {
      mintBtn = <p className="alert-txt"><span className="marker">This is before the start of MINT</span></p>
    }

    return <div className={`freemint-area ${this.state.isTransaction ? 'transaction' : ''}`}>
      <div className="container">
        <div className="image">
          <picture>
            <source type="image/webp" srcSet="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/staking/asagitama.webp" />
            <img src="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/staking/asagitama.png" alt="ASAGI魂" loading="lazy" />
          </picture>
        </div>
        <div className="mint-box">
          <p className="txt">2 ASAGI</p>
          <p className="equal">=</p>
          <p className="txt">1 ASAGITAMA<br />FREE MINT</p>
          {mintBtn}
        </div>
      </div>
    </div>
  }
}

asagitamaFreeMint.propTypes = {
  network: PropTypes.object,
  account: PropTypes.string,
  contract: PropTypes.object,
  walletBalance: PropTypes.any,
  mintStart: PropTypes.bool,
}
export default asagitamaFreeMint
