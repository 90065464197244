import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

import ChimeraModal from './chimeraModal'

class peojectGalleryChimerative extends React.Component {
  state = {
    tokens: [],
    page: this.props.page,
    maxPage: Math.floor(1000 / 12) + 1,
    isModalOpen: false,

    modalChimera: {
      number: '',
      nameEn: '',
      nameJp: '',
      descEn: '',
      descJp: '',
    },

    selectedPhase: 0,

    imageBaseUri:
      'https://2zzxb5bijldsvoo6cnvrw3omlvsgzbgdkqqrr6yqnf2mf6kxywia.arweave.net/1nNw9ChKxyq53hNrG23MXWRshMNUIRj7EGl0wvlXxZA',
  }

  setPage(e) {
    const target = e.currentTarget
    const page = target.dataset.page
    if (page > this.state.maxPage) return

    this.setState({ page: page }, () => {
      this.setPageTokens()

      history.pushState({}, '', `?page=${page}`)
    })
  }

  setPhase(phase) {
    let maxPage = 0
    let imageBaseUri = ''
    switch (phase) {
      case 0:
        maxPage = Math.floor(1000 / 12) + 1
        imageBaseUri =
          'https://2zzxb5bijldsvoo6cnvrw3omlvsgzbgdkqqrr6yqnf2mf6kxywia.arweave.net/1nNw9ChKxyq53hNrG23MXWRshMNUIRj7EGl0wvlXxZA'
        break
      case 1:
        maxPage = Math.floor(140 / 12) + 1
        imageBaseUri =
          'https://d378grak81t7wm.cloudfront.net/chimerative_mononoke'
        break
    }
    this.setState(
      {
        selectedPhase: phase,
        page: 0,
        maxPage: maxPage,
        imageBaseUri: imageBaseUri,
      },
      () => {
        this.setPageTokens()
      }
    )
  }

  setPageTokens() {
    const pageSize = 12
    let startNum = 0
    let endNum = 0
    if (this.state.page > 1) {
      const page = this.state.page - 1
      startNum = page * pageSize + 2
      endNum = startNum + pageSize - 1
    } else {
      startNum = 0
      endNum = 12
    }

    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/chimeras/`,
      params: {
        start: startNum,
        end: endNum,
        phase: this.state.selectedPhase,
      },
    })
      .then((response) => {
        const tokens = response.data.chimeras
        this.setState({
          tokens: tokens,
        })
      })
      .catch((error) => {
        console.error('error', error)
      })
  }
  scrollModalToTop() {
    const modal = document.querySelector('.gallery-modal')
    const modalInner = modal.querySelector('.inner')
    modalInner.scrollTop = 0
  }

  openModal(e) {
    this.setModalChimera(e.currentTarget.dataset)
    this.setState({ isModalOpen: true })
    this.scrollModalToTop()
  }

  closeModal() {
    this.setState({ isModalOpen: false })
  }

  setModalChimera(data) {
    const modalChimera = {
      number: data.number,
      nameEn: data.nameen,
      nameJp: data.namejp,
      descEn: data.descen,
      descJp: data.descjp,
    }
    this.setState({ modalChimera: modalChimera })
  }

  componentDidMount() {
    this.setPageTokens()
  }

  render() {
    const chimeras = this.state.tokens.map((chimera, key) => (
      <button
        className="chimera"
        key={key}
        onClick={this.openModal.bind(this)}
        data-number={chimera.token_id}
        data-nameen={chimera.name_en}
        data-namejp={chimera.name_jp}
        data-descen={chimera.desc_en}
        data-descjp={chimera.desc_jp}
      >
        <div className="box-head">
          <div className="image">
            <div
              className="image-inner"
              style={{
                backgroundImage: `url(${this.state.imageBaseUri}/${chimera.token_id}.gif)`,
              }}
            ></div>
          </div>
        </div>
        <div className="box-body">
          <div className="left">
            <p className="number font-en">
              #{('0000' + chimera.token_id).slice(-4)}
            </p>
          </div>
          <div className="right">
            <h2 className="name font-en">
              {chimera.name_en.length > 0
                ? chimera.name_en
                : `#${('0000' + chimera.token_id).slice(-4)}`}
            </h2>
          </div>
        </div>
      </button>
    ))

    const pageNumbers = [...Array(this.state.maxPage)].map((_, i) => (
      <button
        key={i + 1}
        className={`num ${this.state.page == i + 1 ? 'current' : ''}`}
        onClick={this.setPage.bind(this)}
        data-page={i + 1}
      >
        {i + 1}
      </button>
    ))

    return (
      <main className="main gallery">
        <div className="container">
          <section>
            <div className="ttl-area">
              <div className="treat left">
                <img
                  src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/gallery/ttl_treat.svg"
                  alt="treat"
                />
              </div>
              <h2 className="ttl font-en">
                Visual Dictionary of <br className="sp-tablet-only" />
                CHIMERA
              </h2>
              <div className="treat right">
                <img
                  src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/gallery/ttl_treat.svg"
                  alt="treat"
                />
              </div>
            </div>
            <div className="selector">
              <button
                className={`phase ${
                  this.state.selectedPhase === 0 && 'selected'
                }`}
                onClick={() => this.setPhase(0)}
              >
                1st CHIMERAS
              </button>
              <button
                className={`phase  ${
                  this.state.selectedPhase === 1 && 'selected'
                }`}
                onClick={() => this.setPhase(1)}
              >
                Mononoke CHIMERAS
              </button>
            </div>
            <div className="container-inner">
              <div className="chimeras">{chimeras}</div>

              <div className="pagination">
                <nav className="numbers">{pageNumbers}</nav>
                <button
                  className="next"
                  data-page={Number(this.state.page) + 1}
                  onClick={this.setPage.bind(this)}
                >
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/gallery/next_button.svg"
                    alt="next button"
                  />
                </button>
              </div>
            </div>
          </section>

          <ChimeraModal
            imageBaseUri={this.state.imageBaseUri}
            isModalOpen={this.state.isModalOpen}
            closeModal={this.closeModal.bind(this)}
            modalChimera={this.state.modalChimera}
          />
        </div>
      </main>
    )
  }
}

peojectGalleryChimerative.propTypes = {
  baseURI: PropTypes.string,
  page: PropTypes.number,
}
export default peojectGalleryChimerative
