import React from 'react'
import PropTypes from 'prop-types'

let provider, signer, contract
class chimerativeAuctionSetting extends React.Component {
  state = {
    abi: require('./auctionAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: true,
    auctionChimeras: [],
    haveArcana: 0,
    page: this.props.page,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  // auction
  // function setAuction(
  //   uint256 _auctionId,
  //   address _contractAddress,
  //   uint256 _tokenId,
  //   uint256 _startTime,
  //   uint256 _endTime,
  //   uint256 _startArcana,
  //   uint256 _endArcana)
  async setAuction(
    auctionId,
    tokenId,
    startArcana,
    endArcana,
    startUnix,
    endUnix
  ) {
    if (!this.state.isMetaMask) return
    console.log(auctionId)

    await contract.setAuction(
      auctionId,
      '0x41985B01CA68dB8Dc5805B38b4Bc5303A0485264',
      tokenId,
      startUnix,
      endUnix,
      startArcana,
      endArcana
    )
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setState(
      {
        isLoading: true,
        auctionChimeras: [],
      },
      async () => {
        this.setNetwork()
        await this.setProvider()
        await this.checkMetaMask()
        await this.connectWallet()
        this.setState({ isLoading: false })
      }
    )
  }

  async componentDidMount() {
    await this.initialLoading()
    console.log(this.props.auctionDatum)

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async () => {
      await this.initialLoading()
    })
  }

  jstTimefromUnixTime(unixTime) {
    const date = new Date(unixTime * 1000)
    const jstDate = new Date(date.getTime() + 0 * 60 * 60 * 1000)

    return jstDate.toLocaleString('ja-JP')
  }

  render() {
    return (
      <main className="main auction">
        <div className="container">
          <div className="section">
            <div className="section-inner">
              {this.props.auctionDatum.length > 0 ? (
                this.props.auctionDatum.map((auctionData, key) => (
                  <div key={key} style={{ width: '90%', marginTop: '1rem' }}>
                    <p>AUCTION ID: {key}</p>
                    <p>TOKEN ID: {auctionData.token_id}</p>
                    <p>START Arcana: {auctionData.start_arcana}</p>
                    <p>END Arcana: {auctionData.end_arcana}</p>
                    <p>
                      START UNIX:{' '}
                      {this.jstTimefromUnixTime(auctionData.start_unix)}
                    </p>
                    <p>
                      END UNIX: {this.jstTimefromUnixTime(auctionData.end_unix)}
                    </p>
                    <button
                      style={{
                        width: '50%',
                        color: '#fff',
                        backgroundColor: 'var(--main-color)',
                        padding: '0.5rem',
                      }}
                      onClick={this.setAuction.bind(
                        this,
                        key,
                        auctionData.token_id,
                        auctionData.start_arcana,
                        auctionData.end_arcana,
                        auctionData.start_unix,
                        auctionData.end_unix
                      )}
                    >
                      WRITE
                    </button>
                  </div>
                ))
              ) : (
                <React.Fragment></React.Fragment>
              )}
            </div>
          </div>
        </div>
      </main>
    )
  }
}

chimerativeAuctionSetting.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  auctionDatum: PropTypes.array,
}
export default chimerativeAuctionSetting
