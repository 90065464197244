import React from 'react'

let provider, signer
class contractsNew extends React.Component {
  state = {
    account: '',
    network: '',
    abi: [],
    bytecode: '',
    contractAddress: '',
  }

  // Initialize
  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async connectWallet() {
    if (!window.ethereum?.isMetaMask)
      return alert('MetaMaskの利用できる環境でお試しください')

    const connectedNetwork = await provider.getNetwork()
    this.setState({ network: connectedNetwork.name })

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  // デプロイ関係
  changeAbi(e) {
    const file_reader = new FileReader()

    file_reader.addEventListener('load', (e) => {
      const abi = JSON.parse(e.target.result)
      this.setState({
        abi: abi,
      })
    })

    file_reader.readAsText(e.target.files[0])
  }

  changeBytecode(e) {
    const file_reader = new FileReader()

    file_reader.addEventListener('load', (e) => {
      const bytecode = String(e.target.result).replace('\n', '')
      this.setState({
        bytecode: bytecode,
      })
    })

    file_reader.readAsText(e.target.files[0])
  }

  async deployContract() {
    if (this.state.abi.length <= 0 || !this.state.bytecode)
      return alert('ABIとBytecodeを登録してください')

    const factory = new ethers.ContractFactory(
      this.state.abi,
      this.state.bytecode,
      signer
    )
    const contract = await factory.deploy().catch((error) => {
      if (error.message.indexOf('user rejected transaction') != -1) {
        alert('ユーザーによって署名がキャンセルされました')
      } else {
        console.error(error.message)
        alert('デプロイに失敗しました')
      }
    })

    await contract.deployed()
    console.log(`deployed: ${contract.address}`)
    this.setState({
      contractAddress: contract.address,
    })
  }

  async componentDidMount() {
    await this.setProvider()
    await this.connectWallet()
  }

  render() {
    return (
      <div
        className="flex"
        style={{
          flexDirection: 'column',
          gap: '.5rem',
        }}
      >
        <label className="label bold">
          ABIを入力してください(拡張子指定：.json)
        </label>
        <input
          type="file"
          accept="application/json"
          onChange={this.changeAbi.bind(this)}
        />

        <label className="mt30 label bold">
          bytecodeを入力してください(拡張子指定：.bin)
        </label>
        <input
          type="file"
          accept="application/octet-stream"
          onChange={this.changeBytecode.bind(this)}
        />

        <button
          className="mt30 button"
          onClick={this.deployContract.bind(this)}
          disabled={!this.state.network}
        >
          {this.state.network
            ? `${
                this.state.network === 'homestead'
                  ? 'Ethereum'
                  : this.state.network
              }ネットワークで`
            : ''}
          デプロイする
        </button>
        {this.state.contractAddress ? (
          <p>デプロイアドレス：{this.state.contractAddress}</p>
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default contractsNew
