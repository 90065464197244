import React from 'react'
import PropTypes from 'prop-types'
// import axios from 'axios'

let provider, signer, contract

class MintsiteShow extends React.Component {
  state = {
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: this.props.functions.balance_of,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name:
          this.props.functions.owner_of === ''
            ? 'ownerOf'
            : this.props.functions.owner_of,
        outputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name:
          this.props.functions.sale_start === ''
            ? 'saleStart'
            : this.props.functions.sale_start,
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name:
          this.props.functions.mint_limit === ''
            ? 'mintLimit'
            : this.props.functions.mint_limit,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name:
          this.props.functions.minted === ''
            ? 'minted'
            : this.props.functions.minted,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: this.props.functions.symbol,
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name:
          this.props.functions.total_supply === ''
            ? 'totalSupply'
            : this.props.functions.total_supply,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name:
          this.props.functions.current_supply === ''
            ? 'currentSupply'
            : this.props.functions.current_supply,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: this.props.functions.whitelists,
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: this.props.functions.wl_sale_start,
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: this.props.functions.wl_mint_limit,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: this.props.functions.wl_minted,
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'quantity',
            type: 'uint256',
          },
        ],
        name: this.props.functions.mint,
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'quantity',
            type: 'uint256',
          },
        ],
        name: this.props.functions.wl_mint,
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
    ],
    abi1155: require('./1155.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isTransaction: false,
    quantity: 1,
    mintType: 'MINT',

    // コントラクト自体の情報
    symbol: '',
    currentSupply: 0,
    totalSupply: 0,
    price: 0,
    mintLimit: 1,
    isSaleStart: false,

    // コントラクトに紐づくユーザーの情報
    isWhiteList: false,
    balanceOf: 0,
    minted: 0,

    // ユーザーウォレット情報
    account: null,
    walletBalance: 0,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      case 'polygon': // Polygon(MATIC)
        network = {
          id: '0x89',
          name: 'matic',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          if (this.props.lang === 'en') {
            alert('Network has been changed. Please reconnect your wallet.')
          } else {
            alert(
              'ネットワークを変更しました。再度ウォレットを接続してください'
            )
          }
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0],
        },
        () => {
          if (this.props.lang === 'en') {
            console.log(`Connected: ${this.state.account}`)
          } else {
            console.log(`ウォレットに接続しました：${this.state.account}`)
          }
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.props.ercType === 721 ? this.state.abi : this.state.abi1155,
            signer
          )
          this.setInitialData()
          this.viewUpdate()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  // Read Contract
  async walletBalance() {
    provider.getBalance(this.state.account).then((balance) => {
      this.setState({
        walletBalance: balance,
      })
    })
  }

  async balanceOf(address) {
    if (!this.state.account && !address) return

    try {
      let balanceOf =
        this.props.ercType === 721
          ? await contract[this.props.functions.balance_of](address)
          : await contract[this.props.functions.balance_of](
              address,
              this.props.targetTokenIdFrom1155
            )
      balanceOf = balanceOf.toNumber()

      this.setState({
        balanceOf: balanceOf,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async checkSaleStart() {
    if (this.props.functions.sale_start === '')
      return this.setState({ isSaleStart: true })

    try {
      let isSaleStart
      if (this.props.ercType === 721) {
        if (this.state.mintType === 'WL MINT') {
          isSaleStart = await contract[this.props.functions.wl_sale_start]()
          this.setState({
            isWlSaleStart: this.props.functions.sale_start_reverse
              ? !isSaleStart
              : isSaleStart,
          })
        } else {
          isSaleStart = await contract[this.props.functions.sale_start]()
          this.setState({
            isSaleStart: this.props.functions.sale_start_reverse(
              this.props.targetTokenIdFrom1155
            )
              ? !isSaleStart
              : isSaleStart,
          })
        }
      } else {
        isSaleStart = await contract[this.props.functions.sale_start](0)
        this.setState({
          isSaleStart: this.props.functions.sale_start_reverse
            ? !isSaleStart
            : isSaleStart,
        })
      }
    } catch (error) {
      console.error(error)
    }
  }

  async mintLimit() {
    if (this.props.functions.mint_limit === '')
      return this.setState({ mintLimit: 'undefined' })

    try {
      let mintLimit
      if (this.props.ercType === 721) {
        if (this.state.mintType === 'WL MINT') {
          mintLimit = await contract[this.props.functions.wl_mint_limit]()
        } else {
          mintLimit = await contract[this.props.functions.mint_limit]()
        }
      } else {
        mintLimit = await contract[this.props.functions.mint_limit](0)
      }
      mintLimit = mintLimit.toNumber()

      this.setState({
        mintLimit: mintLimit,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async minted() {
    if (this.props.functions.minted === '')
      return this.setState({ minted: 'undefined' })

    try {
      let minted =
        this.props.ercType === 721
          ? await contract[this.props.functions.minted](this.state.account)
          : await contract[this.props.functions.minted](
              this.props.targetTokenIdFrom1155,
              this.state.account
            )
      minted = minted.toNumber()

      this.setState({
        minted: minted,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async setSymbol() {
    try {
      const symbol =
        this.props.ercType === 721
          ? await contract[this.props.functions.symbol]()
          : ''

      this.setState({
        symbol: symbol,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async isWhiteList(address) {
    if (!this.state.account && !address && !this.props.functions.whitelists)
      return

    try {
      let isWhiteList = await contract[this.props.functions.whitelists](address)

      this.setState({
        isWhiteList: isWhiteList,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async setTotalSupply() {
    if (this.props.functions.total_supply === '')
      return this.setState({ totalSupply: 'undefined' })

    try {
      let totalSupply =
        this.props.functions.ercType === 721
          ? await contract[this.props.functions.total_supply]()
          : await contract[this.props.functions.total_supply](
              this.props.targetTokenIdFrom1155
            )
      totalSupply = totalSupply.toNumber()

      this.setState({
        totalSupply: totalSupply,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async setCurrentSupply() {
    if (this.props.functions.current_supply === '')
      return this.setState({ currentSupply: 'undefined' })

    try {
      let currentSupply =
        this.props.functions.ercType === 721
          ? await contract[this.props.functions.current_supply]()
          : await contract[this.props.functions.current_supply](
              this.props.targetTokenIdFrom1155
            )
      currentSupply = currentSupply.toNumber()

      this.setState({
        currentSupply: currentSupply,
      })
    } catch (error) {
      console.error(error)
    }
  }

  // Write Contract
  async mint(e) {
    await this.connectWallet()

    if (!this.state.account) return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      if (this.props.lang === 'en') {
        alert('Please specify the number of MINT')
      } else {
        alert('MINT枚数を指定してください')
      }
      return
    }

    const amount = this.props.mintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (
      ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)
    ) {
      return alert(`資金が不足しています`)
    }

    this.setState(
      {
        isTransaction: true,
      },
      async () => {
        try {
          let transaction
          if (this.props.ercType === 721) {
            transaction = await contract[this.props.functions.mint](quantity, {
              value: ethers.utils.parseEther(payableAmount),
            })
          } else {
            transaction = await contract[this.props.functions.mint](
              this.props.targetTokenIdFrom1155,
              quantity,
              {
                value: ethers.utils.parseEther(payableAmount),
              }
            )
          }

          console.log(
            `https://${
              this.state.network.name !== 'homestead'
                ? `${this.state.network.name}.`
                : ''
            }etherscan.io/tx/${transaction.hash}`
          )
          await transaction.wait() // トランザクション完了まで待つ
          this.setState({
            isTransaction: false,
            quantity: 1,
          })
          this.viewUpdate()
          alert('MINTが完了しました🎉')
        } catch (error) {
          console.error(error)
          this.setState({
            isTransaction: false,
          })
          if (this.props.lang === 'en') {
            alert('MINT failed.')
          } else {
            alert('MINTに失敗しました。')
          }
        }
      }
    )
  }

  // View関連
  viewUpdate() {
    if (!this.props.contractAddress) return

    this.balanceOf(this.state.account)
    this.minted()
    this.setCurrentSupply()
  }

  setInitialData() {
    if (!this.props.contractAddress) return

    this.walletBalance()
    this.setSymbol()
    this.setTotalSupply()
    this.setCurrentSupply()
    this.mintLimit()
    this.isWhiteList(this.state.account)
    this.checkSaleStart()
  }

  // UI関係
  increaseQuantity() {
    let canMintLimit = 0
    canMintLimit = this.state.mintLimit - this.state.minted

    this.setState({
      quantity:
        this.state.quantity < canMintLimit
          ? this.state.quantity + 1
          : canMintLimit,
    })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 1 ? this.state.quantity - 1 : 1
    this.setState({ quantity: quantity })
  }

  async componentDidMount() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', (accounts) => {
      if (this.props.lang === 'en') {
        console.log(`Change Wallet ${accounts[0]}`)
      } else {
        console.log(`ウォレットを切り替えました ${accounts[0]}`)
      }
      this.setState({ account: accounts[0] })
      this.viewUpdate()
    })
  }

  render() {
    let renderDOM = this.state.isMetaMask ? (
      <button className="btn" onClick={this.connectWallet.bind(this)}>
        CONNECT WALLET
      </button>
    ) : (
      <div className="btn">Undefined METAMASK</div>
    )

    let mintBtn = ''
    if (this.state.isSaleStart) {
      if (this.state.mintLimit > this.state.minted) {
        if (this.state.totalSupply > this.state.currentSupply) {
          mintBtn = this.props.contractAddress ? (
            <button
              className="btn"
              onClick={this.mint.bind(this)}
              disabled={this.state.isTransaction}
            >
              {this.state.isTransaction ? 'Transaction now' : 'MINT'}
            </button>
          ) : (
            <span className="btn">MINT</span>
          )
        } else {
          mintBtn = <span className="btn">SOLD OUT</span>
        }
      } else {
        mintBtn = (
          <span className="btn">
            {this.props.lang === 'en' ? 'FULL MINTED' : 'フルミント済み'}
          </span>
        )
      }
    } else {
      mintBtn = (
        <span className="btn">
          {this.props.lang === 'en' ? 'NOT FOR SALE' : '販売期間外です'}
        </span>
      )
    }

    const decreaseBtn = (
      <button
        className="ctn-btn"
        onClick={this.decreaseQuantity.bind(this)}
        disabled={this.state.isTransaction}
      >
        -1
      </button>
    )
    const increaseBtn = (
      <button
        className="ctn-btn"
        onClick={this.increaseQuantity.bind(this)}
        disabled={this.state.isTransaction}
      >
        +1
      </button>
    )
    const quantityCtl = (
      <div className="quantity-ctl">
        {this.state.mintLimit > 1 ? decreaseBtn : <span></span>}
        <p className="quantity">{this.state.quantity}</p>
        {this.state.mintLimit > 1 ? increaseBtn : <span></span>}
      </div>
    )

    if (this.state.isConnected && this.state.totalSupply > 0) {
      renderDOM = (
        <div className="mint-area">
          <p className="supply">
            {this.state.currentSupply} / {this.state.totalSupply}
          </p>
          {this.state.mintLimit > 0 ? quantityCtl : ''}
          <p className="mint-limit">
            {this.props.lang === 'en'
              ? `A maximum of ${this.state.mintLimit}${this.state.symbol} MINTs.`
              : `最大${this.state.mintLimit}${this.state.symbol} MINT行うことができます。`}
            <br />
            {this.props.lang === 'en'
              ? `You owned ${this.state.balanceOf}${this.state.symbol}.`
              : `あなたは${this.state.balanceOf}${
                  this.state.symbol ? this.state.symbol : '枚'
                }所有しています。`}
            （
            {this.props.lang === 'en'
              ? `${this.state.minted}${this.state.symbol} minted.`
              : `${this.state.minted}${
                  this.state.symbol ? this.state.symbol : '枚'
                } MINTしました。`}
            ）
            <br />
            {this.props.lang === 'en'
              ? `The price per piece is ${this.props.mintPrice} ETH.`
              : `1${
                  this.state.symbol ? this.state.symbol : '枚'
                } MINTを行うための価格は、${this.props.mintPrice} ${
                  this.props.network === 'polygon' ? 'MATIC' : 'ETH'
                }です。`}
          </p>
          {mintBtn}
          <p className="address">Your Wallet Address: {this.state.account}</p>
        </div>
      )
    } else if (this.state.isConnected) {
      renderDOM = (
        <div className="mint-area">
          <p className="supply">loading...</p>
        </div>
      )
    }

    return renderDOM
  }
}

MintsiteShow.propTypes = {
  contractAddress: PropTypes.string,
  network: PropTypes.string,
  ercType: PropTypes.number,
  targetTokenIdFrom1155: PropTypes.number,
  mintPrice: PropTypes.number,
  lang: PropTypes.string,
  functions: PropTypes.object,
}
export default MintsiteShow
