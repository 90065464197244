import React from 'react'
import PropTypes from 'prop-types'

import MintModal from './mintModal'
import OpenMetaMaskModal from '../../contract/openMetaMaskModal'

class chimerativeMint extends React.Component {
  state = {
    modalOpen: false,
    totalSupply: 0,
    maxSupply: 0,
  }

  changeTotalSupply(totalSupply) {
    this.setState({ totalSupply: totalSupply })
  }

  changeMaxSupply(maxSupply) {
    this.setState({ maxSupply: maxSupply })
  }

  modalOpen() {
    this.setState({ modalOpen: true })
  }
  modalClose() {
    this.setState({ modalOpen: false })
  }

  render() {
    return (
      <section className="mintarea">
        <picture>
          <source
            type="image/webp"
            media="(max-width: 767px)"
            srcset="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mintarea_mononoke_sp.webp"
          />
          <source
            type="image/webp"
            srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mintarea_mononoke.webp"
          />
          <source
            media="(max-width: 767px)"
            srcset="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mintarea_mononoke_sp.png"
          />
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mintarea_mononoke.png"
            alt="MINT AREA"
          />
        </picture>
        <div className="btn-ctl">
          <button className="btn" onClick={this.modalOpen.bind(this)}>
            <picture>
              <source
                type="image/webp"
                srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_mononoke.webp"
              />
              <img
                src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_mononoke.png"
                alt="MINT BUTTON"
              />
            </picture>
          </button>
          <p className="size">
            {this.state.totalSupply.toLocaleString()}/
            {this.state.maxSupply.toLocaleString()}
          </p>
        </div>
        <MintModal
          modalOpen={this.state.modalOpen}
          modalCloseFunc={this.modalClose.bind(this)}
          changeMaxSupply={this.changeMaxSupply.bind(this)}
          changeTotalSupply={this.changeTotalSupply.bind(this)}
          network={this.props.network}
          contractAddress={this.props.contractAddress}
          saleContractAddress={this.props.saleContractAddress}
          wlMintPrice={this.props.wlMintPrice}
          mintPrice={this.props.mintPrice}
        />
        <OpenMetaMaskModal deepLink="https://metamask.app.link/dapp/play-nft.art/mints/chimerative" />
      </section>
    )
  }
}

chimerativeMint.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  saleContractAddress: PropTypes.string,
  wlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
}
export default chimerativeMint
