import React from "react"
import PropTypes from "prop-types"

class peojectGallery extends React.Component {
  state = {
    tokens: [],
    fetchSize: 20,
    isLoading: true,
    keepFindSize: 20,
    isFinding: false,
    findNumber: null,
  }

  changeFindNumber(e) {
    const value = Number(e.currentTarget.value)
    if (value < 0 || value > this.props.tokenCount - 1) return

    this.setState({ findNumber: value !== null ? value : null })
  }

  async findNumberToken() {
    if (this.state.findNumber !== 0 && !this.state.findNumber) return
    const tokens = []
    const token = await this.fetchTokenData(this.state.findNumber)
    tokens.push(token)
    this.setState({
      tokens: tokens,
      isFinding: true,
      keepFindSize: this.state.tokens.length < this.state.keepFindSize ? this.state.keepFindSize : this.state.tokens.length
    })
  }

  resetFindToken() {
    this.setState(({
      tokens: [],
      isFinding: false,
      findNumber: null
    }), async () => {
      await this.setTokens(this.state.keepFindSize)
    })
  }

  async fetchTokenData(id) {
    const tokenURI = `${this.props.jsonUri}/${id}.json`
    const token = await fetch(tokenURI)
    .then(response => response.json())
      .then(data => {

        const token = {
          id: id,
          osUri: `https://opensea.io/assets/ethereum/0xdd510ce28dfd085d9cf58f5402ca6d63985e83c0/${id}`,
          imageUri: data.image,
          name: data.name,
        }
        return token
    })
    .catch(err => {
      console.error(err)
    })

    return token
  }

  async setTokens(size) {
    const startId = this.state.tokens.length
    const tokens = this.state.tokens
    for (let i = 0; i < size; i++) {
      const token = await this.fetchTokenData(startId + i)
      tokens.push(token)
    }

    const uniqTokens = Array.from(new Map(tokens.map((token) => [token.id, token])).values())

    this.setState({
      tokens: uniqTokens,
      isLoading: false,
    })
  }

  moreTokens() {
    this.setState({isLoading: true})
    this.setTokens(this.state.fetchSize)
  }

  componentDidMount() {
    this.setTokens(this.state.fetchSize)
  }

  render() {
    const findArea = <div className="find-area">
      <input type="number" className="find-input" onChange={this.changeFindNumber.bind(this)} min="0" max={this.props.tokenCount - 1} value={this.state.findNumber ? this.state.findNumber : 0} />
      <button className="find-btn" onClick={this.findNumberToken.bind(this)} >find</button>
      {this.state.isFinding ? <button className="find-reset-btn" onClick={this.resetFindToken.bind(this)}>reset</button> : ''}
    </div>

    const tokens = this.state.tokens.map((token, key) =>
      <a className="token"
        href={token.osUri}
        target="_blank"
        rel="nofollow noreferrer noopener"
        key={key}
      >
        <div className="image" style={{ backgroundImage: `url(${token.imageUri})` }}></div>
        <h2 className="name">{token.name}</h2>
      </a>)

    const loading = <div className="loading">Loading...</div>

    const moreButton = this.state.tokens.length < (1500 - this.state.fetchSize) && !this.state.isFinding ? <button className="moreBtn" onClick={this.moreTokens.bind(this)}>more</button> : ''

    return <div className="tokens">
      {findArea}
      <div className="container">
        {tokens}
        <div className="more-area">
          {this.state.isLoading && !this.state.isFinding ? loading : ''}
          {moreButton}
        </div>
      </div>
    </div>;
  }
}

peojectGallery.propTypes = {
  tokenCount: PropTypes.number,
  jsonUri: PropTypes.string,
}
export default peojectGallery
