import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class ProjectFormsChecks extends React.Component {
  state = {
    isMetaMask: false,
    isConnected: false,

    // ユーザーウォレット情報
    account: null,
    isWhiteList: false,
  }


  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(({
        isConnected: true,
        account: accounts[0],
      }), () => {
        if (this.props.lang === 'en') {
          console.log(`Connected: ${this.state.account}`)
        } else {
          console.log(`ウォレットに接続しました：${this.state.account}`)
        }

        this.checkWhiteList()
      })
    } catch (error) {
      console.error(error)
    }
  }

  // API
  checkWhiteList() {
    if (!this.state.account) return

    axios({
      method: 'GET',
      url: `${this.props.baseURI}api/v1/projects/forms/${this.props.pid}/elected_address`,
      params: {
        address: this.state.account.toLowerCase(),
      }
    }).then((response) => {
      this.setState(({ isWhiteList: response.data }), () => {
        this.setisWLView()
      })
    }).catch((error) => {
      console.log(error.response.data)
      this.setState({ isWhiteList: false })
    })
  }

  // View
  setisWLView() {
    const lists = document.querySelectorAll('.list')
    lists.forEach(list => {
      list.classList.remove('check')
    })

    if (!this.state.isWhiteList) return

    const accountDOM = document.querySelector(`[data-account="${this.state.account}"`)
    if (!accountDOM) return
    accountDOM.classList.add('check')
  }

  async componentDidMount() {
    await this.checkMetaMask()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', (accounts) => {
    if (this.props.lang === 'en') {
        console.log(`Change Wallet ${accounts[0]}`)
      } else {
        console.log(`ウォレットを切り替えました ${accounts[0]}`)
      }
      this.setState(({ account: accounts[0] }), () => {
        this.checkWhiteList()
      })
    })
  }

  render() {
    let connectButton = <div className="connect-wallet" onClick={this.connectWallet.bind(this)}>{this.state.isMetaMask ? 'Connect Wallet' : 'Undefined MetaMask'}</div>

    if (this.state.account) {
      connectButton = <div className="connect-wallet">{this.state.account.length >= 10 ? this.state.account.slice(0, 7) + '...' : this.state.account}</div>
    }

    return connectButton
  }
}

ProjectFormsChecks.propTypes = {
  baseURI: PropTypes.string,
  pid: PropTypes.string,
  lang: PropTypes.string,
}
export default ProjectFormsChecks
