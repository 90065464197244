import React from 'react'
import PropTypes from 'prop-types'

const baseUri =
  'https://generative-outputs.s3.ap-northeast-1.amazonaws.com/chimerative_mononoke'
const unrevealedImageUri = `${baseUri}/unrevealed.gif`
class chimerativeAuctionCard extends React.Component {
  state = {
    image: unrevealedImageUri,
  }

  async fetchImage() {
    if (
      this.props.chimera.contractAddress ===
      '0x0000000000000000000000000000000000000000'
    )
      return this.setState({ image: unrevealedImageUri })

    await fetch(
      `${baseUri}/${this.props.chimera.tokenId}.json?t=${Math.floor(
        Date.now() / 1000
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ image: data.image })
      })
      .catch(() => {
        this.setState({
          image: unrevealedImageUri,
        })
      })
  }

  componentDidMount() {
    this.fetchImage()
  }
  componentDidUpdate(prevProps) {
    if (prevProps.chimera !== this.props.chimera) {
      this.fetchImage()
    }
  }

  render() {
    return (
      <a
        href={`/chimerative/auction/${this.props.chimera.auctionId}`}
        className="chimera-card"
        data-turbolinks-track="reload"
      >
        <div className="number">
          <div className="treat left"></div>
          <h2 className="txt">
            {this.props.chimera.contractAddress ===
            '0x0000000000000000000000000000000000000000'
              ? 'XXXX'
              : `#${this.props.chimera.auctionId.toString().padStart(4, '0')}`}
          </h2>
          <div className="treat right"></div>
        </div>
        <div
          className="chimera-image"
          style={{
            backgroundImage: `url(${this.state.image})`,
          }}
        ></div>
        {this.props.chimera.contractAddress ===
        '0x0000000000000000000000000000000000000000' ? (
          <div className="use-arcana">
            <p className="num">UNKNOWN</p>
          </div>
        ) : (
          <div className="use-arcana">
            <p className="num">
              {this.props.chimera.isStart && !this.props.chimera.isFinish
                ? this.props.chimera.currentArcana.toLocaleString()
                : !this.props.chimera.isFinish
                ? 'BEFORE START'
                : 'FINISH'}
            </p>
            {this.props.chimera.isStart && !this.props.chimera.isFinish ? (
              <p className="unit">ARCANA</p>
            ) : (
              <React.Fragment></React.Fragment>
            )}
          </div>
        )}
      </a>
    )
  }
}

chimerativeAuctionCard.propTypes = {
  chimera: PropTypes.object,
}
export default chimerativeAuctionCard
