import React from 'react'

class chimerativeMintFinish extends React.Component {
  render() {
    return (
      <div className="inner">
        <h2 className="logo">
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/logo_full_color.svg"
            alt="Chimerative Monsters"
          />
        </h2>
        <div className="txt-box">
          <p className="message" style={{ fontSize: '3rem', lineHeight: 1.4 }}>
            THANK YOU
            <br />
            SOLD OUT
          </p>
          <p className="message">The story is still going on...</p>
        </div>
      </div>
    )
  }
}

export default chimerativeMintFinish
