import React from "react"
import PropTypes from "prop-types"

let provider, signer, contract;

class ProjectKLPC extends React.Component {
  state = {
    abi: [
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ModeA",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ModeB",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ModeC",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ModeD",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "name": "mapTokenMode",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "_totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [
          {
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
          }
        ],
        "name": "ownerOf",
        "outputs": [
          {
            "internalType": "address",
            "name": "",
            "type": "address"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
      {
        "inputs": [],
        "name": "totalSupply",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }
    ],
    network: '',
    isMetaMask: false,
    isConnected: false,
    totalSupply: 0,
    balanceOf: 0,

    // ユーザーウォレット情報
    account: null,
    items: [],
  };

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        };
        break;
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        };
        break;
      default:
        network = {}
    }

    this.setState({ network: network });
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, "any");

      provider.on("network", (_, oldNetwork) => {
        if (oldNetwork) window.location.reload();
      });
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return;

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: this.state.network.id,
        }]
      }).then(() => {
        if (this.props.lang === 'en') {
          alert('Network has been changed. Please reconnect your wallet.');
        } else {
          alert('ネットワークを変更しました。再度ウォレットを接続してください');
        }
      });
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts;

      this.setState(({
        isConnected: true,
        account: accounts[0].toLowerCase(),
      }), () => {
        console.log(`ウォレットに接続しました：${this.state.account}`);
        if (!this.props.contractAddress) return;

        contract = new ethers.Contract(this.props.contractAddress, this.state.abi, signer);
        this.viewUpdate();
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Read Contract
  async balanceOf(address) {
    if (!this.state.account && !this.props.contractAddress) return;

    try {
      let balanceOf = await contract.balanceOf(address);
      balanceOf = balanceOf.toNumber()

      this.setState({ balanceOf: balanceOf })
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  async totalSupply() {
    if (!this.state.account && !this.props.contractAddress) return;

    try {
      let totalSupply = await contract.totalSupply();
      totalSupply = totalSupply.toNumber()

      this.setState({ totalSupply: totalSupply })
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  async ownerOf(tokenId) {
    if (!this.state.account && !this.props.contractAddress) return;

    try {
      const ownerOf = await contract.ownerOf(tokenId);

      return ownerOf.toLowerCase();
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  async setImageURI(tokenId) {
    if (!this.state.account && !this.props.contractAddress) return;

    try {
      const tokenMode = await contract.mapTokenMode(tokenId);
      let num = tokenMode.toNumber();
      num = num === 0 ? 1 : num;
      tokenId = ('000' + tokenId).slice(-3);
      let mode = '';
      switch (num) {
        case 1:
          mode = 'A'
        break
        case 2:
          mode = 'B'
        break
        case 3:
          mode = 'C'
        break
        case 4:
          mode = 'D'
        break
      }
      const imageUri = `https://soco-engineering.com/KLPC/${mode}/${tokenId}-${num}.png`
      return imageUri;
    } catch (error) {
      console.error(error);
      return '';
    }
  }

  async setMyItems() {
    if (!this.state.account && !this.props.contractAddress) return;

    try {
      let items = [];
      [...Array(this.state.totalSupply)].map(async (_, i) => {
        if (i !== 0) {
          const owner = await this.ownerOf(i)
          if (this.state.account === owner) {
            const imageURI = await this.setImageURI(i)
            items.push({id: i, image: imageURI})
            this.setState({ items: items })
          }
        }
      })
    } catch (error) {
      console.error(error);
      return 0;
    }
  }

  async modeChange(e) {
    if (!this.state.account && !this.props.contractAddress) return;
    e.preventDefault();

    const target = e.currentTarget
    const nextMode = Number(target.dataset.mode)
    const tokenId = Number(target.dataset.id)

    let func = ''

    switch (nextMode) {
      case 1:
        func = 'ModeA'
      break;
      case 2:
        func = 'ModeB'
      break;
      case 3:
        func = 'ModeC'
      break;
      case 4:
        func = 'ModeD'
      break;
    }

    const gasLimit = await contract.estimateGas[func](tokenId, {
      gasLimit: this.state.gasLimit,
    }).catch(error => {
      console.error(error);
      alert('ガスの見積もりに失敗しました')
    })
    if (!gasLimit) return;

    this.setState(({
      isTransaction: true
    }), async () => {
      try {
        const transaction = await contract[func](tokenId, {
          gasLimit: gasLimit._hex,
        })

        console.log(`https://${this.state.network.name}.etherscan.io/tx/${transaction.hash}`);
        await transaction.wait(); // トランザクション完了まで待つ

        this.setState(({
          isTransaction: false,
        }), () => {
          this.viewUpdate();
        });
      } catch (error) {
        console.error(error);
        this.setState({ isTransaction: false });
        if (this.props.lang === 'en') {
          alert('Style Change failed.');
        } else {
          alert('着せ替えに失敗しました');
        }
      }
    });
  }

  async componentDidMount() {
    this.setNetwork();
    await this.setProvider();
    await this.checkMetaMask();
    await this.connectWallet();

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      if (this.props.lang === 'en') {
        console.log(`Change Wallet ${accounts[0]}`)
      } else {
        console.log(`ウォレットを切り替えました ${accounts[0]}`)
      }
      this.setState(({ account: accounts[0].toLowerCase() }), () => {
        this.viewUpdate();
      })
    });
  }

  // View関連
  async viewUpdate() {
    if (!this.props.contractAddress) return
    await this.totalSupply()
    await this.balanceOf(this.state.account)
    await this.setMyItems()
  }

  galleryShow(e) {
    const target = e.currentTarget;
    const item = target.parentNode.parentNode.parentNode;
    const styles = item.querySelector('.styles');
    styles.classList.toggle('show');
  }

  render() {
    let items = '';

    if (this.state.items.length > 0) {
      const item = this.state.items.map((item, key) => <div className={`item ${this.state.isTransaction ? 'loading' : ''}`} key={key}>
        <div className="controller">
        <div className="image">
            <img src={item.image} alt={`tokenId ${item.id}`} className="img" />
            <button className="show-styles" onClick={this.galleryShow.bind(this)}>All Styles</button>
          </div>
        <div className="buttons" style={{ backgroundImage: `url('https://soco-engineering.com/KLPC/palettes/${item.id}.png')` }}>
            <button className="button" onClick={this.modeChange.bind(this)} data-mode="1" data-id={item.id} disabled={this.state.isTransaction}></button>
            <button className="button" onClick={this.modeChange.bind(this)} data-mode="2" data-id={item.id} disabled={this.state.isTransaction}></button>
            <button className="button" onClick={this.modeChange.bind(this)} data-mode="3" data-id={item.id} disabled={this.state.isTransaction}></button>
            <button className="button" onClick={this.modeChange.bind(this)} data-mode="4" data-id={item.id} disabled={this.state.isTransaction}></button>
          </div>
        </div>
        <div className="styles">
          <img className="img" src={`https://soco-engineering.com/KLPC/A/${('000' + item.id).slice(-3)}-1.png`} alt="mode A" />
          <img className="img" src={`https://soco-engineering.com/KLPC/B/${('000' + item.id).slice(-3)}-2.png`} alt="mode B" />
          <img className="img" src={`https://soco-engineering.com/KLPC/C/${('000' + item.id).slice(-3)}-3.png`} alt="mode C" />
          <img className="img" src={`https://soco-engineering.com/KLPC/D/${('000' + item.id).slice(-3)}-4.png`} alt="mode D" />
        </div>
      </div>)

      items = <div className="items">{item}</div>
    } else {
      items = <div className="items">
        <div className="item">
          <div className="controller">
            <div className="image">
              <img src="https://d2tnrjjpld3h2t.cloudfront.net/klpc/assets/cover.png" alt="KLPCカバー画像" className="img" />
            </div>
            <div className="buttons" style={{backgroundImage: `url("https://d2tnrjjpld3h2t.cloudfront.net/klpc/assets/pallet.png")`}}>
              <div className="button"></div>
              <div className="button"></div>
              <div className="button"></div>
              <div className="button"></div>
            </div>
          </div>
        </div>
      </div>;
    }

    return items;
  }
}

ProjectKLPC.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
};
export default ProjectKLPC
