import React from 'react'
import PropTypes from 'prop-types'

let provider, signer, contract

class ProjectFormsConnectBtn extends React.Component {
  state = {
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    network: '',
    isMetaMask: false,
    isConnected: false,
    balanceOf: 0,

    // ユーザーウォレット情報
    account: null,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      case 'polygon': // Polygon(MATIC)
        network = {
          id: '0x89',
          name: 'matic',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          if (this.props.lang === 'en') {
            alert('Network has been changed. Please reconnect your wallet.')
          } else {
            alert(
              'ネットワークを変更しました。再度ウォレットを接続してください'
            )
          }
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0],
        },
        async () => {
          console.log(`Connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          // // ウォレットを検証
          // const address = this.state.account
          // const { chainId } = await provider.getNetwork();
          // const ens = null

          // const message = await fetch('/siwe/siwe_message', {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     'X-CSRF-Token': this.getCSRF(),
          //   },
          //   credentials: 'include',
          //   body: JSON.stringify({
          //     chainId,
          //     address,
          //   })
          // }).then((res) => res.text());

          // const signature = await provider.getSigner().signMessage(message);

          // fetch(`/siwe/siwe_sign_in`, {
          //   method: 'POST',
          //   headers: {
          //     'Content-Type': 'application/json',
          //     'X-CSRF-Token': this.getCSRF(),
          //   },
          //   credentials: 'include',
          //   body: JSON.stringify({ signature, ens }),
          // }).then(async (res) => {
          //   if (res.status === 200) {
          //     res.json().then(({ lastSeen, address, ens }) => {
          //       console.log(lastSeen, ens, address);
          //       connectedState(lastSeen, address, ens);
          //       return;
          //     });
          //   } else {
          //     res.json().then((err) => {
          //       console.error(err);
          //     });
          //   }
          // });

          // // ここまで

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
          this.viewUpdate()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  // Read Contract
  async balanceOf(address) {
    if (!this.state.account && !address) return

    try {
      let balanceOf = await contract.balanceOf(address)
      balanceOf = balanceOf.toNumber()

      this.setState(
        {
          balanceOf: balanceOf,
        },
        () => {
          const balanceOf = document.querySelector('#balanceOf')
          const form = document.querySelector('#form')
          balanceOf.innerText = this.state.balanceOf
          if (this.state.balanceOf > 0) {
            form.style.display = 'flex'
          } else {
            form.style.display = 'none'
          }
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async componentDidMount() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', (accounts) => {
      if (this.props.lang === 'en') {
        console.log(`Change Wallet ${accounts[0]}`)
      } else {
        console.log(`ウォレットを切り替えました ${accounts[0]}`)
      }
      this.setState({ account: accounts[0] }, () => {
        this.balanceOf(this.state.account)
        this.formChange(this.state.account)
      })
    })
  }

  // View関連
  viewUpdate() {
    if (!this.props.contractAddress) return

    this.balanceOf(this.state.account)
    this.formChange(this.state.account)
  }

  formChange(address) {
    const addressForm = document.querySelector('#wallet_address')
    addressForm.value = address

    const connectMessage = document.querySelector('#connectMessage')
    connectMessage.style.display = 'none'
  }

  render() {
    let connectButton = (
      <div className="connect-wallet" onClick={this.connectWallet.bind(this)}>
        {this.state.isMetaMask ? 'Connect Wallet' : 'Undefined MetaMask'}
      </div>
    )

    if (this.state.account) {
      connectButton = (
        <div className="connect-wallet">
          {this.state.account.length >= 10
            ? this.state.account.slice(0, 7) + '...'
            : this.state.account}
        </div>
      )
    }

    return connectButton
  }
}

ProjectFormsConnectBtn.propTypes = {
  contractAddress: PropTypes.string,
  network: PropTypes.string,
  lang: PropTypes.string,
}
export default ProjectFormsConnectBtn
