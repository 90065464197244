import React from 'react'
import PropTypes from 'prop-types'

let provider, signer, contract

class ProjectFormsList extends React.Component {
  state = {
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    network: '',
    isMetaMask: false,
    isConnected: false,

    // ユーザーウォレット情報
    account: null,

    // 抽選結果
    targetAccounts: [],
    lotteryAccounts: [],
    lotteryNum: 1,

    // アドレスとTwitterIDの紐付け
    users: {},
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      case 'polygon': // Polygon(MATIC)
        network = {
          id: '0x89',
          name: 'matic',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          if (this.props.lang === 'en') {
            alert('Network has been changed. Please reconnect your wallet.')
          } else {
            alert(
              'ネットワークを変更しました。再度ウォレットを接続してください'
            )
          }
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0],
        },
        () => {
          console.log(`ウォレットに接続しました：${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
          this.viewUpdate()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  // Read Contract
  async balanceOf(address) {
    if (!this.state.account && !address) return

    try {
      let balanceOf = await contract.balanceOf(address)
      balanceOf = balanceOf.toNumber()

      const dom = document.querySelector(`#address-${address}`)
      if (!dom) return

      const balanceDom = dom.querySelector('.balance')
      balanceDom.innerText = `${balanceOf}枚 保有`

      return balanceOf
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async componentDidMount() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', (accounts) => {
      if (this.props.lang === 'en') {
        console.log(`Change Wallet ${accounts[0]}`)
      } else {
        console.log(`ウォレットを切り替えました ${accounts[0]}`)
      }
    })
  }

  // View関連
  viewUpdate() {
    if (!this.props.contractAddress) return

    this.total()
  }

  total() {
    const usersAddress = document.querySelectorAll('.userAddress')
    let totalTokenNum = 0
    let targetAccounts = []
    usersAddress.forEach(async (address) => {
      const balanceOf = await this.balanceOf(address.textContent)

      const twitterId = address.parentNode.querySelector('a').textContent
      const discordId = address.parentNode.querySelector('.discord').textContent
      const users = this.state.users
      users[address.textContent] = discordId
        ? `${twitterId} ${discordId}`
        : twitterId

      totalTokenNum += balanceOf
      const totalTokens = document.querySelector(`#totalTokens`)
      totalTokens.textContent = totalTokenNum

      const account = {
        count: balanceOf,
        address: address.textContent,
        users: users,
      }

      targetAccounts.push(account)
    })
    this.setState({ targetAccounts: targetAccounts })
  }

  changeLotteryNum(e) {
    this.setState({ lotteryNum: e.currentTarget.value })
  }

  randomNum(max) {
    const min = 0

    return Math.floor(Math.random() * (max + 1 - min)) + min
  }

  uniqArr(arr) {
    return [...new Set(arr)]
  }

  setLotteryAccounts(lotteryAccount) {
    if (this.state.lotteryAccounts.includes('lotteryAccounts')) return

    let lotteryAccounts = this.state.lotteryAccounts
    lotteryAccounts.push(lotteryAccount)
    this.setState({ lotteryAccounts: this.uniqArr(lotteryAccounts) })
  }

  lottery() {
    if (this.state.lotteryNum <= 0)
      return alert('抽選数は1以上に設定してください')

    let flattenAddress = []
    this.state.targetAccounts.forEach((account) => {
      ;[...Array(account.count)].map((_) =>
        flattenAddress.push(account.address)
      )
    })

    // リセット処理
    const answers = document.querySelectorAll('.answer')
    answers.forEach((answer) => {
      if (!answer || !answer.classList.contains('red')) return

      answer.classList.remove('red')
    })

    // 抽選開始
    this.setState({ lotteryAccounts: [] }, () => {
      while (
        this.uniqArr(this.state.lotteryAccounts).length < this.state.lotteryNum
      ) {
        const num = this.randomNum(flattenAddress.length - 1)
        const address = flattenAddress[num]
        this.setLotteryAccounts(address)
      }

      this.state.lotteryAccounts.forEach((address) => {
        const dom = document.querySelector(`#address-${address}`)
        if (!dom) return

        dom.classList.add('red')
      })

      console.log('当選アドレス：', this.state.lotteryAccounts)
    })
  }

  render() {
    let connectButton = (
      <button className="btn" onClick={this.connectWallet.bind(this)}>
        {this.state.isMetaMask ? 'Connect Wallet' : 'Undefined MetaMask'}
      </button>
    )

    const lotteryDom =
      this.state.lotteryAccounts.length > 0
        ? this.state.lotteryAccounts.map((address, key) => (
            <p className="address" key={key}>
              {this.state.users[address]} / {address}
            </p>
          ))
        : ''

    if (this.state.account) {
      connectButton = (
        <div>
          <div className="flex gap_5">
            <input
              type="number"
              className="num"
              onChange={this.changeLotteryNum.bind(this)}
              min="1"
              defaultValue={this.state.lotteryNum}
            />
            <button className="btn" onClick={this.lottery.bind(this)}>
              抽選
            </button>
          </div>
          <hr className="mt20 mb20" />
          <p className="txt">【当選アドレス】</p>
          {lotteryDom}
        </div>
      )
    }

    return connectButton
  }
}

ProjectFormsList.propTypes = {
  contractAddress: PropTypes.string,
  network: PropTypes.string,
  lang: PropTypes.string,
}
export default ProjectFormsList
