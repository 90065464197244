import React from "react"
import PropTypes from "prop-types"

class asagitamaStakingMint extends React.Component {
  state = {
    isShow: false,
    isTransaction: false,
    stakeStart: false,
    maxSupply: 3000,
    tokenCount: 0,
    maxMintLimit: 6,
    minted: 0,
    quantity: 0,
    wooOfAsagi: [],

    // staking関係
    stakeMintCost: 30,
    dailyPointMap: 0,
    checkStakePoint: 0,
    checkStakePointSec: 0,
    daySecond: 86400,

    // Staking開始を開始したばかりの際にフロント側で調整
    isStaked: false,
  }

  async tokenCount() {
    const tokenCount = await this.props.contract.tokenCount()
    this.setState({ tokenCount: Number(tokenCount) })
  }

  async minted() {
    const minted = await this.props.contract.minted(this.props.account)
    this.setState({ minted: Number(minted) })
  }

  // Staking関係
  async stakeStart() {
    // 全体として、ステーキング機能が利用開始かを判別
    const stakeStart = await this.props.contract.stakeStart()
    this.setState({ stakeStart: stakeStart })
  }

  secToDay(sec) {
    return Math.floor(sec / this.state.daySecond)
  }

  async stakeMintCost() {
    let stakeMintCost = await this.props.contract.stakeMintCost()
    stakeMintCost = Number(stakeMintCost)

    this.setState({ daySecond: Math.floor(stakeMintCost / 30) }, () => {
      const smc = Math.floor(stakeMintCost / this.state.daySecond)

      this.setState({ stakeMintCost: smc })
    })
  }

  async dailyPointMap() {
    let dailyPointMap = await this.props.contract.dailyPointMap(this.props.account)
    dailyPointMap = Number(dailyPointMap)
    dailyPointMap = this.secToDay(dailyPointMap)

    this.setState({ dailyPointMap: dailyPointMap })
  }

  async checkStakePoint() {
    let checkStakePoint = await this.props.contract.checkStakePoint(this.props.account, this.state.wooOfAsagi)
    const checkStakePointSec = Number(checkStakePoint)

    checkStakePoint = this.secToDay(checkStakePointSec)

    this.setState({
      checkStakePoint: checkStakePoint,
      checkStakePointSec: checkStakePointSec,
      isStaked: checkStakePointSec > 0 || this.state.isStaked ? true : false
    })
  }

  stockSize() {
    const stockSize = this.state.dailyPointMap / this.state.stakeMintCost
    if (isNaN(stockSize)) return 0

    return Math.floor(stockSize)
  }

  // write
  async stake(e) {
    if (!this.props.account || !this.state.stakeStart) return
    e.preventDefault()

    const contract = this.props.contract

    let setGasLimit = this.props.walletBalance
    let gasLimit = ''

    gasLimit = await contract.estimateGas.stake(this.state.wooOfAsagi, {
      gasLimit: setGasLimit,
    }).catch(error => {
      console.error(error)
      alert('Failed to get a gas estimate.')
    })

    if (!gasLimit) return

    this.setState(({
      isTransaction: true
    }), async () => {
      try {
        const transaction = await contract.stake(this.state.wooOfAsagi, {})

        console.log(`https://${ this.props.network.name !== 'homestead' ? `${this.props.network.name}.` : '' }etherscan.io/tx/${transaction.hash}`)
        await transaction.wait() // トランザクション完了まで待つ
        this.setState({
          isTransaction: false,
          isStaked: true
        })
        console.log('Stake Success.')
        await this.viewUpdate()
      } catch (error) {
        console.error(error)
        this.setState({ isTransaction: false })
        alert('Stake failed.')
        this.viewUpdate()
      }
    })
  }

  async stakeMint(e) {
    if (!this.props.account || !this.state.stakeStart) return
    if (this.state.quantity < 1) return alert('Specify the number of pieces as 1 or more.')
    e.preventDefault()

    const contract = this.props.contract

    let setGasLimit = this.props.walletBalance
    let gasLimit = ''

    gasLimit = await contract.estimateGas.stakeMint(this.state.quantity, {
      gasLimit: setGasLimit,
    }).catch(error => {
      console.error(error)
      alert('Failed to get a gas estimate.')
    })

    if (!gasLimit) return

    this.setState(({
      isTransaction: true
    }), async () => {
      try {
        const transaction = await contract.stakeMint(this.state.quantity, {
          gasLimit: gasLimit,
        })

        console.log(`https://${ this.props.network.name !== 'homestead' ? `${this.props.network.name}.` : '' }etherscan.io/tx/${transaction.hash}`)
        await transaction.wait() // トランザクション完了まで待つ
        this.setState({ isTransaction: false })
        console.log('stakeMint Success.')
        this.viewUpdate()
      } catch (error) {
        console.error(error)
        this.setState({ isTransaction: false })
        alert('stakeMint failed.')
        this.viewUpdate()
      }
    })
  }

  // ASAGI
  async wooOfAsagi() {
    if (!this.props.account || !this.props.contract) return

    const wooOfAsagi = await this.props.contract.wooOfAsagi(this.props.account)
    this.setState({ wooOfAsagi: wooOfAsagi })
  }


  // View
  async increaseQuantity() {
    if (this.state.quantity >= this.state.maxMintLimit) return
    if (this.state.quantity >= (this.state.maxSupply - this.state.tokenCount)) return
    if (this.state.quantity >= this.stockSize()) return

    const canMintLimit = this.state.maxMintLimit - this.state.minted
    this.setState({ quantity: this.state.quantity < canMintLimit ? this.state.quantity + 1 : canMintLimit })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  async viewUpdate() {
    await this.stakeMintCost()
    await this.dailyPointMap()
    await this.stakeStart()
    await this.wooOfAsagi()
    await this.checkStakePoint()
    await this.tokenCount()
    await this.minted()

    this.setState({
      quantity: 0,
      isShow: true
    })
  }

  async componentDidMount() {
    if (!this.props.account) return

    await this.viewUpdate()
  }

  async componentDidUpdate(prevProps) {
    if (!this.props.account) return
    if (this.props.account === prevProps.account) return

    await this.viewUpdate()
  }


  render() {
    let stocks = ''
    let stockMore = ''
    const stockSize = this.stockSize()
    if (stockSize > 0 && stockSize <= 6) {
      stocks = [...Array(stockSize)].map((_, key) => <div className="stock" key={key}></div>)
    } else if (stockSize > 6) {
      stocks = [...Array(6)].map((_, key) => <div className="stock" key={key}></div>)
      stockMore = <p className="num">...</p>
    } else if (stockSize <= 0) {
      stockMore = <p className="num">None</p>
    }

    const mintStartArea = this.state.isShow && this.stockSize() > 0 && this.state.minted < this.state.maxMintLimit  ? <React.Fragment>
      <div className="quantity-ctl">
        <button className="decreaseBtn" onClick={this.decreaseQuantity.bind(this)}>
          <img src="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/icon/arrow_left.svg" alt="decrease button" loading="lazy" />
        </button>
        <p className="quantity">{this.state.quantity}</p>
        <button className="increaseBtn" onClick={this.increaseQuantity.bind(this)}>
          <img src="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/icon/arrow_right.svg" alt="increase button" loading="lazy" />
        </button>
      </div>
      {this.state.tokenCount < this.state.maxSupply ? <button className="btn mint" onClick={this.stakeMint.bind(this)}>MINT</button> : ''}
    </React.Fragment> : ''

    let stakingStartButton = ''
    if (this.state.isShow && this.state.stakeStart) {
      stakingStartButton = this.state.checkStakePointSec > 0 || this.state.dailyPointMap > 0 || this.state.isStaked ?
        <button className="btn darkblue" onClick={this.stake.bind(this)}>PROFIT-TAKING</button> :
        <button className="btn orange" onClick={this.stake.bind(this)}>STAKING START</button>
    } else if (this.state.isShow && !this.state.stakeStart) {
      stakingStartButton = <p className="font-en" style={{ textAlign: 'center', fontSize: '1rem' }}>Please wait a little longer for STAKING to begin.</p>
    } else {
      stakingStartButton = <p className="font-en" style={{ textAlign: 'center', fontSize: '1rem' }}>Data loading now...</p>
    }


    return <div className={`stakingmint-area ${this.state.isTransaction ? 'transaction' : ''}`}>
      <section className="asagidays">
        <h2 className="days"><span className="num">{this.state.checkStakePoint}</span>DAYS</h2>
        {stakingStartButton}
      </section>
      <section className="mint-area">
        <div className="container">
          <h2 className="area-ttl">DAYS WITH ASAGI</h2>
          <div className="bottle">
            <p className="current-days"><span style={{ fontSize: '1rem' }}>total: </span>{this.state.dailyPointMap < 100000000 ? this.state.dailyPointMap : '100000000+'}<span className="unit">DAYS</span></p>
            <div className="meter">
              <div className="tank">
                <div className="tank-now" style={{
                  width: `${Math.floor((this.state.dailyPointMap % 30) / 30 * 100)}%`
                }}></div>
                <div className="max">{this.state.stakeMintCost}</div>
              </div>
            </div>
            <div className="stocks">
              <p className="num">{this.state.dailyPointMap % this.state.stakeMintCost}<span className="unit"> DAYS</span> / {this.state.stakeMintCost}<span className="unit"> DAYS</span></p>
            </div>
            <div className="stocks">
              <p className="num">stocks</p>
              {stocks}
              {stockMore}
            </div>
          </div>
          <div className="flex">
            <div className="image-area">
              <div className="image">
                <picture>
                  <source type="image/webp" srcSet="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/staking/asagitama.webp" />
                  <img src="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/staking/asagitama.png" alt="ASAGI魂" loading="lazy" />
                </picture>
              </div>
              <p className="owned">YOU OWNED<br />{this.state.minted} / {this.state.maxMintLimit}</p>
            </div>

            <div className="mint-box">
              <p className="txt">{this.state.stakeMintCost} DAYS</p>
              <p className="equal">=</p>
              <p className="txt">1 ASAGITAMA</p>
              {this.props.mintStart ? mintStartArea : <p className="alert-txt"><span className="marker">Please wait a little longer for MINT to begin.</span></p>}
              {this.stockSize() < 1 ? <p className="alert-txt"><span className="marker">It takes more than 30 DAYS to MINT!</span></p> : '' }
              {this.state.minted >= this.state.maxMintLimit ? <p className="alert-txt"><span className="marker">You are full MINTed!</span></p> : '' }
              <p className="minted">{this.state.tokenCount} / {this.state.maxSupply}</p>
            </div>
          </div>
        </div>
      </section>
    </div>
  }
}

asagitamaStakingMint.propTypes = {
  network: PropTypes.object,
  account: PropTypes.string,
  contract: PropTypes.object,
  walletBalance: PropTypes.any,
  mintStart: PropTypes.bool,
}
export default asagitamaStakingMint
