import React from 'react'
import PropTypes from 'prop-types'

class bdpMintArea extends React.Component {
  state = {
    isLoading: false,
    MAX_SUPPLY: 1200,
    totalSupply: 0,
    preSaleStart: false,
    pubSaleStart: false,

    // WL関係
    isWhitelist: false,
    merkleProof: [],

    // mint関係
    isTransaction: false,
    mintLimit: 0,
    claimed: 0,
    quantity: 0,
  }

  async checkSaleStart() {
    if (!this.props.account || !this.props.contract) return

    try {
      const preSaleStart = await this.props.contract.preSaleStart()
      const pubSaleStart = await this.props.contract.pubSaleStart()

      this.setState({
        preSaleStart: preSaleStart,
        pubSaleStart: pubSaleStart,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async maxSupply() {
    if (!this.props.account || !this.props.contract) return
    try {
      const maxSupply = await this.props.contract.MAX_SUPPLY()
      this.setState({ MAX_SUPPLY: maxSupply.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async totalSupply() {
    if (!this.props.account || !this.props.contract) return
    try {
      const totalSupply = await this.props.contract.totalSupply()
      this.setState({ totalSupply: totalSupply.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async checkWhitelist() {
    if (!this.props.account || !this.props.contract || this.isSoldOut()) return

    const uri = `https://ea2eduf711.execute-api.ap-northeast-1.amazonaws.com/pna_merkleproof?slug=bdp&address=${this.props.account}`

    let isWhitelist = false
    let merkleProof = []
    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        ;(isWhitelist = data.isWhiteList), (merkleProof = data.merkleProof)
      })

    this.setState(
      {
        isWhitelist: isWhitelist,
        merkleProof: merkleProof,
      },
      async () => {
        if (isWhitelist) this.verifyWhitelist()
      }
    )
  }

  async verifyWhitelist() {
    if (!this.props.account || !this.props.contract || this.isSoldOut()) return
    if (!this.state.isWhitelist || this.state.merkleProof.length < 1) return

    try {
      // WLユーザー判定された場合、Lambdaから返されたmerkleProofを元にcontractが通るかを問い合わせる
      const isVerify = await this.props.contract.checkMerkleProof(
        this.state.merkleProof
      )

      if (!isVerify) {
        alert('WLの認証に失敗している可能性があるため、ページをリロードします')
        window.location.reload()
      }
    } catch (error) {
      console.error(error)
    }
  }

  async mintLimit() {
    if (!this.props.account || !this.props.contract) return
    try {
      const mintLimit = await this.props.contract.mintLimit()
      this.setState({ mintLimit: mintLimit.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async claimed() {
    if (!this.props.account || !this.props.contract) return
    try {
      const claimed = await this.props.contract.claimed(this.props.account)
      this.setState({ claimed: claimed.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  canMintLimit() {
    return this.state.mintLimit - this.state.claimed
  }

  async increaseQuantity() {
    if (this.state.quantity >= this.state.mintLimit) return
    if (this.state.quantity >= this.state.MAX_SUPPLY - this.state.totalSupply)
      return
    if (this.state.quantity >= this.canMintLimit()) await this.mintLimit()

    const mintLimit = this.canMintLimit()
    this.setState({
      quantity:
        this.state.quantity < mintLimit ? this.state.quantity + 1 : mintLimit,
    })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  // WriteContract
  async preMint(e) {
    if (
      this.state.isTransaction ||
      !this.props.contract ||
      !this.props.account ||
      !this.state.preSaleStart ||
      !this.state.isWhitelist ||
      !this.state.merkleProof
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    const canMintLimit = this.canMintLimit()
    if (quantity > canMintLimit) {
      alert('The number of possible MINTs has been exceeded')
      return
    }

    const amount = this.props.wlMintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await this.props.contract.preMint(
        quantity,
        this.state.merkleProof,
        {
          value: ethers.utils.parseEther(payableAmount),
        }
      )

      console.log(
        `https://${
          this.props.network.name !== 'homestead'
            ? `${this.props.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.props.reloadFunc()
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.viewUpdate()
    }
  }

  async pubMint(e) {
    if (
      this.state.isTransaction ||
      !this.props.contract ||
      !this.props.account ||
      !this.state.pubSaleStart
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    const canMintLimit = this.canMintLimit()
    if (quantity > canMintLimit) {
      alert('The number of possible MINTs has been exceeded')
      return
    }

    const amount = this.props.mintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await this.props.contract.pubMint(quantity, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(
        `https://${
          this.props.network.name !== 'homestead'
            ? `${this.props.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.props.reloadFunc()
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.viewUpdate()
    }
  }

  errorHandling(error) {
    if (
      error.message ===
      'MetaMask Tx Signature: User denied transaction signature.'
    ) {
      alert('MINTをキャンセルしました')
    } else if (
      error.message.indexOf('insufficient funds for gas * price + value') != -1
    ) {
      alert(
        'MINTに必要な資金が不足しています。\n十分に資金があるにも関わらずこのメッセージが表示される場合は、この画面のスクリーンショットを運営までお知らせください。\n' +
          `wallet: ${this.props.account}\n============\n` +
          error
      )
    } else if (error.message.indexOf('transaction failed') != -1) {
      const scanUri = `https://${
        this.props.network.name !== 'homestead'
          ? `${this.props.network.name}.`
          : ''
      }etherscan.io/tx/${error.transactionHash}`
      console.error(error)

      if (
        confirm(
          'Transactionに失敗しました。\n詳細をetherscanで確認しますか？\n(OKを押すとetherscanへ遷移します)\n' +
            scanUri
        )
      ) {
        window.location.href = scanUri
      }
    } else {
      alert(
        '失敗しました。この画面をスクリーンショットで撮影し、運営までお知らせください。\n' +
          `wallet: ${this.props.account}\n` +
          error
      )
    }
  }

  // 初期設定系
  async initialLoading() {
    await this.checkWhitelist()
    await this.maxSupply()
  }

  async viewUpdate() {
    this.setState({ isLoading: true })
    await this.checkSaleStart()
    await this.totalSupply()
    await this.verifyWhitelist()
    await this.mintLimit()
    await this.claimed()

    this.setState({ isLoading: false })
  }

  async componentDidMount() {
    await this.initialLoading()
    await this.viewUpdate()
  }

  async componentDidUpdate(props) {
    if (props.account !== this.props.account) {
      this.props.reloadFunc()
      await this.checkWhitelist()
    }

    if (!props.reload && this.props.reload) {
      await this.viewUpdate()
      await this.initialLoading()
      this.props.reloadedFunc()
    }
  }

  isSoldOut() {
    return this.state.MAX_SUPPLY === this.state.totalSupply
  }

  render() {
    const loadingDom = (
      <React.Fragment>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>L</span>
        <span>O</span>
        <span>A</span>
        <span>D</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
      </React.Fragment>
    )

    const transactionDom = (
      <p className="load-effect" style={{ fontSize: '2rem', margin: '1rem 0' }}>
        <span>■</span>
        <span>■</span>
        <span>T</span>
        <span>R</span>
        <span>A</span>
        <span>N</span>
        <span>S</span>
        <span>A</span>
        <span>C</span>
        <span>T</span>
        <span>I</span>
        <span>O</span>
        <span>N</span>
        <span>　</span>
        <span>N</span>
        <span>O</span>
        <span>W</span>
        <span>■</span>
        <span>■</span>
      </p>
    )

    return (
      <div className="mint-area scroll-target" id="mint">
        <div className="heading">
          {this.state.isLoading ? (
            <p className="stage load-effect">{loadingDom}</p>
          ) : (
            <p className="stage">
              [{' '}
              {this.isSoldOut()
                ? 'SOLD OUT'
                : this.state.pubSaleStart
                ? 'PUBLIC SALE NOW'
                : this.state.preSaleStart
                ? 'WHITELIST SALE NOW'
                : 'BEFORE SALES START'}{' '}
              / You are{' '}
              {this.isSoldOut() ? '' : this.state.isWhitelist ? 'WL' : 'Public'}{' '}
              User ]
            </p>
          )}
          <h1 className="ttl">BDP GenX Minting</h1>
          <p className="price">
            WL MINT : {this.props.wlMintPrice} ETH / Public MINT :{' '}
            {this.props.mintPrice} ETH{' '}
          </p>
        </div>
        <p className="supply">
          {this.state.isLoading ? 'XXXX' : this.state.totalSupply} /{' '}
          {this.state.MAX_SUPPLY}
        </p>
        {this.isSoldOut() || this.state.claimed >= this.state.mintLimit ? (
          ''
        ) : this.state.isTransaction ? (
          transactionDom
        ) : this.state.isLoading ? (
          <p
            className="load-effect"
            style={{ fontSize: '2rem', margin: '1rem 0' }}
          >
            {loadingDom}
          </p>
        ) : (
          <div className="quantity-ctl">
            <button
              className="btn decrease"
              onClick={this.decreaseQuantity.bind(this)}
            ></button>
            <p className="num">{this.state.quantity}</p>
            <button
              className="btn increase"
              onClick={this.increaseQuantity.bind(this)}
            ></button>
          </div>
        )}
        {this.isSoldOut() ? (
          <p className="supply">Thank you SOLD OUT</p>
        ) : this.state.claimed >= this.state.mintLimit ? (
          <p className="supply">
            Thank you full minted!
            <br />
            <span style={{ fontSize: '1.5rem' }}>
              Currently, the maximum number is {this.state.mintLimit} BDP.
            </span>
          </p>
        ) : (
          ''
        )}
        {this.isSoldOut() || this.state.claimed >= this.state.mintLimit ? (
          ''
        ) : (
          <div className="buttons">
            {this.state.isWhitelist ? (
              <button
                className="default-btn"
                disabled={
                  !this.state.preSaleStart ||
                  this.state.isLoading ||
                  this.state.isTransaction
                }
                onClick={this.preMint.bind(this)}
              >
                WL MINT
              </button>
            ) : (
              ''
            )}
            <button
              className="default-btn"
              disabled={
                !this.state.pubSaleStart ||
                this.state.isLoading ||
                this.state.isTransaction
              }
              onClick={this.pubMint.bind(this)}
            >
              Public MINT
            </button>
          </div>
        )}
        <button
          className={`reload-btn ${this.state.isLoading ? 'loading' : ''} `}
          onClick={this.viewUpdate.bind(this)}
        >
          DATA LOADING{' '}
        </button>

        {this.state.isWhitelist ? (
          <p
            className="txt"
            style={{
              fontSize: '10px',
              textAlign: 'center',
              textShadow: '0 0 3px yellow',
            }}
          >
            [Connected Address]
            <br />
            {this.props.account}
          </p>
        ) : (
          <p className="txt" style={{ fontSize: '10px', textAlign: 'center' }}>
            [Connected Address]
            <br />
            {this.props.account}
          </p>
        )}
      </div>
    )
  }
}

bdpMintArea.propTypes = {
  reloadFunc: PropTypes.func,
  reloadedFunc: PropTypes.func,
  reload: PropTypes.bool,
  wlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
  contract: PropTypes.object,
  network: PropTypes.object,
  account: PropTypes.string,
}
export default bdpMintArea
