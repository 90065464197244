import React from 'react'
import PropTypes from 'prop-types'
class NGMCharMint extends React.Component {
  state = {
    charName: this.charName(),
    quantity: 0,
  }

  // 1.Namy, Size: 232, startIndex: 1
  // 2.Ako, Size: 310, startIndex: 233
  // 3.Elena, Size: 254, startIndex: 543
  // 4.Maruru, Size: 238, startIndex: 797
  // 5.Riff, Size: 238, startIndex: 1035
  // 6.Sekai, Size: 228, startIndex: 1273
  charName() {
    switch (this.props.charType) {
      case 1:
        return 'Namy'
      case 2:
        return 'Ako'
      case 3:
        return 'Elena'
      case 4:
        return 'Maruru'
      case 5:
        return 'Riff'
      case 6:
        return 'Sekai'
    }
  }

  async increaseQuantity() {
    this.setState({
      quantity: this.state.quantity + 1,
    })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  render() {
    return (
      <div className="char">
        <div className="image">
          <img
            src={`https://www.namaiki-girls.com/index.cgi/static/img/${this.state.charName}.gif`}
            alt={this.state.charName}
          />
        </div>
        <p className="name">{this.state.charName}</p>
        <p className="item">
          <span className="num">{this.props.stock}</span>items
        </p>
        {this.props.isLoading ? (
          <React.Fragment />
        ) : this.props.isTransaction ? (
          <React.Fragment />
        ) : this.props.isSoldOut ? (
          <div className="soldout">SOLD OUT</div>
        ) : this.props.stock === 0 ? (
          <div className="soldout">SOLD OUT</div>
        ) : (
          <div className="quantity-ctl">
            <button
              className="btn decrease"
              onClick={() => this.decreaseQuantity()}
            ></button>
            <p className="num">{this.state.quantity}</p>
            <button
              className="btn increase"
              onClick={() => this.increaseQuantity()}
            ></button>
          </div>
        )}
        {this.props.stock > 0 ? (
          this.props.isLoading ? (
            <p style={{ textAlign: 'center' }}>LOADING...</p>
          ) : this.props.isTransaction ? (
            <p style={{ textAlign: 'center' }}>Transaction now...</p>
          ) : (
            <div className="buttons">
              {this.props.isNL ? (
                <button
                  className="default-btn"
                  onClick={(e) =>
                    this.props.preMintFunc(
                      e,
                      0,
                      this.props.charType,
                      this.state.quantity
                    )
                  }
                  disabled={!this.props.nlSaleStart}
                >
                  NL MINT
                </button>
              ) : (
                <React.Fragment />
              )}
              {this.props.isAL ? (
                <button
                  className="default-btn"
                  onClick={(e) =>
                    this.props.preMintFunc(
                      e,
                      1,
                      this.props.charType,
                      this.state.quantity
                    )
                  }
                  disabled={!this.props.alSaleStart}
                >
                  AL MINT
                </button>
              ) : (
                <React.Fragment />
              )}
              <button
                className="default-btn"
                onClick={(e) =>
                  this.props.pubMintFunc(
                    e,
                    this.props.charType,
                    this.state.quantity
                  )
                }
                disabled={!this.props.pubSaleStart}
              >
                Public MINT
              </button>
            </div>
          )
        ) : (
          <React.Fragment />
        )}
      </div>
    )
  }
}

NGMCharMint.propTypes = {
  charType: PropTypes.number,
  isLoading: PropTypes.bool,
  isTransaction: PropTypes.bool,
  isNL: PropTypes.bool,
  isAL: PropTypes.bool,
  isSoldOut: PropTypes.bool,
  stock: PropTypes.number,
  nlSaleStart: PropTypes.bool,
  alSaleStart: PropTypes.bool,
  pubSaleStart: PropTypes.bool,
  preMintFunc: PropTypes.func,
  pubMintFunc: PropTypes.func,
}
export default NGMCharMint
