import React from 'react'
import PropTypes from 'prop-types'

import MintArea from './mint'
import MemberArea from './member'
import RevealArea from './reveal'
import OpenMetaMaskModal from '../../contract/openMetaMaskModal'

let provider, signer, contract
class bdpMintPage extends React.Component {
  state = {
    abi: require('./bdpAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    reload: false,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  reloaded() {
    this.setState({ reload: false })
  }

  reload() {
    this.setState({ reload: true })
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    this.setState({ reload: true })
  }

  async componentDidMount() {
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          account: accounts[0].toLowerCase(),
        },
        async () => {
          await this.initialLoading()
        }
      )
    })
  }

  render() {
    return (
      <React.Fragment>
        <OpenMetaMaskModal deepLink="https://metamask.app.link/dapp/play-nft.art/mints/bdpgenx" />
        <header className="header">
          <div className="left">
            <a
              href={this.state.isMetaMask ? '#mint' : '#member'}
              className="logo"
            >
              <picture>
                <source
                  type="image/webp"
                  srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/symbol.webp"
                />
                <img
                  src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/symbol.png"
                  alt="BDP Logo"
                />
              </picture>
            </a>
            <nav className="nav">
              {this.state.isMetaMask ? (
                <a href="#mint" className="link current" id="gnavMint">
                  MINT
                </a>
              ) : (
                ''
              )}
              <a href="#member" className="link" id="gnavMember">
                MEMBER
              </a>
              {this.state.isMetaMask ? (
                <a href="#reveal" className="link" id="gnavReveal">
                  REVEAL
                </a>
              ) : (
                ''
              )}
            </nav>
          </div>
          <div className="right">
            <a
              href="https://twitter.com/kamikaze_ku92"
              className="icon"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              <img
                src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/icon/twitter.svg"
                alt="Twitter ICON"
              />
            </a>

            <a
              href="https://opensea.io/ja/collection/bdpgenx"
              className="icon os"
              target="_blank"
              rel="nofollow noreferrer noopener"
            >
              <img
                src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/icon/opensea.svg"
                alt="OpenSea ICON"
              />
            </a>
          </div>
        </header>
        <main className="main">
          {this.state.isMetaMask ? (
            <MintArea
              reload={this.state.reload}
              wlMintPrice={this.props.wlMintPrice}
              mintPrice={this.props.mintPrice}
              contract={contract}
              network={this.state.network}
              account={this.state.account}
              reloadFunc={this.reload.bind(this)}
              reloadedFunc={this.reloaded.bind(this)}
            />
          ) : (
            ''
          )}
          <MemberArea />
          {this.state.isMetaMask ? (
            <RevealArea
              reload={this.state.reload}
              contract={contract}
              network={this.state.network}
              account={this.state.account}
              contractAddress={this.props.contractAddress}
            />
          ) : (
            ''
          )}

          {!this.state.isMetaMask ? (
            <div className="not-metamask">
              <h2 className="ttl">MetaMaskが認識できませんでした。</h2>
              <p className="txt">
                BDP GenXのMintを行うには
                <a
                  href="https://metamask.app.link/dapp/play-nft.art/mints/bdpgenx"
                  className="font-jp"
                  style={{ textDecoration: 'underline' }}
                >
                  MetaMask対応のブラウザ
                </a>
                をご利用ください。
              </p>
            </div>
          ) : (
            ''
          )}
        </main>
      </React.Fragment>
    )
  }
}

bdpMintPage.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  wlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
}
export default bdpMintPage
