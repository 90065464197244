import React from 'react'
import PropTypes from 'prop-types'

let provider, signer, contract, chimerativeContract, mononokeContract
class stakingArcanaMain extends React.Component {
  state = {
    abi: require('./arcanaAbi.json'),
    chimerativeAbi: require('../../mints/chimerative/abi/chimerativeAbi.json'),
    mononokeAbi: require('../../mints/chimerative/abi/mononokeAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: true,
    isTransaction: false,
    account: '',
    arcanaCost: 0,
    haveArcana: '0',
    accumlatableArcanaSize: 0,
    isExtracted: false,
    isExtractedMononoke: false,
    chimeras: [],
    mononokeChimeras: [],
    checkedChimeraIds: [],
    checkedMononokeChimeraIds: [],
    unStandbyChimeras: [],
    unStandbyMononokeChimeras: [],
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
          chimerativeContract = new ethers.Contract(
            this.props.chimerativeAddress,
            this.state.chimerativeAbi,
            signer
          )
          mononokeContract = new ethers.Contract(
            this.props.mononokeAddress,
            this.state.mononokeAbi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  currentTimeStamp() {
    const date = new Date()
    const time = date.getTime()
    return Math.floor(time / 1000)
  }

  // キメラを取得する
  async fetchChimeras() {
    if (!this.state.isMetaMask || !this.state.account) return

    const chimeraIds = await chimerativeContract.tokensOfOwner(
      this.state.account
    )

    const mononokeIds = await mononokeContract.tokensOfOwner(this.state.account)

    let isExtracted = false

    const chimeras = await Promise.all(
      chimeraIds.map(async (id) => {
        const startTimeStamp = await this.standbyStarted(0, id)
        const currentTimeStamp = this.currentTimeStamp()
        const arcana =
          startTimeStamp > 0 ? currentTimeStamp - startTimeStamp : 0
        const floatArcana = Number(arcana) / this.state.arcanaCost
        isExtracted = arcana > 0 ? true : false

        return {
          id: Number(id),
          index: ('0000' + id).slice(-4),
          image: `https://2zzxb5bijldsvoo6cnvrw3omlvsgzbgdkqqrr6yqnf2mf6kxywia.arweave.net/1nNw9ChKxyq53hNrG23MXWRshMNUIRj7EGl0wvlXxZA/${id}.gif`,
          arcana:
            Math.floor(floatArcana) > 0
              ? Math.floor(floatArcana)
              : Math.floor(floatArcana * 1000) / 1000,
          isStandby: Number(arcana > 0),
        }
      })
    )

    let isExtractedMononoke = false
    const mononokeChimeras = await Promise.all(
      mononokeIds.map(async (id) => {
        const startTimeStamp = await this.standbyStarted(1, id)
        const currentTimeStamp = this.currentTimeStamp()
        const arcana =
          startTimeStamp > 0 ? currentTimeStamp - startTimeStamp : 0
        const floatArcana = Number(arcana) / this.state.arcanaCost
        isExtractedMononoke = arcana > 0 ? true : false

        return {
          id: Number(id),
          index: ('0000' + id).slice(-4),
          image: `https://d378grak81t7wm.cloudfront.net/chimerative_mononoke/${id}.gif`,
          arcana:
            Math.floor(floatArcana) > 0
              ? Math.floor(floatArcana)
              : Math.floor(floatArcana * 1000) / 1000,
          isStandby: Number(arcana > 0),
        }
      })
    )

    this.setState(
      {
        chimeras: chimeras,
        isExtracted: isExtracted,
        mononokeChimeras: mononokeChimeras,
        isExtractedMononoke: isExtractedMononoke,
      },
      () => {
        const unStandbyChimeras = chimeras.filter(
          (chimera) => !chimera.isStandby
        )
        const unStandbyMononokeChimeras = mononokeChimeras.filter(
          (mononokeChimera) => !mononokeChimera.isStandby
        )
        this.setState({
          unStandbyChimeras: unStandbyChimeras,
          unStandbyMononokeChimeras: unStandbyMononokeChimeras,
        })

        // 抽出可能なアルカナ数を計算する
        this.checkAccumuratableArcanaSize()
      }
    )
  }

  // 抽出可能なアルカナ数を計算する
  checkAccumuratableArcanaSize() {
    const chimeraArcanaSize = this.state.chimeras
      .map((chimera) => {
        return Number(chimera.arcana)
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)

    const mononokeChimeraArcanaSize = this.state.mononokeChimeras
      .map((chimera) => {
        return Number(chimera.arcana)
      })
      .reduce((accumulator, currentValue) => {
        return accumulator + currentValue
      }, 0)

    const accumlatableArcanaSize = (
      chimeraArcanaSize + mononokeChimeraArcanaSize
    ).toLocaleString()

    this.setState({
      accumlatableArcanaSize: accumlatableArcanaSize,
    })
  }

  chimeraCheck(e) {
    if (!this.state.isMetaMask || !this.state.account) return

    const target = e.currentTarget
    const targetId = target.value
    const checked = target.checked
    let checkedChimeraIds = this.state.checkedChimeraIds

    if (checked) {
      checkedChimeraIds.push(Number(targetId))
    } else {
      checkedChimeraIds = checkedChimeraIds.filter((id) => {
        return id != targetId
      })
    }

    checkedChimeraIds = Array.from(new Set(checkedChimeraIds))
  }

  mononokeChimeraCheck(e) {
    if (!this.state.isMetaMask || !this.state.account) return

    const target = e.currentTarget
    const targetId = target.value
    const checked = target.checked
    let checkedMononokeChimeraIds = this.state.checkedMononokeChimeraIds

    if (checked) {
      checkedMononokeChimeraIds.push(Number(targetId))
    } else {
      checkedMononokeChimeraIds = checkedMononokeChimeraIds.filter((id) => {
        return id != targetId
      })
    }

    checkedMononokeChimeraIds = Array.from(new Set(checkedMononokeChimeraIds))
  }

  // arcana
  async arcanaCost() {
    if (!this.state.isMetaMask || !this.state.account) return

    const arcanaCost = await contract.arcanaCost()
    this.setState({ arcanaCost: arcanaCost })
  }

  async getArcana() {
    if (!this.state.isMetaMask || !this.state.account) return

    const haveArcana = await contract.getArcana(this.state.account)
    this.setState({ haveArcana: haveArcana.toString() })
  }

  async standbyStarted(chimeraType, tokenId) {
    if (!this.state.isMetaMask || !this.state.account || !tokenId) return

    const standbyStarted = await contract.standbyStarted(chimeraType, tokenId)
    return standbyStarted
  }

  async checkedChimerasStandby() {
    if (!this.state.isMetaMask || !this.state.account) return

    if (
      this.state.unStandbyChimeras.length < 1 &&
      this.state.unStandbyMononokeChimeras.length < 1
    )
      return alert('既に保有中のすべてのキメラがスタンバイしています')

    if (
      this.state.checkedChimeraIds.length < 1 &&
      this.state.checkedMononokeChimeraIds.length < 1
    )
      return alert('スタンバイさせるキメラを選択してください')

    if (this.state.checkedChimeraIds.length > 0) {
      alert('Chimerative monsters 1stのスタンバイを開始します')
      await this.batchArcanaStandby(0, this.state.checkedChimeraIds)
    }
    if (this.state.checkedMononokeChimeraIds.length > 0) {
      alert('Chimerative monsters 2ndのスタンバイを開始します')
      await this.batchArcanaStandby(1, this.state.checkedMononokeChimeraIds)
    }
  }

  async batchArcanaStandby(chimeraType, ids) {
    if (!this.state.isMetaMask || !this.state.account) return

    if (ids.length < 1) return alert('スタンバイさせるキメラを選択してください')

    try {
      this.setState({ isTransaction: true })
      const tx = await contract.batchArcanaStandby(chimeraType, ids)
      await tx.wait() // トランザクション完了まで待つ
      switch (chimeraType) {
        case 0:
          this.setState({
            checkedChimeraIds: [],
            isTransaction: false,
          })
          break
        case 1:
          this.setState({
            checkedMononokeChimeraIds: [],
            isTransaction: false,
          })
          break

        default:
          break
      }
      this.initialLoading()
    } catch (error) {
      this.setState({
        checkedChimeraIds: [],
        checkedMononokeChimeraIds: [],
        isTransaction: false,
      })
      console.error(error.message)
      alert('スタンバイに失敗しました')
      // if (
      //   error.message.indexOf('ARCANA Standby is not yet ready to run' !== -1)
      // ) {
      //   alert('スタンバイ機能が開放までもうしばらくお待ち下さい')
      // }
    }
  }

  async extractArcana() {
    if (!this.state.isMetaMask || !this.state.account) return

    if (
      this.state.chimeras.length < 1 &&
      this.state.mononokeChimeras.length < 1
    )
      return alert('キメラの所有を確認できませんでした')

    const ownedChimeraIds = this.state.chimeras.map((chimera) => chimera.id)
    const ownedMononokeChimeraIds = this.state.mononokeChimeras.map(
      (chimera) => chimera.id
    )

    if (ownedChimeraIds.length > 1 && ownedMononokeChimeraIds.length > 1)
      if (
        !confirm(
          'Chimerative 1stと2ndのキメラを所有しています。\nそれぞれのコレクションで分けてTransactionを発行します'
        )
      )
        return

    // 1stを実行
    if (ownedChimeraIds.length > 1) {
      alert('Chimerative monsters 1stのアルカナ抽出を開始します')
      await this.extractArcanaDo(0, ownedChimeraIds)
    }

    // 2ndを実行
    if (ownedMononokeChimeraIds.length > 1) {
      alert('Chimerative monsters 2ndのアルカナ抽出を開始します')
      await this.extractArcanaDo(1, ownedMononokeChimeraIds)
    }
  }

  // 抽出実行部分
  async extractArcanaDo(chimeraType, ownedChimeraIds) {
    if (ownedChimeraIds.length >= 100) {
      const txCount = Math.floor(ownedChimeraIds.length / 100) + 1
      let holdChimeraSize = 0
      switch (chimeraType) {
        case 0:
          holdChimeraSize = this.state.chimeras.length
          break
        case 1:
          holdChimeraSize = this.state.mononokeChimeras.length
          break

        default:
          break
      }
      if (
        confirm(
          `100体以上キメラを所有しているため、Transactionを${txCount}回に分けて発行します(所有数: ${holdChimeraSize}体)`
        )
      ) {
        let chimeras = ownedChimeraIds
        while (chimeras.length > 0) {
          const targetChimeras = chimeras.slice(0, 100)
          chimeras = chimeras.filter((i) => targetChimeras.indexOf(i) == -1)
          await this.batchArcanaStandby(chimeraType, targetChimeras)
        }
      }
    } else {
      await this.batchArcanaStandby(chimeraType, ownedChimeraIds)
    }
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setState(
      {
        isLoading: true,
        chimeras: [],
      },
      async () => {
        this.setNetwork()
        await this.setProvider()
        await this.checkMetaMask()
        await this.connectWallet()
        await this.arcanaCost()
        await this.getArcana()
        await this.fetchChimeras()
        this.setState({ isLoading: false })
      }
    )
  }

  async componentDidMount() {
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async () => {
      await this.initialLoading()
    })
  }

  render() {
    const defaultTxtStyle = {
      color: 'var(--main-color)',
      fontSize: '1.2rem',
      textAlign: 'center',
      fontWeight: 'bold',
      wordBreak: 'break-all',
    }

    const chimeras = this.state.chimeras.map((chimera, key) =>
      chimera.isStandby ? (
        <div className="chimera" key={key}>
          <div className="card">
            <div className="image">
              <div
                className="image-inner"
                style={{ backgroundImage: `url(${chimera.image})` }}
              ></div>
            </div>
            <div className="bottom">
              <div className="left">
                <div className="index">
                  <div className="checkbox"></div>
                  <p className="num">#{chimera.index}</p>
                </div>
                <p className="status">
                  Standby <br className="pc-tablet-only" />
                  Now
                </p>
              </div>
              <div className="right">
                <p className="ttl">ARCANA</p>
                <p className="size">
                  <span className="num">{chimera.arcana}</span>ml
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <label className="chimera" key={key}>
          <input
            type="checkbox"
            name="chimeraId"
            value={chimera.id}
            onChange={this.chimeraCheck.bind(this)}
          />
          <div className="card">
            <div className="image">
              <div
                className="image-inner"
                style={{ backgroundImage: `url(${chimera.image})` }}
              ></div>
            </div>
            <div className="bottom">
              <div className="left">
                <div className="index">
                  <div className="checkbox"></div>
                  <p className="num">#{chimera.index}</p>
                </div>
                <p className="status">Unstandby</p>
              </div>
              <div className="right">
                <p className="ttl">ARCANA</p>
                <p className="size">
                  <span className="num">{chimera.arcana}</span>ml
                </p>
              </div>
            </div>
          </div>
        </label>
      )
    )
    const mononokeChimeras = this.state.mononokeChimeras.map((chimera, key) =>
      chimera.isStandby ? (
        <div className="chimera" key={key}>
          <div className="card">
            <div className="image">
              <div
                className="image-inner"
                style={{ backgroundImage: `url(${chimera.image})` }}
              ></div>
            </div>
            <div className="bottom">
              <div className="left">
                <div className="index">
                  <div className="checkbox"></div>
                  <p className="num">#{chimera.index}</p>
                </div>
                <p className="status">
                  Standby <br className="pc-tablet-only" />
                  Now
                </p>
              </div>
              <div className="right">
                <p className="ttl">ARCANA</p>
                <p className="size">
                  <span className="num">{chimera.arcana}</span>ml
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <label className="chimera" key={key}>
          <input
            type="checkbox"
            name="chimeraId"
            value={chimera.id}
            onChange={this.mononokeChimeraCheck.bind(this)}
          />
          <div className="card">
            <div className="image">
              <div
                className="image-inner"
                style={{ backgroundImage: `url(${chimera.image})` }}
              ></div>
            </div>
            <div className="bottom">
              <div className="left">
                <div className="index">
                  <div className="checkbox"></div>
                  <p className="num">#{chimera.index}</p>
                </div>
                <p className="status">Unstandby</p>
              </div>
              <div className="right">
                <p className="ttl">ARCANA</p>
                <p className="size">
                  <span className="num">{chimera.arcana}</span>ml
                </p>
              </div>
            </div>
          </div>
        </label>
      )
    )

    return (
      <main className="main arcana">
        <div className="container">
          <div className="section">
            <div className="section-inner" style={{ overflow: 'hidden' }}>
              <h1 className="kv">
                <img
                  src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/kv.gif"
                  alt="ARCANA  DISTILLATION"
                />
              </h1>
              <div className="contents">
                <h2 className="own-txt">
                  Your Arcana is{' '}
                  <span className="ml">
                    <span className="num">
                      {Number(this.state.haveArcana).toLocaleString('en-US')}
                    </span>
                    ml
                  </span>{' '}
                  accumulated.
                </h2>
                <ol className="desc-list">
                  <li className="list font-en">
                    <span className="num">01.</span>Arcana accumulates 1 ml
                    daily for each chimera held.
                  </li>
                  <li className="list font-en">
                    <span className="num">02.</span>The amount of arcanas
                    accumulated increases with the number of chimeras held.
                  </li>
                  <li className="list font-en">
                    <span className="num">03.</span>To accumulate Arcana, after
                    pressing the Extract arcana button{' '}
                    <br className="pc-tablet-only" />
                    Press the Start Refining button below.
                  </li>
                </ol>

                <ol className="desc-list">
                  <li className="list font-jp">
                    <span className="num">01.</span>
                    アルカナは毎日、保有しているキメラ1体につき1ml精製されます。
                  </li>
                  <li className="list font-jp">
                    <span className="num">02.</span>
                    アルカナの精製量は、キメラの保有数に応じて増加します。
                  </li>
                  <li className="list font-jp">
                    <span className="num">03.</span>
                    アルカナを貯めるには、アルカナの抽出を押したあとに
                    <br className="pc-tablet-only" />
                    表示される精製開始ボタンを押してください。
                  </li>
                  <li className="list font-jp">
                    ※Standby前の状態では「抽出」
                    <br />
                    Standby完了後の状態では「精製」
                    <br />
                    それぞれボタンが切り替わって表示されます。
                  </li>
                </ol>
                <p className="txt" style={defaultTxtStyle}>
                  【
                  {this.state.isMetaMask
                    ? `ADDRESS: ${this.state.account}`
                    : 'Metamask Undefined'}
                  】
                </p>

                {this.state.chimeras.length > 0 ||
                this.state.mononokeChimeras.length > 0 ? (
                  <p className="txt" style={defaultTxtStyle}>
                    抽出可能なアルカナ数：約
                    {this.state.accumlatableArcanaSize}
                    ml
                    <br />
                    <span style={{ fontSize: '10px', fontWeight: 'normal' }}>
                      ※アルカナはそれぞれ秒単位で蓄積されているため、
                      <br />
                      合算することで表示上の数よりも多く抽出が可能な場合があります。
                    </span>
                  </p>
                ) : (
                  <React.Fragment></React.Fragment>
                )}

                {!this.state.isLoading &&
                this.state.chimeras.length === 0 &&
                this.state.mononokeChimeras.length === 0 ? (
                  <p className="txt" style={defaultTxtStyle}>
                    【Don't have CHIMERAs】
                  </p>
                ) : (!this.state.isExtracted ||
                    !this.state.isExtractedMononoke) &&
                  this.state.haveArcana === '0' ? (
                  <button
                    className="btn"
                    onClick={this.extractArcana.bind(this)}
                  >
                    <picture>
                      <source
                        type="image/webp"
                        media="(max-width: 900px)"
                        srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/btn_sp_extract.svg"
                      />
                      <img
                        src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/btn_pc_extract.svg"
                        alt="EXTRACT ARCANA 〜アルカナの抽出〜"
                      />
                    </picture>
                  </button>
                ) : (
                  <button
                    className="btn"
                    onClick={this.extractArcana.bind(this)}
                  >
                    <picture>
                      <source
                        type="image/webp"
                        media="(max-width: 900px)"
                        srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/btn_sp_accumulating.svg"
                      />
                      <img
                        src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/btn_pc_accumulating.svg"
                        alt="START START REFINING 〜精製開始〜"
                      />
                    </picture>
                  </button>
                )}

                <div className="ttl-area">
                  <div className="treat left">
                    <img
                      src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/treat-left.svg"
                      alt="treat"
                    />
                  </div>
                  <h2 className="ttl font-en">Standby Chimeras</h2>
                  <div className="treat right">
                    <img
                      src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/treat-right.svg"
                      alt="treat"
                    />
                  </div>
                </div>
                <p className="desc font-en">
                  Start individual chimera Arcana extraction.
                  <br />
                  Arcana cannot be extracted unless the chimera is in Standby
                  Now.
                  <br />
                  Check the Unstandby chimera from which you want to extract
                  Arcana, and press the
                  <br />
                  Then press the "Standby start" button.
                </p>

                <p className="desc font-jp">
                  個別にキメラのアルカナ抽出を開始します。
                  <br />
                  アルカナはキメラがStandby Nowの状態でないと精製できません。
                  <br />
                  アルカナ抽出を開始したいUnstandbyのキメラにチェックを入れ、
                  <br className="pc-tablet-only" />
                  STANDBY STARTのボタンを押してください。
                </p>
                <p className="desc font-jp">
                  ※こちらは2次流通などでStandby前のキメラをお迎えした際に、
                  <br className="pc-tablet-only" />
                  ガス代節約のために個別にStandbyを行うための機能です。
                  <br />
                  一括でキメラからアルカナの抽出の開始や、精製を行う場合は、
                  <br className="pc-tablet-only" />
                  前項の「抽出 / 精製」ボタンをご利用ください。
                </p>

                {this.state.isLoading ? (
                  <p className="txt" style={defaultTxtStyle}>
                    【Chimeras LOADING NOW...】
                  </p>
                ) : (
                  ''
                )}
                {this.state.isTransaction ? (
                  <p className="txt" style={defaultTxtStyle}>
                    【TRANSACTION NOW...】
                  </p>
                ) : (
                  ''
                )}
                {!this.state.isLoading &&
                this.state.chimeras.length === 0 &&
                this.state.mononokeChimeras.length === 0 ? (
                  <p className="txt" style={defaultTxtStyle}>
                    【Don't have CHIMERAs】
                  </p>
                ) : (
                  ''
                )}

                {!this.state.isTransaction && !this.state.isLoading ? (
                  <div className="chimeras">
                    {chimeras}
                    {mononokeChimeras}
                  </div>
                ) : (
                  ''
                )}

                {this.state.isMetaMask &&
                (this.state.chimeras.length > 0 ||
                  this.state.mononokeChimeras.length > 0) &&
                !this.state.isTransaction ? (
                  <button
                    className="btn"
                    style={{ marginTop: '3rem' }}
                    onClick={this.checkedChimerasStandby.bind(this)}
                  >
                    <picture>
                      <source
                        type="image/webp"
                        media="(max-width: 900px)"
                        srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/btn_sp_standby.svg"
                      />
                      <img
                        src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/arcana/btn_pc_standby.svg"
                        alt="STANDBY START"
                      />
                    </picture>
                  </button>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

stakingArcanaMain.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  chimerativeAddress: PropTypes.string,
  mononokeAddress: PropTypes.string,
}
export default stakingArcanaMain
