import React from 'react'
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'

class merkleTree extends React.Component {
  state = {
    isMultiAL: false,
    whitelistAddresses: [],
    hexRoot: '',
    includeCheckAddress: '',
    hexProof: '',
    tree: '',
  }

  checkIncludeWhitelist() {
    const leaves = this.state.whitelistAddresses.map((addr) => {
      return this.state.isMultiAL
        ? keccak256(
            Buffer.from(
              addr.slice(0, 42).slice(2) +
                Number(addr.slice(42)).toString(16).padStart(64, '0'),
              'hex'
            )
          )
        : keccak256(addr)
    })
    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })
    const rootHash = tree.getRoot() // ルートハッシュを取得

    const keccakAddr = this.state.isMultiAL
      ? keccak256(
          Buffer.from(
            this.state.includeCheckAddress.slice(0, 42).slice(2) +
              Number(this.state.includeCheckAddress.slice(42))
                .toString(16)
                .padStart(64, '0'),
            'hex'
          )
        )
      : keccak256(this.state.includeCheckAddress)

    const hexProof = tree.getHexProof(keccakAddr)
    const result = tree.verify(hexProof, keccakAddr, rootHash)
    console.log(
      this.state.includeCheckAddress,
      'included in the white list?:',
      result
    )

    this.setState({
      hexProof: result
        ? hexProof
        : 'このアドレスはマークルツリーに含まれていません',
    })
  }

  getHexProof(addr, tree) {
    const keccakAddr = this.state.isMultiAL
      ? keccak256(
          Buffer.from(
            addr.slice(0, 42).slice(2) +
              Number(addr.slice(42)).toString(16).padStart(64, '0'),
            'hex'
          )
        )
      : keccak256(addr)
    const hexProof = tree.getHexProof(keccakAddr)
    return hexProof
  }

  // address: address.slice(0, 42),
  // size: addr.slice(42),
  generateTree() {
    if (this.state.whitelistAddresses.length <= 1)
      return alert('Whitelistへ2つ以上のアドレスを入力してください')

    const leaves = this.state.whitelistAddresses.map((addr) =>
      this.state.isMultiAL
        ? keccak256(
            Buffer.from(
              addr.slice(0, 42).slice(2) +
                Number(addr.slice(42)).toString(16).padStart(64, '0'),
              'hex'
            )
          )
        : keccak256(addr)
    )
    const tree = new MerkleTree(leaves, keccak256, { sortPairs: true })
    const hexRoot = tree.getHexRoot() // Hex形式でルートハッシュを取得(これをコントラクトに書き込む)
    this.setState({
      hexRoot: hexRoot,
      includeCheckAddress: '',
      hexProof: '',
      tree: String(tree),
    })
  }

  setWhitelistAddresses(e) {
    const values = e.currentTarget.value.split(/\n/)
    const whitelistAddresses = values
      .map((address) => {
        if (address.match(/^[A-Za-z0-9]*$/)) {
          return address
        } else {
          return ''
        }
      })
      .map((string) => string.toLowerCase())
      .filter((value, index, self) => self.indexOf(value) === index)
      .filter(Boolean)
    this.setState({ whitelistAddresses: whitelistAddresses })
  }

  setIncludeCheckAddress(e) {
    const includeCheckAddress = e.currentTarget.value
    if (!includeCheckAddress.match(/^[A-Za-z0-9]*$/))
      return alert('半角英数字のみ入力可能です')
    this.setState({ includeCheckAddress: includeCheckAddress })
  }

  exportJson() {
    if (this.state.whitelistAddresses.length <= 0)
      return alert('出力にはアドレスを入力してください')

    let data
    if (this.state.isMultiAL) {
      const formattedAddresses = this.state.whitelistAddresses.map(
        (address) => ({
          address: address.slice(0, 42),
          size: Number(address.slice(42)),
        })
      )
      data = JSON.stringify({ addressList: formattedAddresses })
    } else {
      data = JSON.stringify({ address: this.state.whitelistAddresses })
    }

    const blob = new Blob([data], { type: 'application/json' })

    const fileName = 'address_list.json'
    const downloadLink = document.createElement('a')
    downloadLink.href = URL.createObjectURL(blob)
    downloadLink.download = fileName

    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  render() {
    const hexRoot = this.state.hexRoot ? (
      <div className="mt40 hexRoot">
        <p className="txt bold">
          【MerkleRoot】 - Contractへはこの値を登録してください。
        </p>
        <p className="mt10 txt bold" style={{ wordBreak: 'break-all' }}>
          {this.state.hexRoot}
        </p>
      </div>
    ) : (
      ''
    )

    const includedChecker = this.state.hexRoot ? (
      <div
        className="mt40 includedChecker flex"
        style={{
          flexDirection: 'column',
          gap: '.5rem',
        }}
      >
        <p className="txt bold">
          【直コン用 hexProof】 -
          ウォレットアドレスをベースにマークルツリーを参照する「hexProof」を返します
          。<br />
          {this.state.isMultiAL
            ? '(※確認したいウォレットアドレスの後ろに、付与するALの枚数をつなげて入れてください)'
            : '(※確認したいウォレットアドレスを入れてください)'}
        </p>
        <input
          type="text"
          onChange={this.setIncludeCheckAddress.bind(this)}
          className="mt10 pd10"
          value={this.state.includeCheckAddress}
        />
        <button
          className="button"
          onClick={this.checkIncludeWhitelist.bind(this)}
        >
          hexProofを作成する
        </button>
        <textarea
          className="mt10 textarea"
          value={this.state.hexProof ? `[${this.state.hexProof}]` : ''}
          readOnly
          disabled
        ></textarea>
      </div>
    ) : (
      ''
    )

    const tree = this.state.tree ? (
      <div>
        <hr className="mt30" />
        <p className="mt30 txt bold">【Merkle Tree】 - 見たい人向け</p>
        <textarea
          className="mt20 textarea"
          value={this.state.tree}
          readOnly
          disabled
        ></textarea>
      </div>
    ) : (
      ''
    )
    return (
      <div className="">
        <div
          className="flex"
          style={{
            flexDirection: 'column',
            gap: '.5rem',
          }}
        >
          <label className="label bold">
            ALLOW LISTに追加したいアドレスを入力してください(改行で区切れます)
          </label>

          <label className="label bold">
            <input
              type="checkbox"
              onChange={() =>
                this.setState({ isMultiAL: !this.state.isMultiAL })
              }
              checked={this.state.isMultiAL}
            />
            &nbsp;&nbsp;1ウォレットあたり複数枚のALを付与する
          </label>

          {this.state.isMultiAL ? (
            <p
              className="label bold"
              style={{ fontSize: '12px', lineHeight: '2' }}
            >
              1ウォレットあたり複数枚のALを付与する設定の場合、ウォレットアドレスの後ろに続けてALを付与する枚数を記述してください。
              <br />
              例）
              <span className="bold" style={{ color: 'blue' }}>
                0x12345678901234567890123456789abCDEFgHiJk
              </span>
              <span className="bold main-color">12</span>
              <br />
              この場合、末尾の12がALの枚数です
            </p>
          ) : (
            <React.Fragment />
          )}

          <textarea
            className="textarea"
            onChange={(e) => this.setWhitelistAddresses(e)}
            placeholder="（入力例 - 改行で区切れます）&#10;0x123456789&#10;0x987654321&#10;0x024686420&#10;0x135797531..."
          >
            {this.state.whitelistAddresses}
          </textarea>

          {this.state.whitelistAddresses.length > 0 ? (
            <div
              className="addresses"
              style={{
                width: '100%',
                maxHeight: '10rem',
                border: '1px solid #333',
                borderBottom: 'none',
                borderRadius: '.3rem',
                overflowY: 'auto',
              }}
            >
              {this.state.whitelistAddresses.map((address, key) =>
                this.state.isMultiAL ? (
                  <div
                    key={key}
                    style={{
                      padding: '.5rem 1rem',
                      borderBottom: '1px solid #333',
                    }}
                  >
                    {address.slice(0, 42)} / {address.slice(42)}枚
                  </div>
                ) : (
                  <div
                    key={key}
                    style={{
                      padding: '.5rem 1rem',
                      borderBottom: '1px solid #333',
                    }}
                  >
                    {address}
                  </div>
                )
              )}
            </div>
          ) : (
            <React.Fragment />
          )}

          <button className="button" onClick={this.generateTree.bind(this)}>
            MerkleRootを作成する
          </button>
        </div>
        {hexRoot}

        {this.state.whitelistAddresses.length > 0 ? (
          <button className="mt20 button" onClick={this.exportJson.bind(this)}>
            入力アドレスをJSONで出力
          </button>
        ) : (
          <React.Fragment></React.Fragment>
        )}
        <hr className="mt30" />
        {includedChecker}
        {tree}
      </div>
    )
  }
}

export default merkleTree
