import React from "react"
import PropTypes from "prop-types"

import AboutArea from "./aboutArea"
import FreeMintArea from "./freemintArea"
import StakingMintArea from "./stakingmintArea"
import OpenMetaMaskModal from "../../../contract/openMetaMaskModal";

let provider, signer, contract, asagiContract, metamaskProvider

class asagitama extends React.Component {
  state = {
    abi: require('./asagitamaABI.json'),
    asagiAbi: [{
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      }],
    network: {},
    isMetaMask: false,
    isConnected: false,
    isTransaction: false,
    isLoading: true,
    mintStart: false,

    // asagi
    asagiBalanceOf: 0,

    // ユーザーウォレット情報
    account: null,
    walletBalance: 0,

    // View関連
    activeTab: 'about',
  }

  async setCanMintLimit() {
    const canMintLimit = this.state.mintLimit - this.state.minted
    this.setState({
      canMintLimit: canMintLimit,
    })
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, "any")
      if (window.ethereum.providers) {
        metamaskProvider = window.ethereum.providers.find((p) => p.isMetaMask)
      }

      provider.on("network", (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return
    const connectProvider = window.ethereum.providers ? metamaskProvider : ethereum

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return connectProvider.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: this.state.network.id,
        }]
      }).then(() => {
        alert('Network has been changed. Please reconnect your wallet.')
      })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await connectProvider.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(({
        isConnected: true,
        account: accounts[0].toLowerCase(),
      }), () => {
        console.log(`Wallet connected: ${this.state.account}`)
        if (!this.props.contractAddress) return

        contract = new ethers.Contract(this.props.contractAddress, this.state.abi, signer)
        asagiContract = new ethers.Contract(this.props.asagiContractAddress, this.state.asagiAbi, signer)
        this.viewUpdate()
      })
    } catch (error) {
      console.error(error)
    }
  }

  async walletBalance() {
    provider.getBalance(this.state.account)
      .then((balance) => {
        this.setState(({
          walletBalance: balance,
        }))
      })
  }

  // Read
  async asagiBalanceOf() {
    if (!this.state.account || !this.props.asagiContractAddress || !asagiContract) return

    try {
      const asagiBalanceOf = await asagiContract.balanceOf(this.state.account)

      let activeTab = this.state.activeTab
      if (Number(asagiBalanceOf) < 1 || (Number(asagiBalanceOf) == 1 && this.state.activeTab == 'freegMint')) {
        activeTab = 'about'
      }

      this.setState({
        asagiBalanceOf: Number(asagiBalanceOf),
        activeTab: activeTab
      })
    } catch (error) {
      console.error(error)
      return false
    }
  }

  async mintStart() {
    if (!this.state.account || !this.props.contractAddress || !contract) return

    try {
      const mintStart = await contract.mintStart()

      this.setState({ mintStart: mintStart })
    } catch (error) {
      console.error(error)
      return false
    }
  }


  async componentDidMount() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(({
        isLoading: true,
        account: accounts[0].toLowerCase(),
      }), async () => {
        await this.initialLoading()
        await this.viewUpdate()
      })
    })
  }

  // View関連
  async initialLoading() {
    await this.mintStart()
  }

  async viewUpdate() {
    if (!this.props.contractAddress && !this.state.account) return

    this.setState({ isLoading: true })
    this.walletBalance()
    this.asagiBalanceOf()

    this.setState({ isLoading: false })
  }

  changeTab(e) {
    const tabData = e.currentTarget.dataset.tab
    this.setState({ activeTab: tabData })
  }

  render() {
    let tabContent = ''
    switch (this.state.activeTab) {
      case 'about':
        tabContent = <AboutArea />
        break;
      case 'freeMint':
        tabContent = <FreeMintArea
          network={this.state.network}
          contract={contract}
          account={this.state.account}
          walletBalance={this.state.walletBalance}
          mintStart={this.state.mintStart}
        />
        break;
      case 'stakingMint':
        tabContent = <StakingMintArea
          network={this.state.network}
          contract={contract}
          account={this.state.account}
          walletBalance={this.state.walletBalance}
          mintStart={this.state.mintStart}
        />
        break;
    }
    return <main className="main asagitama">
      <OpenMetaMaskModal deepLink="https://metamask.app.link/dapp/play-nft.art/mints/asagitama" />
      <div className="about-asagitama">
        <div className="container">
          <h1 className="ttl">ASAGITAMA</h1>
          <p className="txt font-en">You have {this.state.asagiBalanceOf} ASAGI</p>
        </div>
      </div>
      <div className="staking-nav">
        <button className={`btn ${this.state.activeTab === 'about' ? 'current' : ''}`} data-tab="about" onClick={this.changeTab.bind(this)}>ABOUT</button>
        <button className={`btn ${this.state.activeTab === 'freeMint' ? 'current' : ''}`} data-tab="freeMint" onClick={this.changeTab.bind(this)}>FREE MINT</button>
        <button className={`btn ${this.state.activeTab === 'stakingMint' ? 'current' : ''}`} data-tab="stakingMint" onClick={this.changeTab.bind(this)}>STAKING MINT</button>
      </div>
      {tabContent}
    </main>
  }
}

asagitama.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  asagiContractAddress: PropTypes.string
}
export default asagitama
