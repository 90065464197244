import React from 'react'
import PropTypes from 'prop-types'

class openMetaMaskModal extends React.Component {
  state = {
    modalOpen: false,
  }

  async modalClose() {
    this.setState({ modalOpen: false })
  }

  componentDidMount() {
    if (!window.ethereum || !window.ethereum?.isMetaMask)
      this.setState({ modalOpen: true })
  }

  render() {
    const modalStyle = this.state.modalOpen
      ? {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          opacity: 1,
          visibility: 'visible',
          zIndex: '100000000000000000',
        }
      : {
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100vh',
          opacity: 0,
          visibility: 'hidden',
          zIndex: '100000000000000000',
        }

    const modalOverlayStyle = {
      width: '100%',
      height: '100%',
      background: 'rgba(0, 0, 0, .8)',
    }

    const modalBodyStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%',
      maxWidth: '500px',
      background: '#fff',
      borderRadius: '.5rem',
      boxShadow: '0, 0, 5px #000',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '1rem',
      gap: '1.5rem',
    }

    const titleStyle = {
      textAlign: 'center',
      fontSize: '1.4rem',
      fontWeight: 'bold',
      color: '#2e2e2e',
    }
    const buttonStyle = {
      width: '95%',
      textAlign: 'center',
      fontSize: '1.2rem',
      background: '#efefef',
      padding: '1rem',
      borderRadius: '4px',
      fontWeight: 'bold',
      color: '#2e2e2e',
    }

    const buttonPrimaryStyle = {
      width: '95%',
      textAlign: 'center',
      fontSize: '1.2rem',
      padding: '1rem',
      borderRadius: '4px',
      fontWeight: 'bold',
      background: '#D13B3C',
      color: '#fff',
    }

    return (
      <div style={modalStyle}>
        <div
          style={modalOverlayStyle}
          onClick={this.modalClose.bind(this)}
        ></div>
        <div style={modalBodyStyle}>
          <h2 style={titleStyle} className="font-jp">
            MetaMaskアプリを
            <br />
            開きますか？
          </h2>
          <a
            style={buttonPrimaryStyle}
            href={this.props.deepLink}
            className="font-jp"
          >
            はい
          </a>
          <button
            style={buttonStyle}
            onClick={this.modalClose.bind(this)}
            className="font-jp"
          >
            キャンセル
          </button>
        </div>
      </div>
    )
  }
}

openMetaMaskModal.propTypes = {
  deepLink: PropTypes.string,
}
export default openMetaMaskModal
