import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

const abi = require('./abi.json');
// const network = { // Rinkeby
//   id: '0x4',
//   name: 'rinkeby',
// };
const network = { // Ethereum
  id: '0x1',
  name: 'homestead',
};

// const provider = new ethers.providers.JsonRpcProvider(rpc + process.env.INFURA_PROJECT_ID);
let provider;
let signer
if (window.ethereum?.isMetaMask) {
  provider = new ethers.providers.Web3Provider(window.ethereum, "any");

  provider.on("network", (_, oldNetwork) => {
    if (oldNetwork) window.location.reload();
  });
  signer = provider.getSigner()
}

// const contractAddress = '0x6ab076f7277b38225587310b3d8cacb4211a0cf5' // Rinkeby
const contractAddress = '0x83565d91809ab9c0d4d4d5a74610095264aba4ce' // Ethereum
let contract;



class MintsWatcher extends React.Component {
  state = {
    isMetaMask: false,
    isConnected: false,
    isWhiteList: false,
    maxSupply: 0,
    totalSupply: 0,
    freeMinted: 0,
    mintLimit: 0,
    psMinted: 0,
    balanceOf: 0,
    account: null,
    isTransaction: false,
    quantity: 0,
    isOpenModal: false,
    mintType: 'FREE MINT',
    gasLimit: '0x55520800000000',
    wlSaleStart: false,
    saleStart: false,
    secondSaleStart: false,
    walletBalance: 0,
    ownedTokens: [],
    partsCreators: []
  };

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({
        isMetaMask: true,
      })
      this.initialReadContract();
    }
  }

  async initialReadContract() {
    contract = new ethers.Contract(contractAddress, abi, provider);
    this.totalSupply();
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return;

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== network.name) {
      return ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: network.id,
        }]
      }).then(() => {
        if (this.props.lang === 'en') {
          alert('Network has been changed. Please reconnect your wallet.');
        } else {
          alert('ネットワークを変更しました。再度ウォレットを接続してください');
        }
      });
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts;

      this.setState(({
        isConnected: true,
        account: accounts[0],
      }), () => {
        if (this.props.lang === 'en') {
          console.log(`Connected: ${this.state.account}`);
        } else {
          console.log(`ウォレットに接続しました：${this.state.account}`);
        }
        contract = new ethers.Contract(contractAddress, abi, signer);
        this.setInitialData();
        this.viewUpdate();
      });
    } catch (error) {
      console.error(error);
    }
  }

  // Read Contract
  async walletBalance() {
    provider.getBalance(this.state.account)
      .then((balance) => {
        // let balanceInEth = ethers.utils.formatEther(balance)
        // console.log("Balance:", balanceInEth)

        this.setState(({
          walletBalance: balance,
        }));
      })
  }

  async balanceOf(address) {
    if (!this.state.account && !address) return;

    try {
      let balanceOf = await contract.balanceOf(address);
      balanceOf = balanceOf.toNumber()

      this.setState({
        balanceOf: balanceOf,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async checkSaleStart() {
    try {
      let isSaleStart;
      if (this.state.mintType === 'FREE MINT') {
        isSaleStart = await contract.wlSaleStart();
        this.setState(({
          wlSaleStart: isSaleStart,
        }));
      } else {
        isSaleStart = await contract.saleStart();
        this.setState(({
          saleStart: isSaleStart,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }


  async mintLimit() {
    try {
      let mintLimit;
      if (this.state.mintType === 'FREE MINT') {
        mintLimit = await contract.freeMintLimit();
      } else {
        mintLimit = await contract.psMintLimit();
      }
      mintLimit = mintLimit.toNumber()

      this.setState(({
        mintLimit: mintLimit,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async totalSupply() {
    try {
      let totalSupply = await contract.totalSupply();
      totalSupply = totalSupply.toNumber()
      document.querySelector('.minted-count').textContent = totalSupply;

      this.setState(({
        totalSupply: totalSupply,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async isWhiteList(address) {
    if (!this.state.account && !address) return;

    try {
      let isWhiteList = await contract._whiteLists(address);
      this.setState(({
        isWhiteList: isWhiteList,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async setMaxSupply() {
    try {
      let maxSupply = await contract._totalSupply();
      maxSupply = maxSupply.toNumber()

      this.setState(({
        maxSupply: maxSupply,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async freeMinted() {
    try {
      let freeMinted = await contract.freeMinted(this.state.account);
      freeMinted = freeMinted.toNumber()

      this.setState(({
        freeMinted: freeMinted,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async psMinted() {
    try {
      let psMinted = await contract.psMinted(this.state.account);
      psMinted = psMinted.toNumber()

      this.setState(({
        psMinted: psMinted,
      }));
    } catch (error) {
      console.error(error);
    }
  }

  async tokenURI(tokenId) {
    const token = await contract.tokenURI(tokenId);
    return token
  }


  async walletOfOwner() {
    let tokens = await contract.walletOfOwner(this.state.account)
    const tokenIds = tokens.map(data => {
      return ethers.BigNumber.from(data._hex).toNumber()
    })

    this.setState(({
      ownedTokens: [],
    }), async () => {
      await tokenIds.map(async tokenId => {
        let uri = await this.tokenURI(tokenId);
        uri = uri.replace('https://soco-engineering.com/', 'https://watcher-nft.s3.ap-northeast-1.amazonaws.com/')

        await axios({
          method: 'GET',
          url: uri,
        }).then((response) => {
          this.setState(({
            ownedTokens: this.state.ownedTokens.concat(response.data),
          }));
          return response.data
        }).catch((error) => {
          console.error(error);
          return null
        });
      });
    });
  }

  async getPartsCreators() {
    await axios({
      method: 'GET',
      url: 'https://api.sssapi.app/AeM_DY4KHea6rTHZCK3wl',
    }).then((response) => {
      this.setState(({
        partsCreators: response.data,
      }));
    }).catch((error) => {
      console.error(error);
    });
  }

  setCreator(parts) {
    const creators = this.state.partsCreators.filter((partsCreator) => { return partsCreator.value === parts })
    const creator = creators[0]
    return creator ? `<a href="https://twitter.com/${creator.ID}" class="underline" target="_blank" rel="nofollow noreferrer noopener">${creator.creator}</a>` : 'unknown'
  }

  setTokenAttributesDOM(attributes) {
    return attributes.map((attribute) => {
      return `<div class="attribute">
        <p class="txt">${attribute.trait_type}: ${attribute.value} / Creator: ${this.setCreator(attribute.value)}</p>
      </div>`
    })
  }

  // Write Contract
  async mint(e) {
    await this.connectWallet();

    if (!this.state.account) return;
    e.preventDefault();

    const quantity = this.state.quantity;
    if (quantity <= 0) {
      if (this.props.lang === 'en') {
        alert('Please specify the number of MINT');
      } else {
        alert('MINT枚数を指定してください');
      }
      return;
    }

    const amount = 0.006;
    let payableAmount = String(((amount * 1000) * (quantity * 1000)) / 1000000); // 浮動小数対策

    if (ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)) {
      return alert(`資金が不足しています`);
    }

    if (quantity === 3) {
      payableAmount = '0.018'
    } else if (quantity === 6) {
      payableAmount = '0.036'
    } else if (quantity === 9) {
      payableAmount = '0.054'
    }


    let setGasLimit = this.state.walletBalance;
    let gasLimit = '';

    gasLimit = await contract.estimateGas.psMint(quantity, {
      value: ethers.utils.parseEther(payableAmount),
      gasLimit: setGasLimit,
    }).catch(error => {
      // setGasLimit = ethers.BigNumber.from(setGasLimit).add('0x2710')._hex;
      console.error(error);
      alert('ガスの見積もりに失敗しました')
    })

    if (!gasLimit) return;
    // console.log(setGasLimit)
    // console.log(gasLimit)

    this.setState(({
      isTransaction: true
    }), async () => {
      try {
        const transaction = await contract.psMint(quantity, {
          value: ethers.utils.parseEther(payableAmount),
          gasLimit: gasLimit,
        })

        console.log(`https://${ network.name !== 'homestead' ? `${network.name}.` : '' }etherscan.io/tx/${transaction.hash}`);
        await transaction.wait(); // トランザクション完了まで待つ
        this.setState({
          isTransaction: false,
          quantity: 1
        });
        this.viewUpdate();
      } catch (error) {
        console.error(error);
        this.setState({
          isTransaction: false,
        });
        if (this.props.lang === 'en') {
          alert('MINT failed.');
        } else {
          alert('MINTに失敗しました。');
        }
      }
    });
  }

  async freeMint(e) {
    if (!this.state.account) return;
    if (!this.state.isWhiteList) {
      if (this.props.lang === 'en') {
        alert('FREE MINT is available only for WhiteList eligible users.');
      } else {
        alert('WhiteList対象ユーザーのみFREE MINTが可能です。');
      }
      return;
    }
    e.preventDefault();

    const quantity = this.state.quantity;
    if (quantity <= 0) {
      if (this.props.lang === 'en') {
        alert('Please specify the number of MINT');
      } else {
        alert('MINT枚数を指定してください');
      }
      return;
    }

    const gasLimit = await contract.estimateGas.freeMint(quantity, {
      gasLimit: this.state.gasLimit,
    }).catch(error => {
      console.error(error);
      alert('ガスの見積もりに失敗しました')
    })
    if (!gasLimit) return;

    this.setState(({
      isTransaction: true
    }), async () => {
      try {
        const transaction = await contract.freeMint(quantity, {
          gasLimit: gasLimit._hex,
        })

        console.log(`https://${network.name}.etherscan.io/tx/${transaction.hash}`);
        await transaction.wait(); // トランザクション完了まで待つ

        this.setState({
          isTransaction: false,
          quantity: 1
        });
        this.viewUpdate();
      } catch (error) {
        console.error(error);
        this.setState({ isTransaction: false });
        if (this.props.lang === 'en') {
          alert('FREE MINT failed.');
        } else {
          alert('Free MINTに失敗しました。');
        }
      }
    });
  }


  // View関連
  viewUpdate() {
    this.balanceOf(this.state.account);
    this.freeMinted();
    this.psMinted();
  }

  setInitialData() {
    this.walletBalance();
    this.setMaxSupply();
    this.totalSupply();
    this.mintLimit();
    this.isWhiteList(this.state.account);
    this.checkSaleStart();
    this.walletOfOwner();
  }

  increaseQuantity() {
    let canMintLimit = 0;
    if (this.state.mintType === 'MINT') {
      canMintLimit = this.state.mintLimit - this.state.psMinted;
    } else {
      canMintLimit = this.state.mintLimit - this.state.freeMinted;
    }
    this.setState({ quantity: this.state.quantity < canMintLimit ? this.state.quantity + 1 : canMintLimit });
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0;
    this.setState({ quantity: quantity });
  }

  hex_to_ascii(str1) {
    var hex  = str1.toString();
    var str = '';
    for (var n = 0; n < hex.length; n += 2) {
      str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
    }
    return str;
  }

  openModal(mintType) {
    this.setState(({
      isOpenModal: true,
      mintType: mintType
    }), () => {
      this.setInitialData()
    });
  }

  closeModal() {
    this.setState({ isOpenModal: false });
  }

  componentDidMount() {
    this.checkMetaMask();
    this.getPartsCreators();

    const freeMintBtn = document.querySelector('#freeMint')
    const mintBtn = document.querySelector('#mintBtn')
    if (freeMintBtn) {
      freeMintBtn.addEventListener('click', () => this.openModal('FREE MINT'));
    }
    if (mintBtn) {
      mintBtn.addEventListener('click', () => this.openModal('MINT'));
    }

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', (accounts) => {
      this.setState({ account: accounts[0] });
      this.setInitialData()
    });
  }

  render() {
    const balanceOfDOM = this.props.lang === 'en' ? `You own ${this.state.balanceOf}.` : `あなたは${this.state.balanceOf}枚所有しています。`;
    const psMintedDOM = this.props.lang === 'en' ? `You can Mint up to ${this.state.mintLimit - this.state.psMinted} remaining.` : `あなたは残り最大${this.state.mintLimit - this.state.psMinted}枚Mintできます。`;
    const freeMintedDOM = this.props.lang === 'en' ? `You can Mint up to ${this.state.mintLimit - this.state.freeMinted} remaining.` : `あなたは残り最大${this.state.isWhiteList ? this.state.mintLimit - this.state.freeMinted : 0}枚Mintできます。`
    const mintBtn = this.state.saleStart && this.state.mintType === 'MINT' && this.state.mintLimit - this.state.psMinted > 0 ? <button className="mint-btn" onClick={this.mint.bind(this)}>MINT</button> : <button className="mint-btn">DONT MINT</button>;
    const freeMintBtn = this.state.wlSaleStart && this.state.mintLimit - this.state.freeMinted > 0 ? <button className="mint-btn" onClick={this.freeMint.bind(this)}>FREE MINT</button> : <button className="mint-btn">DONT FREE MINT</button>;

    const mintButton = this.state.mintType === 'MINT' ? mintBtn : freeMintBtn

    let quantityDom = '';
    if (this.state.mintType === 'FREE MINT' && this.state.mintLimit - this.state.freeMinted > 0) {
      quantityDom = <div className="size-set">
        <button className="btn decrease" onClick={this.decreaseQuantity.bind(this)}>&lt;</button>
        <p className="amount">{this.state.quantity}</p>
        <button className="btn increase" onClick={this.increaseQuantity.bind(this)}>&gt;</button>
      </div>
    } else if (this.state.mintType === 'MINT' && this.state.mintLimit - this.state.psMinted > 0) {
      quantityDom = <div className="size-set">
        <button className="btn decrease" onClick={this.decreaseQuantity.bind(this)}>&lt;</button>
        <p className="amount">{this.state.quantity}</p>
        <button className="btn increase" onClick={this.increaseQuantity.bind(this)}>&gt;</button>
      </div>
    }

    let metamask = <button>Please Install MetaMask</button>
    if (this.state.isConnected) {
      metamask = ''
    } else if (this.state.isMetaMask) {
      metamask = <button className="mint-btn" style={{ margin: 'auto auto 0' }} onClick={this.connectWallet.bind(this)}>Connect METAMASK</button>
    } else {
      metamask = ''
    }

    let txtDOM = '';
    if ((this.state.mintType === 'FREE MINT' && this.state.wlSaleStart) || (this.state.mintType === 'MINT' && this.state.saleStart)) {
      txtDOM = <React.Fragment>
        {this.state.isConnected ? balanceOfDOM : ''}<br />
        {this.state.isConnected ? this.state.mintType === 'FREE MINT' ? freeMintedDOM : psMintedDOM : ''}<br />
        {this.state.mintType === 'FREE MINT' ? this.state.isWhiteList ? 'You Are WhiteList User' : 'You are not WhiteList User' : ''}
      </React.Fragment>;
    } else {
      txtDOM = 'This magic will be unleashed.'
    }

    let controllerDOM = '';
    if ((this.state.mintType === 'FREE MINT' && this.state.wlSaleStart && this.state.isWhiteList) || (this.state.mintType === 'MINT' && this.state.saleStart)) {
      controllerDOM = <React.Fragment>
        {!this.state.isTransaction ? quantityDom : ''}
        {!this.state.isTransaction ? mintButton : ''}
      </React.Fragment>
    }

    const transactionNow = <p className="txt">{this.props.lang === 'en' ? 'Transaction Now' : 'トランザクション中です。'}<br />
      {this.props.lang === 'en' ? 'Please wait...' : 'このままお待ち下さい...'}<i className="fas fa-spinner fa-spin"></i></p>;

    const body = this.state.isConnected ? <React.Fragment>
      <h2 className="ttl">{this.state.mintType === 'MINT' ? 'MINT' : 'FREE MINT'}</h2>
      <p className="txt">{txtDOM}</p>
      {controllerDOM}
    </React.Fragment> : metamask;


    // 所有NFTを表示
    let ownedTokens = this.state.ownedTokens.map((token, key) => token.attributes ? `<div class="token" key=${key}>
      <img src=${token.image} alt=${token.name} />
      <div class="meta">
        <h3 class="name"><a href="https://opensea.io/assets/ethereum/${contractAddress}/${Number(token.name.replace('WATCHER #', '')) - 1}" target="_blank" rel="nofollow noreferrer noopener">${token.name}</a></h3>
        <div class="attributes">
          ${this.setTokenAttributesDOM(token.attributes)}
        </div>
      </div>
    </div>` : '').toString()

    let result = ownedTokens.replace(',', '');
    while(result !== ownedTokens) {
      ownedTokens = ownedTokens.replace(',', '');
      result = result.replace(',', '');
    }

    if (this.state.isMetaMask) {
      const ownedTokensArea = document.querySelector('#ownedTokens');
      ownedTokensArea.style.display = 'flex';
      const ownedTitle = `<h2>${this.props.lang === 'en' ? `Owned Tokens (${this.state.ownedTokens.length})` : `所有トークン (${this.state.ownedTokens.length}件)`}</h2>`;

      const ownedTokenConnectBtn = `<button id="ownedTokenConnectBtn">Connect METAMASK</button>`;
      ownedTokensArea.innerHTML = `${ownedTitle}${ownedTokenConnectBtn}`;

      const connectBtn = document.querySelector('#ownedTokenConnectBtn');
      connectBtn.addEventListener('click', () => {
        this.connectWallet();
      })

      if (ownedTokens.length > 0) {
        ownedTokensArea.innerHTML = `${ownedTitle}<div class="tokens">${ownedTokens}</div>`;
      } else if (this.state.isConnected) {
        const ownedTitle = `<h2>${this.props.lang === 'en' ? 'Owned Tokens' : '所有トークン'}</h2>`;
        ownedTokensArea.innerHTML = `${ownedTitle}<p class="account">${this.state.account}<br />Don't have a token.</p>`;
      }
    }

    return <div className={`mint-modal ${this.state.isOpenModal ? 'open' : ''}`} >
      <div className="mint-overlay" onClick={this.closeModal.bind(this)}></div>
      <div className="mint-box">
        {this.state.isMetaMask ? body : <p className="txt">
          {this.props.lang === 'en' ? 'METAMASK is not installed.' : 'METAMASKがインストールされていません。'}<br />
          {this.props.lang === 'en' ? 'To MINT, please use a browser with METAMASK installed.' : 'MINTするにはMETAMASKがインストールされているブラウザで操作を行ってください。'}</p>}
        {this.state.isTransaction ? transactionNow : ''}
        <button className="close" onClick={this.closeModal.bind(this)}>close</button>
      </div>
    </div>;
  }
}

MintsWatcher.propTypes = {
  baseURI: PropTypes.string,
  lang: PropTypes.string,
};
export default MintsWatcher
