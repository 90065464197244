import React from "react"

class asagitamaAbout extends React.Component {
  render() {
    return <div className="about-area">
      <div className="container">
        <picture className="img">
          <source type="image/webp" srcSet="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/staking/about.webp" />
          <img src="https://pna-assets.s3.ap-northeast-1.amazonaws.com/mints/asagi/img/staking/about.png" alt="ASAGI魂について" loading="lazy" />
        </picture>
      </div>
  </div>
  }
}

export default asagitamaAbout
