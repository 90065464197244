import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

class GenerativePartsCounter extends React.Component {
  state = {
    seed: [],
    layerCounts: {},
    isShow: false,
    isLoading: true,
  }

  fetchSeed() {
    this.setState({ isLoading: true }, async () => {
      await axios({
        method: 'GET',
        url: `https://pna-generative.s3.ap-northeast-1.amazonaws.com/${this.props.slug}/seed.json`,
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate', // キャッシュをクリアするためのヘッダー
          Pragma: 'no-cache', // HTTP/1.0 キャッシュをクリアするためのヘッダー
          Expires: '0', // キャッシュの有効期限を 0 に設定
        },
      })
        .then((response) => {
          this.processSeed(response.data.layers)
          this.setState({ isLoading: false })
        })
        .catch((error) => {
          console.log(error.message)
          this.setState({ isLoading: false })
        })
    })
  }

  processSeed(seed) {
    const counts = {}

    seed.forEach((layerGroup) => {
      layerGroup.forEach((item) => {
        const layerName = item.attribute_name
        if (!counts[layerName]) {
          counts[layerName] = {}
        }

        const partName = item.attribute_detail
        if (!counts[layerName][partName]) {
          counts[layerName][partName] = 0
        }
        counts[layerName][partName]++
      })
    })

    this.setState({ layerCounts: counts })
  }

  calculatePercentage(count, total) {
    if (total === 0) {
      return 0
    }
    return ((count / total) * 100).toFixed(2)
  }

  toggleShow() {
    this.setState({ isShow: !this.state.isShow })
  }

  componentDidMount() {
    this.fetchSeed()
  }

  render() {
    const partsListTableInnerStyle = {
      display: 'flex',
      width: '100%',
      borderBottom: '1px solid #ccc',
    }
    const partsListTableLayerNameStyle = {
      width: '40%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid #ccc',
      fontWeight: 'bold',
      padding: '0.3rem 0.5rem',
      fontSize: '12px',
    }
    const partsListTablePartsNameStyle = {
      width: '100%',
      padding: '0.3rem 0.5rem',
      fontSize: '12px',
    }

    const { layerCounts } = this.state
    return (
      <div style={{ width: '100%', marginBottom: '1rem' }}>
        <div className="flex" style={{ width: '100%' }}>
          <h2
            style={{
              fontWeight: 'bold',
              display: 'flex',
              gap: '.5rem',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={this.toggleShow.bind(this)}
          >
            <div
              style={
                this.state.isShow
                  ? {
                      width: '1rem',
                      height: '1rem',
                      background: 'var(--key-color)',
                      clipPath: 'polygon(100% 0, 0 0, 50% 100%)',
                      transition: '.2s',
                    }
                  : {
                      width: '1rem',
                      height: '1rem',
                      background: 'var(--key-color)',
                      clipPath: 'polygon(100% 50%, 0 0, 0 100%)',
                      transition: '.2s',
                    }
              }
            ></div>
            パーツ比率
          </h2>
          {this.state.isShow ? (
            <div
              style={{
                marginLeft: 'auto',
                padding: '.3rem 1rem',
                background: 'var(--key-color)',
                color: '#fff',
                cursor: 'pointer',
                fontSize: '.9rem',
                borderRadius: '.3rem',
              }}
              onClick={this.fetchSeed.bind(this)}
            >
              パーツ比率を更新
            </div>
          ) : (
            <React.Fragment />
          )}
        </div>
        {this.state.isLoading ? (
          this.state.isShow ? (
            <p style={{ fontSize: '10px' }}>
              パーツリストを読み込んでいます...
            </p>
          ) : (
            <React.Fragment />
          )
        ) : (
          <div
            style={
              this.state.isShow
                ? {
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid #ccc',
                    borderBottom: 'none',
                    margin: '1rem 0',
                  }
                : { display: 'none' }
            }
          >
            {Object.entries(this.state.layerCounts).map(([layer, parts]) => {
              const total = Object.values(parts).reduce(
                (acc, count) => acc + count,
                0
              )
              return (
                <div key={layer} style={partsListTableInnerStyle}>
                  <h3 style={partsListTableLayerNameStyle}>{layer}</h3>
                  <ul style={{ width: '100%' }}>
                    {Object.entries(parts).map(([part, count]) => {
                      const percentage = this.calculatePercentage(count, total)
                      return (
                        <li key={part} style={partsListTablePartsNameStyle}>
                          {part}: {count} ({percentage}%)
                        </li>
                      )
                    })}
                  </ul>
                </div>
              )
            })}
          </div>
        )}
      </div>
    )
  }
}

GenerativePartsCounter.propTypes = {
  slug: PropTypes.string,
}

export default GenerativePartsCounter
