import React from 'react'
import PropTypes from 'prop-types'

import OpenMetaMaskModal from '../../contract/openMetaMaskModal'
import Loading from '../loading'
import Transaction from '../transaction'
import CharMint from './charMint'

let provider, signer, contract, otherSaleContract
class NGMMint extends React.Component {
  state = {
    abi: require('./ngmAbi.json'),
    otherSaleAbi: require('./otherSaleAbi.json'),
    account: '',
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: false,

    MAX_SUPPLY: 0,
    totalSupply: 0,
    nlSaleStart: false,
    alSaleStart: false,
    pubSaleStart: false,

    // WL関係
    nl: {
      isWhitelist: false,
      merkleProof: [],
    },
    al: {
      isWhitelist: false,
      merkleProof: [],
    },

    // キャラクター関係
    namyStock: 0,
    maruruStock: 0,
    riffStock: 0,
    elenaStock: 0,
    akoStock: 0,
    sekaiStock: 0,
    charArr: [1, 4, 5, 3, 2, 6],

    // mint関係
    isTransaction: false,
    mintLimitNL: 0,
    mintLimitAL: 0,
    claimed: 0,

    // OtherSale関係
    orderIds: [],
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )

          otherSaleContract = new ethers.Contract(
            '0x8533F9A7ed6ed7695021ABF1f5d69aB38EBC2EEA',
            this.state.otherSaleAbi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async reload() {
    this.setState({ isLoading: true }, async () => {
      await this.viewUpdate()
      this.setState({ isLoading: false })
    })
  }

  async getOrderIds() {
    if (!this.state.account || !otherSaleContract) return

    const orderIds = await otherSaleContract.getOrderIds(this.state.account)
    this.setState({ orderIds: orderIds })
  }

  async otherOrderMint(e) {
    if (this.state.isTransaction || !this.state.account || !otherSaleContract)
      return

    e.preventDefault()

    const quantity = 1
    const amount = 0.02
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await otherSaleContract.purchaseNFT({
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(
        `https://${
          this.state.network.name !== 'homestead'
            ? `${this.state.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({ isTransaction: false })
      this.reload()
      this.viewUpdate()
      alert('ORDER MINTしました')
    } catch (error) {
      this.errorHandling(error)
      this.setState({ isTransaction: false })
      this.viewUpdate()
    }
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.maxSupply()
    await this.totalSupply()
    await this.checkWhitelist('nl')
    await this.checkWhitelist('al')
  }

  async viewUpdate() {
    await this.checkSaleStart()
    await this.maxSupply()
    await this.totalSupply()
    await this.verifyWhitelist('nl')
    await this.verifyWhitelist('al')
    await this.charCounts()
    await this.mintLimit()
    await this.claimed()

    // OtherSale関係
    await this.getOrderIds()
  }

  async componentDidMount() {
    this.setState({ isLoading: true }, async () => {
      await this.initialLoading()
      await this.viewUpdate()
      this.setState({ isLoading: false })
    })

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          account: accounts[0].toLowerCase(),
        },
        async () => {
          this.setState({ isLoading: true }, async () => {
            await this.initialLoading()
            await this.viewUpdate()
            this.setState({ isLoading: false })
          })
        }
      )
    })
  }

  // Contract Call
  async checkSaleStart() {
    if (!this.state.account || !contract) return

    try {
      const nlSaleStart = await contract.isSaleStart(0)
      const alSaleStart = await contract.isSaleStart(1)
      const pubSaleStart = await contract.isSaleStart(2)

      this.setState({
        nlSaleStart: nlSaleStart,
        alSaleStart: alSaleStart,
        pubSaleStart: pubSaleStart,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async maxSupply() {
    if (!this.state.account || !contract) return
    try {
      const maxSupply = await contract.MAX_SUPPLY()
      this.setState({ MAX_SUPPLY: maxSupply.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async totalSupply() {
    if (!this.state.account || !contract) return
    try {
      const totalSupply = await contract.totalSupply()
      this.setState({ totalSupply: totalSupply.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async checkWhitelist(list) {
    if (!this.state.account || !contract || this.isSoldOut()) return

    const uri = `https://ea2eduf711.execute-api.ap-northeast-1.amazonaws.com/pna_merkleproof?slug=ngm-${list}&address=${this.state.account}`

    let isWhitelist = false
    let merkleProof = []
    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        isWhitelist = data.isWhiteList
        merkleProof = data.merkleProof
      })

    if (list === 'nl') {
      this.setState(
        {
          nl: {
            isWhitelist: isWhitelist,
            merkleProof: merkleProof,
          },
        },
        async () => {
          if (isWhitelist) this.verifyWhitelist()
        }
      )
    } else if (list === 'al') {
      this.setState(
        {
          al: {
            isWhitelist: isWhitelist,
            merkleProof: merkleProof,
          },
        },
        async () => {
          if (isWhitelist) this.verifyWhitelist()
        }
      )
    }
  }

  async verifyWhitelist(list) {
    if (!this.state.account || !contract || this.isSoldOut() || !list) return

    const mintType = list === 'nl' ? 0 : 1
    const merkleProof =
      list === 'nl' ? this.state.nl.merkleProof : this.state.al.merkleProof

    if (merkleProof.length === 0) return
    try {
      // WLユーザー判定された場合、Lambdaから返されたmerkleProofを元にcontractが通るかを問い合わせる
      const isVerify = await contract.checkMerkleProof(mintType, merkleProof)

      if (!isVerify) {
        const upperCaseString = list.toUpperCase()
        alert(
          `${upperCaseString}の認証に失敗している可能性があるため、ページをリロードします`
        )
        window.location.reload()
      }
    } catch (error) {
      console.error(error)
    }
  }

  async mintLimit() {
    if (!this.state.account || !contract) return
    try {
      const mintLimitNL = await contract.MINT_LIMIT_NL()
      const mintLimitAL = await contract.MINT_LIMIT_AL()
      this.setState({
        mintLimitNL: mintLimitNL.toNumber(),
        mintLimitAL: mintLimitAL.toNumber(),
      })
    } catch (error) {
      console.error(error)
    }
  }

  async charCounts() {
    if (!this.state.account || !contract) return
    try {
      // 1.Namy, Size: 232, startIndex: 1
      // 2.Ako, Size: 310, startIndex: 233
      // 3.Elena, Size: 254, startIndex: 543
      // 4.Maruru, Size: 238, startIndex: 797
      // 5.Riff, Size: 238, startIndex: 1035
      // 6.Sekai, Size: 228, startIndex: 1273
      const namyStock = await contract.charCounts(1)
      const akoStock = await contract.charCounts(2)
      const elenaStock = await contract.charCounts(3)
      const maruruStock = await contract.charCounts(4)
      const riffStock = await contract.charCounts(5)
      const sekaiStock = await contract.charCounts(6)

      this.setState({
        namyStock: 232 - namyStock.toNumber(),
        akoStock: 310 - akoStock.toNumber(),
        elenaStock: 254 - elenaStock.toNumber(),
        maruruStock: 238 - maruruStock.toNumber(),
        riffStock: 238 - riffStock.toNumber(),
        sekaiStock: 228 - sekaiStock.toNumber(),
      })
    } catch (error) {
      console.error(error)
    }
  }

  async claimed() {
    if (!this.state.account || !contract) return
    try {
      const claimed = await contract.claimed(this.state.account)
      this.setState({ claimed: claimed.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  canMintLimit(mintType) {
    let remaining = 0
    if (mintType === 0) {
      remaining = this.state.mintLimitNL - this.state.claimed
    } else if (mintType === 1) {
      remaining = this.state.mintLimitAL - this.state.claimed
    }
    return remaining > 0 ? remaining : 0
  }

  // WriteContract
  async allCharMint(e, mintType) {
    if (this.state.isTransaction || !contract || !this.state.account) return
    if (mintType !== 0 && mintType !== 2) return
    if (mintType === 0 && !this.state.nl.isWhitelist) return

    e.preventDefault()

    const quantity = 6

    if (mintType === 0) {
      const canMintLimit = this.canMintLimit(mintType)
      if (quantity > canMintLimit) {
        alert('MINT可能な数量をオーバーしています')
        return
      }
    }

    let amount = 0
    let merkleProof = []
    if (mintType === 0) {
      amount = this.props.nlMintPrice
      merkleProof = this.state.nl.merkleProof
    }
    if (mintType === 2) {
      amount = this.props.mintPrice
    }

    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.allCharMint(mintType, merkleProof, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(
        `https://${
          this.state.network.name !== 'homestead'
            ? `${this.state.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({ isTransaction: false })
      this.reload()
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({ isTransaction: false })
      this.viewUpdate()
    }
  }

  async preMint(e, mintType, charNum, quantity) {
    if (this.state.isTransaction || !contract || !this.state.account) return
    if (mintType === 0 && !this.state.nl.isWhitelist) return
    if (mintType === 1 && !this.state.al.isWhitelist) return

    e.preventDefault()

    if (quantity <= 0) {
      alert('MINTしたい数量を1以上に設定してください')
      return
    }

    const canMintLimit = this.canMintLimit(mintType)
    if (quantity > canMintLimit) {
      alert('MINT可能な数量をオーバーしています')
      return
    }

    let amount = 0
    let merkleProof = []
    if (mintType === 0) {
      amount = this.props.nlMintPrice
      merkleProof = this.state.nl.merkleProof
    }
    if (mintType === 1) {
      amount = this.props.mintPrice
      merkleProof = this.state.al.merkleProof
    }

    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.preMint(
        mintType,
        charNum,
        quantity,
        merkleProof,
        {
          value: ethers.utils.parseEther(payableAmount),
        }
      )

      console.log(
        `https://${
          this.state.network.name !== 'homestead'
            ? `${this.state.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({ isTransaction: false })
      this.reload()
      this.viewUpdate()
      alert('MINTしました')
    } catch (error) {
      this.errorHandling(error)
      this.setState({ isTransaction: false })
      this.viewUpdate()
    }
  }

  // function pubMint(uint256 _charNum, uint256 _quantity)
  async pubMint(e, charNum, quantity) {
    if (this.state.isTransaction || !contract || !this.state.account) return

    e.preventDefault()

    if (quantity <= 0) {
      alert('MINTしたい数量を1以上に設定してください')
      return
    }

    const amount = this.props.mintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.pubMint(charNum, quantity, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(
        `https://${
          this.state.network.name !== 'homestead'
            ? `${this.state.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({ isTransaction: false })
      this.reload()
      this.viewUpdate()
      alert('MINTしました')
    } catch (error) {
      this.errorHandling(error)
      this.setState({ isTransaction: false })
      this.viewUpdate()
    }
  }

  errorHandling(error) {
    if (
      error.message ===
      'MetaMask Tx Signature: User denied transaction signature.'
    ) {
      alert('MINTをキャンセルしました')
    } else if (error.message.indexOf('Before sale begin.') != -1) {
      alert('セール開始前です')
    } else if (
      error.message.indexOf('insufficient funds for gas * price + value') != -1
    ) {
      alert(
        'MINTに必要な資金が不足しています。\n十分に資金があるにも関わらずこのメッセージが表示される場合は、この画面のスクリーンショットを運営までお知らせください。\n' +
          `wallet: ${this.state.account}\n============\n` +
          error
      )
    } else if (error.message.indexOf('transaction failed') != -1) {
      const scanUri = `https://${
        this.state.network.name !== 'homestead'
          ? `${this.state.network.name}.`
          : ''
      }etherscan.io/tx/${error.transactionHash}`
      console.error(error)

      if (
        confirm(
          'Transactionに失敗しました。\n詳細をetherscanで確認しますか？\n(OKを押すとetherscanへ遷移します)\n' +
            scanUri
        )
      ) {
        window.location.href = scanUri
      }
    } else if (error.message.indexOf('Max supply over') != -1) {
      alert(
        'Mint指定数が総供給量をオーバーしたため失敗しました。\n最新のコントラクト情報を確認するには、「DATA LODING」を押してください。'
      )
    } else if (error.message.indexOf('Not enough Namy left.') != -1) {
      alert('SOLD OUTしました')
    } else if (error.message.indexOf('Not enough Maruru left.') != -1) {
      alert('SOLD OUTしました')
    } else if (error.message.indexOf('Not enough Riff left.') != -1) {
      alert('SOLD OUTしました')
    } else if (error.message.indexOf('Not enough Elena left.') != -1) {
      alert('SOLD OUTしました')
    } else if (error.message.indexOf('Not enough Ako left.') != -1) {
      alert('SOLD OUTしました')
    } else if (error.message.indexOf('Not enough Sekai left.') != -1) {
      alert('SOLD OUTしました')
    } else if (error.message.indexOf('No reserved tokens') != -1) {
      alert('予約済みのトークンがありません')
    } else if (error.message.indexOf('Invalid payment amount') != -1) {
      alert('設定された金額が不足しています')
    } else {
      alert(
        '失敗しました。この画面をスクリーンショットで撮影し、運営までお知らせください。\n' +
          `wallet: ${this.state.account}\n` +
          error
      )
    }
  }

  isSoldOut() {
    return this.state.MAX_SUPPLY === this.state.totalSupply
  }

  userTypeCheck() {
    let userType
    if (this.state.nl.isWhitelist && this.state.al.isWhitelist) {
      userType = 'AL & NL'
    } else if (this.state.nl.isWhitelist) {
      userType = 'NL'
    } else if (this.state.al.isWhitelist) {
      userType = 'AL'
    } else {
      userType = 'Public'
    }
    return userType
  }

  checkStock(charType) {
    switch (charType) {
      case 1:
        return this.state.namyStock
      case 2:
        return this.state.akoStock
      case 3:
        return this.state.elenaStock
      case 4:
        return this.state.maruruStock
      case 5:
        return this.state.riffStock
      case 6:
        return this.state.sekaiStock
      default:
        return 0
    }
  }

  render() {
    return (
      <div className="mint-area scroll-target" id="mint">
        <OpenMetaMaskModal deepLink="https://metamask.app.link/dapp/play-nft.art/mints/ngm" />

        <div className="heading">
          {this.state.isLoading ? (
            <Loading />
          ) : this.state.isTransaction ? (
            <Transaction />
          ) : (
            <p className="stage">
              [&nbsp;
              {this.isSoldOut()
                ? 'SOLD OUT'
                : this.state.pubSaleStart &&
                  this.state.alSaleStart &&
                  this.state.nlSaleStart
                ? 'NL & AL & Public SALE NOW'
                : this.state.pubSaleStart && this.state.alSaleStart
                ? 'AL & Public SALE NOW'
                : this.state.pubSaleStart && this.state.nlSaleStart
                ? 'NL & Public SALE NOW'
                : this.state.alSaleStart && this.state.nlSaleStart
                ? 'AL & NL SALE NOW'
                : this.state.pubSaleStart
                ? 'Public SALE NOW'
                : this.state.alSaleStart
                ? 'AL SALE NOW'
                : this.state.nlSaleStart
                ? 'NL SALE NOW'
                : 'BEFORE SALES START'}
              &nbsp;/&nbsp;You&nbsp;are&nbsp;
              {this.userTypeCheck()}
              &nbsp;User&nbsp;]
            </p>
          )}
          <h1 className="ttl">
            <img
              src="https://d2tnrjjpld3h2t.cloudfront.net/ngm/mintsite/img/title.webp"
              alt="NAMAIKI GIRLS MUSIC"
            />
            <br className="sp-only" />
            Minting
          </h1>
        </div>
        <p className="supply">
          {this.state.totalSupply} / {this.state.MAX_SUPPLY}
        </p>
        <button
          className={`reload-btn ${this.state.isLoading ? 'loading' : ''}`}
          onClick={this.reload.bind(this)}
        >
          DATA&nbsp;LOADING
        </button>

        {this.state.account ? (
          <div
            style={{
              maxWidth: '1200px',
              width: '80%',
              margin: '2rem auto 0',
              padding: '.5rem 1rem',
              background: '#fff',
            }}
          >
            {this.state.account ? (
              <p
                style={{
                  color: 'var(--main-color)',
                  fontSize: '.8rem',
                  textAlign: 'center',
                }}
              >
                ⚡&nbsp;{this.state.account}
              </p>
            ) : (
              ''
            )}
            {this.state.claimed ? (
              <p
                style={{
                  marginTop: '.5rem',
                  color: 'var(--main-color)',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                }}
              >
                {this.state.claimed}&nbsp;Minted&nbsp;🎉
              </p>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}

        <div className="chars">
          {this.state.charArr.map((index) => {
            return (
              <CharMint
                key={index}
                charType={index}
                isLoading={this.state.isLoading}
                isTransaction={this.state.isTransaction}
                isNL={this.state.nl.isWhitelist}
                isAL={this.state.al.isWhitelist}
                isSoldOut={this.isSoldOut()}
                stock={this.checkStock(index)}
                nlSaleStart={this.state.nlSaleStart}
                alSaleStart={this.state.alSaleStart}
                pubSaleStart={this.state.pubSaleStart}
                preMintFunc={this.preMint.bind(this)}
                pubMintFunc={this.pubMint.bind(this)}
              />
            )
          })}
        </div>

        <div className="all-mint">
          <h2 className="ttl">ALL MINT</h2>
          <p className="char-names">
            NAMY / MARURU / RIFF / ELENA / AKO / SEKAI
          </p>
          {this.state.isTransaction ? (
            <p className="char-names" style={{ marginTop: '1rem' }}>
              Transaction now...
            </p>
          ) : this.state.isLoading ? (
            <p className="char-names" style={{ marginTop: '1rem' }}>
              LOADING...
            </p>
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '1rem',
                width: '100%',
                marginTop: '1rem',
              }}
            >
              {this.state.nlSaleStart && this.state.nl.isWhitelist ? (
                <button
                  className="default-btn"
                  style={{ margin: 0 }}
                  onClick={(e) => this.allCharMint(e, 0)}
                >
                  NL MINT
                </button>
              ) : (
                <React.Fragment />
              )}
              <button
                className="default-btn"
                style={{ margin: 0 }}
                onClick={(e) => this.allCharMint(e, 2)}
                disabled={!this.state.pubSaleStart}
              >
                Public MINT
              </button>
            </div>
          )}
        </div>

        {this.state.orderIds.length > 0 ? (
          <div className="all-mint">
            <h2 className="ttl">AL ORDER</h2>
            <p>NL MINTED USER ONLY</p>
            <p>
              RESERVED:&nbsp;{this.state.orderIds.length}&nbsp;NFT&nbsp;/&nbsp;
              {this.state.orderIds.length * 0.02}&nbsp;ETH
            </p>
            {this.state.isTransaction ? (
              <p className="char-names" style={{ marginTop: '1rem' }}>
                Transaction now...
              </p>
            ) : this.state.isLoading ? (
              <p className="char-names" style={{ marginTop: '1rem' }}>
                LOADING...
              </p>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '1rem',
                  width: '100%',
                  marginTop: '1rem',
                }}
              >
                <button
                  className="default-btn"
                  style={{ margin: 0 }}
                  onClick={(e) => this.otherOrderMint(e)}
                >
                  Order MINT
                </button>
              </div>
            )}
          </div>
        ) : (
          <React.Fragment />
        )}
      </div>
    )
  }
}

NGMMint.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  nlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
}
export default NGMMint
