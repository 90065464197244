import React from 'react'
import PropTypes from 'prop-types'

let provider, signer, contract, salContract

class asagiWearChange extends React.Component {
  state = {
    abi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'index',
            type: 'uint256',
          },
        ],
        name: 'tokenOfOwnerByIndex',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '_tokenId',
            type: 'uint256',
          },
        ],
        name: 'tokenURI',
        outputs: [
          {
            internalType: 'string',
            name: '',
            type: 'string',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },

      // MODE
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        name: 'mapTokenMode',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        name: 'TokenID2AtMode',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ModeBase',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ModeX1',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ModeX2',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ModeX3',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ModeX4',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
        ],
        name: 'ModeX5',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'tokenId',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'AtNumber',
            type: 'uint256',
          },
        ],
        name: 'ModeAT',
        outputs: [],
        stateMutability: 'nonpayable',
        type: 'function',
      },

      // リリースチェック
      {
        inputs: [],
        name: 'releaseX1',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'releaseX2',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'releaseX3',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'releaseX4',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'releaseX5',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'releaseAT',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },

      // 所有チェック
      {
        inputs: [
          {
            internalType: 'address',
            name: '_address',
            type: 'address',
          },
        ],
        name: 'wearAuthOfOwner',
        outputs: [
          {
            internalType: 'uint256[]',
            name: '',
            type: 'uint256[]',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },

      // SAL
      {
        inputs: [],
        name: 'SalCost',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    salAbi: [
      {
        inputs: [
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
          {
            internalType: 'uint256',
            name: 'amount',
            type: 'uint256',
          },
        ],
        name: 'approve',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'spender',
            type: 'address',
          },
        ],
        name: 'allowance',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    network: '',
    isMetaMask: false,
    isConnected: false,
    isTransaction: false,
    isReleaseX1: true,
    isReleaseX2: true,
    isReleaseX3: false,
    isReleaseX4: false,
    isReleaseX5: false,
    isReleaseAT: false,
    isLoading: true,
    balanceOf: 0,
    haveTokens: [],
    wearAuthOfOwner: [],

    // ユーザーウォレット情報
    account: null,
    walletBalance: 0,

    // SAL
    salAllowance: BigInt('0x0'),
    salCost: BigInt('0x0'),
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
          salContract = new ethers.Contract(
            this.props.contractSalAddress,
            this.state.salAbi,
            signer
          )
          this.viewUpdate()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async walletBalance() {
    provider.getBalance(this.state.account).then((balance) => {
      this.setState({
        walletBalance: balance,
      })
    })
  }

  // Read Contract - Contract自体の状態を取得
  async tokenURI(tokenId) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      const tokenURI = await contract.tokenURI(tokenId)
      return tokenURI
    } catch (error) {
      console.error(error)
    }
  }

  async balanceOf(address) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let balanceOf = await contract.balanceOf(address)
      balanceOf = balanceOf.toNumber()

      this.setState({
        balanceOf: balanceOf,
        haveTokens: [],
      })
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async tokenOfOwnerByIndex(address, index) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let tokenOfOwnerByIndex = await contract.tokenOfOwnerByIndex(
        address,
        index
      )
      tokenOfOwnerByIndex = tokenOfOwnerByIndex.toNumber()

      let haveTokens = this.state.haveTokens
      const tokenURI = await this.tokenURI(tokenOfOwnerByIndex)
      let token = await this.fetchTokenData(tokenURI)
      token.id = tokenOfOwnerByIndex
      haveTokens.push(token)

      this.setState({ haveTokens: haveTokens })
    } catch (error) {
      console.error(error)
    }
  }

  async fetchTokenData(tokenURI) {
    let token = {}
    await fetch(tokenURI)
      .then((response) => response.json())
      .then((data) => {
        token = {
          name: data.name,
          image: data.image,
        }
      })
      .catch((err) => {
        console.error(err)
        token = {
          name: 'error',
          image: '',
        }
      })
    return token
  }

  async wearAuthOfOwner(address) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let wearAuthOfOwner = await contract.wearAuthOfOwner(address)
      wearAuthOfOwner = wearAuthOfOwner.map((count, i) => {
        if (i === 2 && count._hex && count._hex !== '0x00') {
          count = ethers.BigNumber.from('1')
        }
        return count.toNumber()
      })
      this.setState({ wearAuthOfOwner: wearAuthOfOwner })
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async mapTokenMode(tokenId) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let mapTokenMode = await contract.mapTokenMode(tokenId)
      mapTokenMode = mapTokenMode.toNumber()
      return mapTokenMode
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async TokenID2AtMode(tokenId) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let TokenID2AtMode = await contract.TokenID2AtMode(tokenId)
      TokenID2AtMode = TokenID2AtMode.toNumber()
      return TokenID2AtMode
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async checkModeRelease() {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      const releaseX1 = await contract.releaseX1()
      const releaseX2 = await contract.releaseX2()
      const releaseX3 = await contract.releaseX3()
      const releaseX4 = await contract.releaseX4()
      const releaseX5 = await contract.releaseX5()
      const releaseAT = await contract.releaseAT()
      this.setState({
        isReleaseX1: releaseX1,
        isReleaseX2: releaseX2,
        isReleaseX3: releaseX3,
        isReleaseX4: releaseX4,
        isReleaseX5: releaseX5,
        isReleaseAT: releaseAT,
      })
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  waitTransaction() {
    if (this.state.isTransaction === false) {
      setTimeout(this.waitTransaction(), 50)
      return
    }
  }

  // Write Contract
  async modeChange(e) {
    if (!this.state.account || !this.props.contractAddress) return
    e.preventDefault()

    const target = e.currentTarget
    const nextMode = Number(target.dataset.mode)
    const tokenId = Number(target.dataset.tid)
    const currentMode = await this.mapTokenMode(tokenId)

    let func = ''

    if (currentMode === nextMode) return alert('already this style!')
    switch (nextMode) {
      case 1:
        func = 'ModeX1'
        break
      case 2:
        func = 'ModeX2'
        break
      case 3:
        func = 'ModeX3'
        if (this.state.salAllowance < this.state.salCost) {
          alert('許可されたSALトークン量が足りないので、再度Approveします。')
          await this.salApprove()
        }
        break
      case 4:
        func = 'ModeX4'
        break
      case 5:
        func = 'ModeX5'
        break
      default:
        if (currentMode == 0) return alert('already this style!')
        func = 'ModeBase'
        break
    }

    if (!func) return

    const gasLimit = await contract.estimateGas[func](tokenId, {
      gasLimit: this.state.gasLimit,
    }).catch((error) => {
      console.error(error.message)
      alert('Failed to get a gas estimate.', error.message)
    })
    if (!gasLimit) return

    this.setState(
      {
        isTransaction: true,
      },
      async () => {
        try {
          const transaction = await contract[func](tokenId, {
            gasLimit: gasLimit._hex,
          })

          console.log(
            `https://${this.state.network.name}.etherscan.io/tx/${transaction.hash}`
          )
          await transaction.wait()

          this.setState(
            {
              isTransaction: false,
            },
            () => {
              this.viewUpdate()
            }
          )
        } catch (error) {
          console.error(error)
          this.setState({ isTransaction: false })
          alert('Style Change failed.')
        }
      }
    )
  }
  async modeATChange(e) {
    if (!this.state.account || !this.props.contractAddress) return
    e.preventDefault()

    const target = e.currentTarget
    const atNum = Number(target.dataset.num)
    if (atNum > 6) return

    const tokenId = Number(target.dataset.tid)
    const tokenMode = await this.mapTokenMode(tokenId)
    const currentMode = await this.TokenID2AtMode(tokenId)

    if (tokenMode === 6 && currentMode === atNum)
      return alert('already this style!')

    let func = 'ModeAT'

    if (!func) return

    const gasLimit = await contract.estimateGas[func](tokenId, atNum, {
      gasLimit: this.state.gasLimit,
    }).catch((error) => {
      console.error(error)
      alert('Failed to get a gas estimate.')
    })
    if (!gasLimit) return

    this.setState(
      {
        isTransaction: true,
      },
      async () => {
        try {
          const transaction = await contract[func](tokenId, atNum, {
            gasLimit: gasLimit._hex,
          })

          console.log(
            `https://${this.state.network.name}.etherscan.io/tx/${transaction.hash}`
          )
          await transaction.wait()

          this.setState(
            {
              isTransaction: false,
            },
            () => {
              this.viewUpdate()
            }
          )
        } catch (error) {
          console.error(error)
          this.setState({ isTransaction: false })
          alert('Style Change failed.')
        }
      }
    )
  }

  // SAL TOKEN
  async salCost() {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let salCost = await contract.SalCost()
      salCost = BigInt(salCost._hex)

      this.setState({ salCost: salCost })
    } catch (error) {
      console.error(error)
    }
  }

  async salAllowance() {
    if (!this.state.account || !this.props.contractSalAddress) return

    try {
      let salAllowance = await salContract.allowance(
        this.state.account,
        this.props.contractAddress
      )
      salAllowance = BigInt(salAllowance)

      this.setState({ salAllowance: salAllowance })
    } catch (error) {
      this.setState({ salAllowance: BigInt('0x0') })
      console.error(error)
    }
  }

  async salApprove() {
    if (!this.state.account || !this.props.contractSalAddress) return

    const approveSize = BigInt(
      '115792089237313269984665646399340564039457584007913129619542357098500868790785'
    )
    const gasLimit = await salContract.estimateGas
      .approve(this.props.contractAddress, approveSize, {
        gasLimit: this.state.gasLimit,
      })
      .catch((error) => {
        console.error(error)
        alert('Failed to get a gas estimate.')
      })
    if (!gasLimit) return

    this.setState(
      {
        isTransaction: true,
      },
      async () => {
        try {
          const transaction = await salContract.approve(
            this.props.contractAddress,
            approveSize,
            {
              gasLimit: gasLimit._hex,
            }
          )

          console.log(
            `https://${this.state.network.name}.etherscan.io/tx/${transaction.hash}`
          )
          await transaction.wait()

          this.setState(
            {
              isTransaction: false,
            },
            () => {
              alert('SALトークンのapproveが完了しました')
              this.viewUpdate()
            }
          )
        } catch (error) {
          console.error(error)
          this.setState({ isTransaction: false })
          alert('SALトークンのapproveに失敗しました')
        }
      }
    )
  }

  async componentDidMount() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.salCost()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          isLoading: true,
          account: accounts[0].toLowerCase(),
        },
        async () => {
          await this.viewUpdate()
        }
      )
    })
  }

  // View関連
  async viewUpdate() {
    if (!this.props.contractAddress && !this.state.account) return

    this.setState({ isLoading: true })
    this.walletBalance()
    await this.balanceOf(this.state.account)
    await this.wearAuthOfOwner(this.state.account)
    await this.checkModeRelease()
    await this.salAllowance()

    const tobi = [...Array(this.state.balanceOf)].map(
      async (_, i) => await this.tokenOfOwnerByIndex(this.state.account, i)
    )
    this.setState({ isLoading: false })
  }

  render() {
    const salButton = (token) => {
      return this.state.salAllowance > this.state.salCost ? (
        <button
          className="btn"
          data-tid={token.id}
          data-mode="3"
          onClick={this.modeChange.bind(this)}
        >
          mode SAL
          <br />
          <span
            className="font-en bold"
            style={{ fontSize: '1rem', display: 'block' }}
          >
            (USE {Number(this.state.salCost / BigInt('1000000000000000000'))}{' '}
            SAL)
          </span>
        </button>
      ) : (
        <button
          className="btn"
          data-tid={token.id}
          data-mode="3"
          onClick={this.salApprove.bind(this)}
        >
          SAL Approve
        </button>
      )
    }

    const haveTokens =
      this.state.haveTokens.length > 0 ? (
        this.state.haveTokens
          .sort((a, b) => {
            if (a.id < b.id) return -1
            if (b.id < a.id) return 1
            return 0
          })
          .map((token, key) => (
            <div className="my-token" key={key}>
              <div className="image">
                <img
                  src={
                    token.image
                      ? token.image
                      : 'https://d2tnrjjpld3h2t.cloudfront.net/asagi/assets/unknown.png'
                  }
                  alt={`${token.name} image`}
                />
                <h2 className="name">{token.name}</h2>
              </div>
              <div className="style-action">
                <button
                  className="btn"
                  data-tid={token.id}
                  data-mode=""
                  onClick={this.modeChange.bind(this)}
                >
                  default
                </button>
                {this.state.isReleaseX1 && this.state.wearAuthOfOwner[0] > 0 ? (
                  <button
                    className="btn"
                    data-tid={token.id}
                    data-mode="1"
                    onClick={this.modeChange.bind(this)}
                  >
                    mode NSM
                  </button>
                ) : (
                  ''
                )}
                {this.state.isReleaseX2 && this.state.wearAuthOfOwner[1] > 0 ? (
                  <button
                    className="btn"
                    data-tid={token.id}
                    data-mode="2"
                    onClick={this.modeChange.bind(this)}
                  >
                    mode NTP
                  </button>
                ) : (
                  ''
                )}
                {this.state.isReleaseX3 && this.state.wearAuthOfOwner[2] > 0
                  ? salButton(token)
                  : ''}
                {this.state.isReleaseX4 && this.state.wearAuthOfOwner[3] > 0 ? (
                  <button
                    className="btn"
                    data-tid={token.id}
                    data-mode="4"
                    onClick={this.modeChange.bind(this)}
                  >
                    mode X4
                  </button>
                ) : (
                  ''
                )}
                {this.state.isReleaseX5 && this.state.wearAuthOfOwner[4] > 0 ? (
                  <button
                    className="btn"
                    data-tid={token.id}
                    data-mode="5"
                    onClick={this.modeChange.bind(this)}
                  >
                    mode X5
                  </button>
                ) : (
                  ''
                )}
                {this.state.isReleaseAT ? (
                  <div className="ats">
                    {this.state.wearAuthOfOwner[5] > 0 ? (
                      <button
                        className="at"
                        data-tid={token.id}
                        data-num="0"
                        onClick={this.modeATChange.bind(this)}
                      >
                        <span className="txt">炎</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {this.state.wearAuthOfOwner[6] > 0 ? (
                      <button
                        className="at"
                        data-tid={token.id}
                        data-num="1"
                        onClick={this.modeATChange.bind(this)}
                      >
                        <span className="txt">雷</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {this.state.wearAuthOfOwner[7] > 0 ? (
                      <button
                        className="at"
                        data-tid={token.id}
                        data-num="2"
                        onClick={this.modeATChange.bind(this)}
                      >
                        <span className="txt">水</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {this.state.wearAuthOfOwner[8] > 0 ? (
                      <button
                        className="at"
                        data-tid={token.id}
                        data-num="3"
                        onClick={this.modeATChange.bind(this)}
                      >
                        <span className="txt">草</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {this.state.wearAuthOfOwner[9] > 0 ? (
                      <button
                        className="at"
                        data-tid={token.id}
                        data-num="4"
                        onClick={this.modeATChange.bind(this)}
                      >
                        <span className="txt">氷</span>
                      </button>
                    ) : (
                      ''
                    )}
                    {this.state.wearAuthOfOwner[10] > 0 ? (
                      <button
                        className="at"
                        data-tid={token.id}
                        data-num="5"
                        onClick={this.modeATChange.bind(this)}
                      >
                        <span className="txt">闇</span>
                      </button>
                    ) : (
                      ''
                    )}
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))
      ) : this.state.balanceOf > 0 ? (
        <p className="font-en">Token is being acquired ...</p>
      ) : this.state.isMetaMask ? (
        <p className="font-en">Don't have token.</p>
      ) : (
        <p className="font-en">Please use a browser with METAMASK.</p>
      )

    return (
      <div
        className={`my-tokens ${this.state.isTransaction ? 'transaction' : ''}`}
      >
        <div className="container">{haveTokens}</div>
      </div>
    )
  }
}

asagiWearChange.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  contractSalAddress: PropTypes.string,
}
export default asagiWearChange
