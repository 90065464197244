import React from "react"

let provider, signer
class transfersNew extends React.Component {
  state = {
    isTransaction: false,
    contractAddress: '',
    tokenId: 0,
    amount: 1,
    receivers: [],
    abi: [{
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_tokenId",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        },
        {
          "internalType": "address[]",
          "name": "_receivers",
          "type": "address[]"
        }
      ],
      "name": "batchMint",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }],
    account: '',
    network: ''
  }

  // Initialize
  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, "any")
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async connectWallet() {
    if (!window.ethereum?.isMetaMask) return alert('MetaMaskの利用できる環境でお試しください')

    const connectedNetwork = await provider.getNetwork()
    this.setState({ network: connectedNetwork.name })


    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(({
        account: accounts[0].toLowerCase(),
      }), () => {
        console.log(`Wallet connected: ${this.state.account}`)
      })
    } catch (error) {
      console.error(error)
    }
  }

  // batchMint関係
  changeContractAddress(e) {
    const contractAddress = e.currentTarget.value
    this.setState({
      contractAddress: contractAddress
    })
  }

  changeTokenId(e) {
    const tokenId = Number(e.currentTarget.value)
    this.setState({
      tokenId: tokenId
    })
  }

  changeAmount(e) {
    const amount = Number(e.currentTarget.value)
    this.setState({
      amount: amount
    })
  }

  changeReceivers(e) {
    const file_reader = new FileReader()

    file_reader.addEventListener('load', (e) => {
      const result = String(e.target.result)
      const removeSpaces = result.trim().replace(',', '').replace('\n', '').replace('\r', '').replace('\r\n', '')
      const splits = removeSpaces.split('0x').filter(v => !!v)
      const receivers = splits.map(split => { return `0x${split.replace('\r\n', '')}`})
      this.setState({
        receivers: receivers
      })
    })

    file_reader.readAsText(e.target.files[0])
  }

  async batchMint(e) {
    if (!this.state.contractAddress) return alert('コントラクトアドレスを入力してください')
    if (this.state.receivers.length <= 0) return alert('送信先のアドレスが登録されていません')
    if (this.state.tokenId < 0 || this.state.tokenId === null || this.state.tokenId === undefined) return alert('トークンIDが登録されていません')
    if (this.state.amount <= 0 || this.state.amount === null || this.state.amount === undefined) return alert('配布数が登録されていません')

    const contract = new ethers.Contract(this.state.contractAddress, this.state.abi, signer)
    if (!contract) return alert('コントラクトが見つかりませんでした')

    e.preventDefault()

    this.setState(({
      isTransaction: true
    }), async () => {
      const tokenId = this.state.tokenId
      const amount = this.state.amount
      const tryCount = 1000

      if (this.state.receivers.length >= tryCount) {
        const txCount = Math.floor(this.state.receivers.length / tryCount) + 1
        if (confirm(`アドレスが${tryCount}件以上のため、Transactionを${txCount}回に分けて発行します(件数: ${this.state.receivers.length})`)) {
          let receivers = this.state.receivers
          while (receivers.length > 0) {
            const targetReceivers = receivers.slice(0, tryCount)
            receivers = receivers.filter(i => targetReceivers.indexOf(i) == -1)
            await contract.batchMint(tokenId, amount, targetReceivers).then(() => {
            }).catch(error => {
              this.errorHandling(error)
              this.setState({
                isTransaction: false
              })
            })
          }
        }
      } else {
        await contract.batchMint(tokenId, amount, this.state.receivers).then(() => {
        }).catch(error => {
          this.errorHandling(error)
          this.setState({
            isTransaction: false
          })
        })
      }
      this.setState({
        isTransaction: false
      })
    })
  }

  errorHandling(error) {
    if (error.message === "MetaMask Tx Signature: User denied transaction signature.") {
      alert('キャンセルしました')
    } else if (error.message.indexOf('insufficient funds for gas * price + value') != -1) {
      alert('必要な資金が不足しています。\n' + `wallet: ${this.state.account}\n============\n` + error)
    } else if (error.message.indexOf('Ownable: caller is not the owner') != -1) {
      alert('コントラクトオーナー以外には許可されていません')
    } else if (error.message.indexOf('transaction failed') != -1) {
      const scanUri = `https://${ this.state.network !== 'homestead' ? `${this.state.network}.` : '' }etherscan.io/tx/${error.transactionHash}`
      console.error(error)

      if (confirm('Transactionに失敗しました。\n詳細をetherscanで確認しますか？\n(OKを押すとetherscanへ遷移します)\n' + scanUri)) {
        window.location.href = scanUri
      }
    } else {
      alert('失敗しました。\n' + `wallet: ${this.state.account}\n` + error)
    }
  }

  async componentDidMount() {
    await this.setProvider()
    await this.connectWallet()
  }

  render() {
    const receiversDOM = <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '.5rem'
    }} >
      <label className="mt10 label bold">送信先アドレス({this.state.receivers.length}件)</label>
      <div className="flex" style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem',
        maxHeight: '10rem',
        overflowY: 'auto',
        border: '1px solid #333',
        borderRadius: '.2rem'
      }}>
        {this.state.receivers.map((receiver, key) => <div className="txt" key={key} style={{
          padding: '.5rem',
          borderBottom: '1px solid #333',

        }}>{ receiver }</div>)}
      </div>
    </div>

    return <div className="flex" style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '.5rem'
    }}>
      <p>接続ウォレット：{ this.state.account }</p>
      <label className="label bold">コントラクトアドレスを入力してください</label>
      <input type="text" onChange={this.changeContractAddress.bind(this)} placeholder="0x123456789..." className="mt10 pd10" value={this.state.includeCheckAddress} />

      <label className="mt30 label bold">トークンIDを入力してください</label>
      <input type="number" onChange={this.changeTokenId.bind(this)} placeholder="1000" min="0" className="mt10 pd10" value={this.state.tokenId} />

      <label className="mt30 label bold">1アドレスあたりに送信する個数を入力してください</label>
      <input type="number" onChange={this.changeAmount.bind(this)} placeholder="1" min="1" className="mt10 pd10" value={this.state.amount} />

      <label className="mt30 label bold">送信したいウォレットアドレス一覧を入力してください(拡張子指定：.csv)</label>
      <input type="file" accept="text/csv" onChange={this.changeReceivers.bind(this)} />

      {this.state.receivers.length > 0 ? receiversDOM : ''}

      {this.state.isTransaction ? <button className="mt30 button">送信中</button> : <button className="mt30 button" onClick={this.batchMint.bind(this)} disabled={!this.state.network}>{this.state.network ? `${this.state.network === 'homestead' ? 'Ethereum' : this.state.network}ネットワークで` : ''}一括送信する</button>}
    </div>
  }
}

export default transfersNew
