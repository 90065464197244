import React from 'react'
import PropTypes from 'prop-types'

class chimerativeMintStart extends React.Component {
  state = {
    quantity: 0,
  }

  async increaseQuantity() {
    this.setState({
      quantity: this.state.quantity + 1,
    })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  // WriteContract
  async preMint(e) {
    if (
      this.props.isTransaction ||
      !this.props.contract ||
      !this.props.wallet.address ||
      !this.props.saleStart.pre ||
      !this.props.whitelist.isWhitelist
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity

    const amount = this.props.mintPrice.pre
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    await this.props.isTransactionFunc(true)
    try {
      const transaction = await this.props.contract.preMint(
        quantity,
        this.props.whitelist.merkleProof,
        {
          value: ethers.utils.parseEther(payableAmount),
        }
      )

      console.log(
        `https://${
          this.props.network.name !== 'homestead'
            ? `${this.props.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
      })
      await this.props.isTransactionFunc(false)
      this.props.viewUpdate()
      alert('🎉 Mintしました！')
    } catch (error) {
      console.error(error)
      this.errorHandling(error)
      this.setState({
        quantity: 0,
      })
      await this.props.isTransactionFunc(false)
      this.props.viewUpdate()
    }
  }

  async pubMint(e) {
    if (
      this.props.isTransaction ||
      !this.props.contract ||
      !this.props.wallet.address ||
      !this.props.saleStart.pub
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity

    const amount = this.props.mintPrice.pub
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (
      ethers.utils.formatEther(this.props.wallet.balance) <
      Number(payableAmount)
    ) {
      return alert('購入に必要な資金が不足しています')
    }

    await this.props.isTransactionFunc(true)
    try {
      const transaction = await this.props.contract.pubMint(quantity, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(
        `https://${
          this.props.network.name !== 'homestead'
            ? `${this.props.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({ quantity: 0 })
      await this.props.isTransactionFunc(false)
      this.props.viewUpdate()
      alert('🎉 Mintしました！')
    } catch (error) {
      console.error(error)
      this.errorHandling(error)
      this.setState({ quantity: 0 })
      await this.props.isTransactionFunc(false)
      this.props.viewUpdate()
    }
  }

  errorHandling(error) {
    if (
      error.message ===
      'MetaMask Tx Signature: User denied transaction signature.'
    ) {
      alert('MINTをキャンセルしました')
    } else if (error.message.indexOf('Invalid Merkle Proof') != -1) {
      alert('ALエラー')
    } else if (error.message.indexOf('user rejected transaction') != -1) {
      alert('MINTをキャンセルしました')
    } else if (error.message.indexOf('Before sale begin.') != -1) {
      alert('セール開始までお待ち下さい。')
    } else if (error.message.indexOf('mintLimit over.') != -1) {
      alert('ミント可能数をオーバーしています。')
    } else if (error.message.indexOf('Already claimed max') != -1) {
      alert('既に最大数Mintしています。')
    } else if (error.message.indexOf('Max supply over') != -1) {
      alert('最大供給量をオーバーしています')
    } else if (error.message.indexOf('Please set quantity.') != -1) {
      alert('MINT数を1以上に設定してください')
    } else if (error.message.indexOf('Sold out.') != -1) {
      alert('SOLD OUTしています')
    } else if (error.message.indexOf('Not enough funds') != -1) {
      alert('送金額エラーです。')
    } else if (
      error.message.indexOf('insufficient funds for gas * price + value') != -1
    ) {
      alert(
        'MINTに必要な資金が不足しています。\n十分に資金があるにも関わらずこのメッセージが表示される場合は、この画面のスクリーンショットを運営までお知らせください。\n' +
          `wallet: ${this.props.wallet.address}\n============\n` +
          error
      )
    } else if (error.message.indexOf('transaction failed') != -1) {
      const scanUri = `https://${
        this.props.network.name !== 'homestead'
          ? `${this.props.network.name}.`
          : ''
      }etherscan.io/tx/${error.transactionHash}`
      console.error(error)

      if (
        confirm(
          'Transactionに失敗しました。\n詳細をetherscanで確認しますか？\n(OKを押すとetherscanへ遷移します)\n' +
            scanUri
        )
      ) {
        window.location.href = scanUri
      }
    } else {
      alert(
        '失敗しました。この画面をスクリーンショットで撮影し、運営までお知らせください。\n' +
          `wallet: ${this.props.wallet.address}\n` +
          error
      )
    }
  }

  render() {
    const preSaleRow = (
      <div className="row">
        <div className="ttl">
          <picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/ttl_wl.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/ttl_wl.png"
              alt="Whitelist Sale"
            />
          </picture>
        </div>
        <button className="mint-btn" onClick={this.preMint.bind(this)}>
          <picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/mint_wh.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/mint_wh.png"
              alt="MINT BUTTON"
            />
          </picture>
        </button>
      </div>
    )

    const pubSaleRow = (
      <div className="row">
        <div className="ttl">
          <picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/ttl_public.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/ttl_public.png"
              alt="Public Sale"
            />
          </picture>
        </div>
        <button className="mint-btn" onClick={this.pubMint.bind(this)}>
          <picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/mint_wh.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/mint_wh.png"
              alt="MINT BUTTON"
            />
          </picture>
        </button>
      </div>
    )

    const decreaseBtn = (
      <button
        className="btn decrease"
        onClick={this.decreaseQuantity.bind(this)}
      >
        <picture>
          <source
            type="image/webp"
            srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/decrease.webp"
          />
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/decrease.png"
            alt="Decrease BUTTON"
          />
        </picture>
      </button>
    )

    const increaseBtn = (
      <button
        className="btn increase"
        onClick={this.increaseQuantity.bind(this)}
      >
        <picture>
          <source
            type="image/webp"
            srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/increase.webp"
          />
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/increase.png"
            alt="Increase BUTTON"
          />
        </picture>
      </button>
    )

    const mintRows = (
      <div className="mint-rows">
        {this.props.saleStart.pre && this.props.whitelist.isWhitelist
          ? preSaleRow
          : ''}
        {this.props.saleStart.pub ? pubSaleRow : ''}

        {this.props.saleStart.pub ||
        (this.props.saleStart.pre &&
          !this.props.saleStart.pub &&
          this.props.whitelist.isWhitelist) ? (
          <div className="quantity-ctl">
            {decreaseBtn}
            <p className="quantity">{this.state.quantity}</p>
            {increaseBtn}
          </div>
        ) : (
          ''
        )}

        <p className="count">
          {this.props.totalSupply.toLocaleString()}/
          {this.props.maxSupply.toLocaleString()}
        </p>
      </div>
    )

    return (
      <div className="inner">
        <h2 className="logo">
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/logo_full_color.svg"
            alt="Chimerative Monsters"
          />
        </h2>
        {this.props.saleStart.pre &&
        !this.props.saleStart.pub &&
        !this.props.whitelist.isWhitelist ? (
          <div className="mint_price">
            {/*<picture>
              <source
                type="image/webp"
                srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/mint_price.webp"
              />
              <img
                src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/mint_price.png"
                alt="Price"
              />
            </picture>*/}
            <p
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--main-color)',
              }}
            >
              PRICE: 0.02 ETH
            </p>
            <p
              style={{
                marginTop: '.5rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--main-color)',
              }}
            >
              PreSale 1MINT / PubSale 5MINT
            </p>
          </div>
        ) : (
          <div className="mint_price">
            <p
              style={{
                fontSize: '2rem',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--main-color)',
              }}
            >
              PRICE: 0.02 ETH
            </p>
            <p
              style={{
                marginTop: '.5rem',
                fontSize: '1rem',
                fontWeight: 'bold',
                textAlign: 'center',
                color: 'var(--main-color)',
              }}
            >
              PreSale 1MINT / PubSale 5MINT
            </p>
          </div>
        )}
        {!this.props.isLoading ? mintRows : ''}
        <div className="txt-box">
          {this.props.saleStart.pre || this.props.saleStart.pub ? (
            ''
          ) : (
            <p className="message">
              Please wait a little longer for the sale to start...
            </p>
          )}

          {this.props.saleStart.pre &&
          !this.props.saleStart.pub &&
          !this.props.whitelist.isWhitelist ? (
            <p className="message">Currently only WhiteList users can MINT.</p>
          ) : (
            ''
          )}

          <p className="address">
            Your wallet address:{' '}
            <span className="num">{this.props.wallet.address}</span>
          </p>
          {this.props.whitelist.isWhitelist ? (
            <p className="message">You are Allow List User</p>
          ) : (
            ''
          )}

          {this.props.isLoading ? (
            <p className="message">Data Loading...</p>
          ) : (
            ''
          )}
        </div>
        <button className="load-btn" onClick={this.props.viewUpdate.bind(this)}>
          <picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/reload.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/reload.png"
              alt="DATA LOAD"
            />
          </picture>
        </button>

        {this.props.claimed.pre + this.props.claimed.pub > 0 && (
          <p
            className="claimed"
            style={{
              fontSize: '1rem',
              fontWeight: 'bold',
              textAlign: 'center',
              color: 'var(--main-color)',
            }}
          >
            【 🎉 {this.props.claimed.pre + this.props.claimed.pub} minted! 】
          </p>
        )}
      </div>
    )
  }
}

chimerativeMintStart.propTypes = {
  viewUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
  isTransaction: PropTypes.bool,
  maxSupply: PropTypes.number,
  totalSupply: PropTypes.number,
  wallet: PropTypes.object,
  contract: PropTypes.object,
  network: PropTypes.object,
  whitelist: PropTypes.object,
  saleStart: PropTypes.object,
  mintPrice: PropTypes.object,
  claimed: PropTypes.object,
}
export default chimerativeMintStart
