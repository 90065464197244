import React from "react"
import PropTypes from "prop-types"

class peojectGalleryChimerativeModal extends React.Component {
  render() {
    const closeBtnStyle = {
      margin: "3rem auto 0",
      padding: "1rem 3rem",
      background: "transparent",
      color: "var(--main-color)",
      border: "1px solid var(--main-color)",
      borderRadius: '.5rem',
      display: 'block',
      fontSize: '1rem'
    }

    return <div className={`gallery-modal ${this.props.isModalOpen ? 'show' : ''}`}>
      <div className="modal-wrapper" onClick={this.props.closeModal.bind(this)}></div>
      <div className="modal">
        <div className="inner">
          <div className="top">
            <div className="left">
              <div className="image" style={{ backgroundImage: `url(${this.props.imageBaseUri}/${this.props.modalChimera.number}.gif)` }}></div>
            </div>
            <div className="right">
              <p className="num">#{ ( '0000' + this.props.modalChimera.number ).slice( -4 ) }</p>
              <div className="name">
                <h2 className="en">{ this.props.modalChimera.nameEn.length > 0 ? this.props.modalChimera.nameEn : 'no name' }</h2>
                <p className="ja">{ this.props.modalChimera.nameJp }</p>
              </div>
            </div>
          </div>
          <div className="bottom">
            <p className="desc-en" dangerouslySetInnerHTML={{ __html: this.props.modalChimera.descEn }}></p>

            <p className="desc-ja" dangerouslySetInnerHTML={{ __html: this.props.modalChimera.descJp }}></p>

            <button style={closeBtnStyle} onClick={this.props.closeModal.bind(this)}>CLOSE</button>
          </div>
        </div>
      </div>
    </div>
  }
}

peojectGalleryChimerativeModal.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  imageBaseUri: PropTypes.string,
  modalChimera: PropTypes.object,

}
export default peojectGalleryChimerativeModal
