import React from 'react'

class MintTransaction extends React.Component {
  render() {
    return (
      <p className="load-effect" style={{ fontSize: '2rem', margin: '1rem 0' }}>
        <span>■</span>
        <span>■</span>
        <span>T</span>
        <span>R</span>
        <span>A</span>
        <span>N</span>
        <span>S</span>
        <span>A</span>
        <span>C</span>
        <span>T</span>
        <span>I</span>
        <span>O</span>
        <span>N</span>
        <span>　</span>
        <span>N</span>
        <span>O</span>
        <span>W</span>
        <span>■</span>
        <span>■</span>
      </p>
    )
  }
}

export default MintTransaction
