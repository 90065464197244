import React from 'react'
import PropTypes from 'prop-types'
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'

let provider, signer, contract

class asagiMint extends React.Component {
  state = {
    abi: [
      // Contractの状態管理
      {
        inputs: [],
        name: 'totalSupply',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: '_totalSupply',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      // BalanceOf
      {
        inputs: [
          {
            internalType: 'address',
            name: 'owner',
            type: 'address',
          },
        ],
        name: 'balanceOf',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },

      // PreSale
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'quantity',
            type: 'uint256',
          },
          {
            internalType: 'bytes32[]',
            name: '_merkleProof',
            type: 'bytes32[]',
          },
        ],
        name: 'wlMint',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [],
        name: 'wlSaleStart',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },

      // Public Sale
      {
        inputs: [],
        name: 'MintLimit',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'uint256',
            name: 'quantity',
            type: 'uint256',
          },
        ],
        name: 'psMint',
        outputs: [],
        stateMutability: 'payable',
        type: 'function',
      },
      {
        inputs: [
          {
            internalType: 'address',
            name: '',
            type: 'address',
          },
        ],
        name: 'Minted',
        outputs: [
          {
            internalType: 'uint256',
            name: '',
            type: 'uint256',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'saleStart',
        outputs: [
          {
            internalType: 'bool',
            name: '',
            type: 'bool',
          },
        ],
        stateMutability: 'view',
        type: 'function',
      },
    ],
    whitelistAddresses: [],
    network: '',
    isMetaMask: false,
    isConnected: false,
    isTransaction: false,
    isLoading: true,
    wlSaleStart: false,
    saleStart: false,
    totalSupply: 0,
    maxSupply: 1500,
    balanceOf: 0,
    mintLimit: 0,
    minted: 0,

    // Mint数管理
    canMintLimit: 0,

    // ユーザーウォレット情報
    account: null,
    walletBalance: 0,

    // White List
    isWhiteListUser: false,
    hexProof: null,

    // ユーザーアクション
    quantity: 0,
  }

  async setCanMintLimit() {
    const canMintLimit = this.state.mintLimit - this.state.minted
    this.setState({
      canMintLimit: canMintLimit,
    })
  }

  // MerkleTree
  async checkIncludeWhitelist() {
    const leafNodes = this.state.whitelistAddresses.map((addr) =>
      keccak256(addr)
    )
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })
    const rootHash = tree.getRoot() // ルートハッシュを取得

    const keccakAddr = keccak256(this.state.account)
    const hexProof = tree.getHexProof(keccakAddr)
    const result = tree.verify(hexProof, keccakAddr, rootHash)
    console.log(this.state.account, 'included in the white list?:', result)

    this.setState({
      isWhiteListUser: result,
      hexProof: result ? hexProof : null,
    })
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
          this.viewUpdate()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async walletBalance() {
    provider.getBalance(this.state.account).then((balance) => {
      this.setState({
        walletBalance: balance,
      })
    })
  }

  // Read Contract - Contract自体の状態を取得
  async totalSupply() {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let totalSupply = await contract.totalSupply()
      totalSupply = totalSupply.toNumber()

      this.setState({ totalSupply: totalSupply }, () => {
        const countDOM = document.querySelector('.count')
        const current = countDOM.querySelector('.current')
        current.textContent = totalSupply
      })
    } catch (error) {
      console.error(error)
      return 0
    }
  }
  async maxSupply() {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let maxSupply = await contract._totalSupply()
      maxSupply = maxSupply.toNumber()

      this.setState({ maxSupply: maxSupply }, () => {
        const countDOM = document.querySelector('.count')
        const current = countDOM.querySelector('.total')
        current.textContent = maxSupply
      })
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async checkMintLimit() {
    try {
      let mintLimit = await contract.MintLimit()
      mintLimit = mintLimit.toNumber()

      this.setState({
        mintLimit: mintLimit,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async checkSaleStart() {
    try {
      let saleStart = await contract.saleStart()
      let wlSaleStart = await contract.wlSaleStart()
      this.setState({
        saleStart: saleStart,
        wlSaleStart: wlSaleStart,
      })
    } catch (error) {
      console.error(error)
    }
  }

  // Read Contract - ユーザーに紐づく情報を取得
  async checkMinted(address) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let minted = await contract.Minted(address)
      minted = minted.toNumber()

      this.setState({
        minted: minted,
      })
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  async balanceOf(address) {
    if (!this.state.account || !this.props.contractAddress) return

    try {
      let balanceOf = await contract.balanceOf(address)
      balanceOf = balanceOf.toNumber()

      this.setState({ balanceOf: balanceOf })
    } catch (error) {
      console.error(error)
      return 0
    }
  }

  // Write Contract
  async wlMint(e) {
    if (
      !this.state.account ||
      !this.props.contractAddress ||
      !this.state.wlSaleStart ||
      !this.state.isWhiteListUser
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    const amount = this.props.wlMintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (
      ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)
    ) {
      return alert('Funds are in short supply.')
    }

    let setGasLimit = this.state.walletBalance
    let gasLimit = ''

    gasLimit = await contract.estimateGas
      .wlMint(quantity, this.state.hexProof, {
        value: ethers.utils.parseEther(payableAmount),
        gasLimit: setGasLimit,
      })
      .catch((error) => {
        console.error(error)
        alert('Failed to get a gas estimate.')
      })

    if (!gasLimit) return

    this.setState(
      {
        isTransaction: true,
      },
      async () => {
        try {
          const transaction = await contract.wlMint(
            quantity,
            this.state.hexProof,
            {
              value: ethers.utils.parseEther(payableAmount),
              gasLimit: gasLimit,
            }
          )

          console.log(
            `https://${
              this.state.network.name !== 'homestead'
                ? `${this.state.network.name}.`
                : ''
            }etherscan.io/tx/${transaction.hash}`
          )
          await transaction.wait() // トランザクション完了まで待つ
          this.setState({
            isLoading: true,
            isTransaction: false,
            quantity: 0,
          })
          this.viewUpdate()
        } catch (error) {
          console.error(error)
          this.setState({
            isTransaction: false,
            quantity: 0,
          })
          alert('MINT failed.')
          this.viewUpdate()
        }
      }
    )
  }

  async psMint(e) {
    if (
      !this.state.account ||
      !this.props.contractAddress ||
      !this.state.saleStart
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    const amount = this.props.mintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (
      ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)
    ) {
      return alert('Funds are in short supply.')
    }

    let setGasLimit = this.state.walletBalance
    let gasLimit = ''

    gasLimit = await contract.estimateGas
      .psMint(quantity, {
        value: ethers.utils.parseEther(payableAmount),
        gasLimit: setGasLimit,
      })
      .catch((error) => {
        console.error(error)
        alert('Failed to get a gas estimate.')
      })

    if (!gasLimit) return

    this.setState(
      {
        isTransaction: true,
      },
      async () => {
        try {
          const transaction = await contract.psMint(quantity, {
            value: ethers.utils.parseEther(payableAmount),
            gasLimit: gasLimit,
          })

          console.log(
            `https://${
              this.state.network.name !== 'homestead'
                ? `${this.state.network.name}.`
                : ''
            }etherscan.io/tx/${transaction.hash}`
          )
          await transaction.wait() // トランザクション完了まで待つ
          this.setState({
            isLoading: true,
            isTransaction: false,
            quantity: 0,
          })
          this.viewUpdate()
        } catch (error) {
          console.error(error)
          this.setState({
            isTransaction: false,
            quantity: 0,
          })
          alert('MINT failed.')
          this.viewUpdate()
        }
      }
    )
  }

  async componentDidMount() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          isLoading: true,
          account: accounts[0].toLowerCase(),
        },
        async () => {
          await this.initialLoading()
          await this.viewUpdate()
        }
      )
    })

    // MINTモーダルを開いた際に更新
    document
      .querySelector('#openModalBtn')
      .addEventListener('click', async () => {
        await this.initialLoading()
        await this.viewUpdate()
      })
  }

  // View関連
  async initialLoading() {
    await this.maxSupply()
    await this.checkMintLimit()
  }
  async viewUpdate() {
    if (!this.props.contractAddress && !this.state.account) return

    this.setState({ isLoading: true })
    this.walletBalance()
    await this.balanceOf(this.state.account)
    await this.checkMinted(this.state.account)
    await this.checkIncludeWhitelist()
    await this.checkSaleStart()
    await this.totalSupply()
    await this.setCanMintLimit()

    this.setState({ isLoading: false })
  }

  async reload() {
    await this.initialLoading()
    await this.viewUpdate()
    this.setState({
      quantity:
        this.state.quantity > this.state.canMintLimit
          ? this.state.canMintLimit
          : this.state.quantity,
    })
  }

  async increaseQuantity() {
    if (this.state.quantity >= 5) return
    if (this.state.quantity >= this.state.maxSupply - this.state.totalSupply)
      return
    if (
      !this.state.saleStart &&
      this.state.mintLimit <= 3 &&
      this.state.quantity >= 3
    ) {
      await this.checkMintLimit()
      await this.setCanMintLimit()
    }

    let canMintLimit = 0
    canMintLimit = this.state.canMintLimit
    this.setState({
      quantity:
        this.state.quantity < canMintLimit
          ? this.state.quantity + 1
          : canMintLimit,
    })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  render() {
    const wlMintBtn =
      this.state.wlSaleStart &&
      this.state.isWhiteListUser &&
      this.state.canMintLimit >= this.state.quantity &&
      this.state.canMintLimit > 0 ? (
        <button className="mint-btn" onClick={this.wlMint.bind(this)}>
          WL MINT
        </button>
      ) : (
        ''
      )

    const mintBtn =
      this.state.saleStart &&
      this.state.canMintLimit >= this.state.quantity &&
      this.state.canMintLimit > 0 ? (
        <button className="mint-btn" onClick={this.psMint.bind(this)}>
          MINT
        </button>
      ) : (
        ''
      )

    const mintBtns = !this.state.isTransaction ? (
      <div className="buttons">
        {wlMintBtn}
        {mintBtn}
      </div>
    ) : (
      <div className="buttons">
        <button className="mint-btn" disabled>
          Transaction Now
        </button>
      </div>
    )

    const quantityCtl =
      this.state.canMintLimit > 0 || this.state.canMintLimit > 0 ? (
        <div className="quantity-ctl">
          <button
            className="decreaseBtn"
            onClick={this.decreaseQuantity.bind(this)}
          >
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/asagi/img/icon/arrow_left.svg"
              alt="decrease button"
              loading="lazy"
            />
          </button>
          <p className="quantity">{this.state.quantity}</p>
          <button
            className="increaseBtn"
            onClick={this.increaseQuantity.bind(this)}
          >
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/asagi/img/icon/arrow_right.svg"
              alt="increase button"
              loading="lazy"
            />
          </button>
        </div>
      ) : (
        <p className="mint-limit">No number of slots available for MINT.</p>
      )

    const watingMessage =
      this.state.isTransaction || this.state.isLoading ? (
        <p className="mint-limit">Loading...</p>
      ) : (
        <p className="mint-limit">
          Please wait a little longer for the sale to start!
        </p>
      )

    const mintArea = !this.state.account ? (
      <div className="mint-area">
        <p className="mint-limit">Please use a browser with METAMASK.</p>
      </div>
    ) : (
      <div className="mint-area">
        <p className="supply font-en">
          {this.state.totalSupply}/{this.state.maxSupply}
        </p>
        <p className="mint-limit">
          PreSale: MAX {this.state.mintLimit} MINT / Public Sale: MAX 5 MINT
          <br />
          PreSale {this.props.wlMintPrice}ETH / Public Sale{' '}
          {this.props.mintPrice}ETH
        </p>

        {(!this.state.isTransaction &&
          this.state.wlSaleStart &&
          this.state.isWhiteListUser) ||
        (!this.state.isTransaction && this.state.saleStart)
          ? quantityCtl
          : ''}

        {((this.state.wlSaleStart && this.state.isWhiteListUser) ||
          this.state.saleStart) &&
        !this.state.isLoading
          ? mintBtns
          : watingMessage}

        <p className="reload font-en" onClick={this.reload.bind(this)}>
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/asagi/img/icon/icon_reload.svg"
            alt="reload icon"
          />
          &nbsp;Data Reload
        </p>
        <p className="address">
          Your Wallet Address: {this.state.account}
          {this.state.isWhiteListUser ? (
            <React.Fragment>(Whitelist already added.)</React.Fragment>
          ) : (
            ''
          )}
        </p>
      </div>
    )

    return this.state.maxSupply > this.state.totalSupply ? (
      mintArea
    ) : (
      <div className="mint-area">
        <p className="supply font-en">
          {this.state.totalSupply}/{this.state.maxSupply}
        </p>
        <p className="supply font-en">
          【Sold out】
          <br />
          Thank you!
        </p>
      </div>
    )
  }
}

asagiMint.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  wlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
}
export default asagiMint
