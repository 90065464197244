import React from "react"
import PropTypes from "prop-types"

class ProjectFormsAddressCheck extends React.Component {
  state = {
    abi: [
      {
        "inputs": [
          {
            "internalType": "address",
            "name": "owner",
            "type": "address"
          }
        ],
        "name": "balanceOf",
        "outputs": [
          {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
          }
        ],
        "stateMutability": "view",
        "type": "function"
      },
    ],
    network: '',
    balanceOf: 0,

    // ユーザーウォレット情報
    account: this.props.address,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break;
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break;
      case 'polygon': // Polygon(MATIC)
        network = {
          id: '0x89',
          name: 'matic',
        }
        break;
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  // Read Contract
  async balanceOf(address) {
    if (!this.state.account && !address) return

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any")
    provider.on("network", network => {
      if (network.name != this.state.network.name) {
        return ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [{
            chainId: this.state.network.id,
          }]
        }).then(() => {
          alert('ネットワークを変更したのでページをリロードします')
          window.location.reload()
        })
      }
    })

    const signer = provider.getSigner()
    const contract = new ethers.Contract(this.props.contractAddress, this.state.abi, signer)

    try {
      let balanceOf = await contract.balanceOf(address)
      balanceOf = balanceOf.toNumber()

      this.setState(({
        balanceOf: balanceOf,
      }), () => {
        const balanceOf = document.querySelector('#balanceOf')
        const form = document.querySelector('#form')
        balanceOf.innerText = this.state.balanceOf
        if (this.state.balanceOf > 0) {
          form.style.display = 'flex'
        } else {
          form.style.display = 'none'
        }
      })
    } catch (error) {
      console.error(error)
    }
  }

  checkChangeAddress() {
    const addressForm = document.querySelector('#dummy_wallet_address')
    const dummyAddress = addressForm.value
    if (!dummyAddress || dummyAddress === this.state.account) return

    this.setState(({ account: dummyAddress }), () => { this.viewUpdate() })
  }

  async componentDidMount() {
    if (window.ethereum?.isMetaMask) {
      this.setNetwork()
      this.viewUpdate()
    }

    this.intervalId = setInterval(() => this.checkChangeAddress(), 1000)
  }

  componentWillUnmount() {
    clearInterval(this.intervalId)
  }

  // View関連
  viewUpdate() {
    if (!this.props.contractAddress) return

    this.balanceOf(this.state.account)
  }

  render() {
    return ''
  }
}

ProjectFormsAddressCheck.propTypes = {
  contractAddress: PropTypes.string,
  network: PropTypes.string,
  lang: PropTypes.string,
  address: PropTypes.string,
}
export default ProjectFormsAddressCheck
