import React from 'react'
import PropTypes from 'prop-types'

import OpenMetaMaskModal from '../../contract/openMetaMaskModal'
import Loading from '../loading'
import Transaction from '../transaction'

let provider, signer, contract
class ToyboyMint extends React.Component {
  state = {
    abi: require('./toyboyAbi.json'),
    account: '',
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: false,

    MAX_SUPPLY: 0,
    totalSupply: 0,
    preSaleStart: false,
    pubSaleStart: false,

    // WL関係
    isWhitelist: false,
    merkleProof: [],

    // mint関係
    isTransaction: false,
    mintLimit: 0,
    claimed: 0,
    quantity: 0,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async reload() {
    this.setState({ isLoading: true }, async () => {
      await this.viewUpdate()
      this.setState({ isLoading: false })
    })
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.maxSupply()
    await this.totalSupply()
    await this.checkWhitelist()
  }

  async viewUpdate() {
    await this.checkSaleStart()
    await this.maxSupply()
    await this.totalSupply()
    await this.verifyWhitelist()
    await this.mintLimit()
    await this.claimed()
  }

  async componentDidMount() {
    this.setState({ isLoading: true }, async () => {
      await this.initialLoading()
      await this.viewUpdate()
      this.setState({ isLoading: false })
    })

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          account: accounts[0].toLowerCase(),
        },
        async () => {
          this.setState({ isLoading: true }, async () => {
            await this.initialLoading()
            await this.viewUpdate()
            this.setState({ isLoading: false })
          })
        }
      )
    })
  }

  // Contract Call
  async checkSaleStart() {
    if (!this.state.account || !contract) return

    try {
      const preSaleStart = await contract.preSaleStart()
      const pubSaleStart = await contract.pubSaleStart()

      this.setState({
        preSaleStart: preSaleStart,
        pubSaleStart: pubSaleStart,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async maxSupply() {
    if (!this.state.account || !contract) return
    try {
      const maxSupply = await contract.max_supply()
      this.setState({ MAX_SUPPLY: maxSupply.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async totalSupply() {
    if (!this.state.account || !contract) return
    try {
      const totalSupply = await contract.totalSupply()
      this.setState({ totalSupply: totalSupply.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async checkWhitelist() {
    if (!this.state.account || !contract || this.isSoldOut()) return

    const uri = `https://ea2eduf711.execute-api.ap-northeast-1.amazonaws.com/pna_merkleproof?slug=toyboy&address=${this.state.account}`

    let isWhitelist = false
    let merkleProof = []
    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        isWhitelist = data.isWhiteList
        merkleProof = data.merkleProof
      })

    this.setState(
      {
        isWhitelist: isWhitelist,
        merkleProof: merkleProof,
      },
      async () => {
        if (isWhitelist) this.verifyWhitelist()
      }
    )
  }

  async verifyWhitelist() {
    if (!this.state.account || !contract || this.isSoldOut()) return
    if (!this.state.isWhitelist || this.state.merkleProof.length < 1) return

    try {
      // WLユーザー判定された場合、Lambdaから返されたmerkleProofを元にcontractが通るかを問い合わせる
      const isVerify = await contract.checkMerkleProof(this.state.merkleProof)

      if (!isVerify) {
        alert('ALの認証に失敗している可能性があるため、ページをリロードします')
        window.location.reload()
      }
    } catch (error) {
      console.error(error)
    }
  }

  async mintLimit() {
    if (!this.state.account || !contract) return
    try {
      const mintLimit = await contract.mintLimit()
      this.setState({ mintLimit: mintLimit.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  async claimed() {
    if (!this.state.account || !contract) return
    try {
      const claimed = await contract.claimed(this.state.account)
      this.setState({ claimed: claimed.toNumber() })
    } catch (error) {
      console.error(error)
    }
  }

  canMintLimit() {
    return this.state.mintLimit - this.state.claimed
  }

  async increaseQuantity() {
    if (this.state.quantity >= this.state.mintLimit) return
    if (this.state.quantity >= this.state.MAX_SUPPLY - this.state.totalSupply)
      return
    if (this.state.quantity >= this.canMintLimit()) await this.mintLimit()

    const mintLimit = this.canMintLimit()
    this.setState({
      quantity:
        this.state.quantity < mintLimit ? this.state.quantity + 1 : mintLimit,
    })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  // WriteContract
  async preMint(e) {
    if (
      this.state.isTransaction ||
      !contract ||
      !this.state.account ||
      !this.state.preSaleStart ||
      !this.state.isWhitelist ||
      !this.state.merkleProof
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('MINTしたい数量を1以上に設定してください')
      return
    }

    const canMintLimit = this.canMintLimit()
    if (quantity > canMintLimit) {
      alert('MINT可能な数量をオーバーしています')
      return
    }

    const amount = this.props.wlMintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.preMint(
        quantity,
        this.state.merkleProof,
        {
          value: ethers.utils.parseEther(payableAmount),
        }
      )

      console.log(
        `https://${
          this.state.network.name !== 'homestead'
            ? `${this.state.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.reload()
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.viewUpdate()
    }
  }

  async pubMint(e) {
    if (
      this.state.isTransaction ||
      !contract ||
      !this.state.account ||
      !this.state.pubSaleStart
    )
      return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('MINTしたい数量を1以上に設定してください')
      return
    }

    const canMintLimit = this.canMintLimit()
    if (quantity > canMintLimit) {
      alert('MINT可能な数量をオーバーしています')
      return
    }

    const amount = this.props.mintPrice
    let payableAmount = String((amount * 1000 * (quantity * 1000)) / 1000000) // 浮動小数対策

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.pubMint(quantity, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(
        `https://${
          this.state.network.name !== 'homestead'
            ? `${this.state.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.reload()
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({
        quantity: 0,
        isTransaction: false,
      })
      this.viewUpdate()
    }
  }

  errorHandling(error) {
    if (
      error.message ===
      'MetaMask Tx Signature: User denied transaction signature.'
    ) {
      alert('MINTをキャンセルしました')
    } else if (
      error.message.indexOf('insufficient funds for gas * price + value') != -1
    ) {
      alert(
        'MINTに必要な資金が不足しています。\n十分に資金があるにも関わらずこのメッセージが表示される場合は、この画面のスクリーンショットを運営までお知らせください。\n' +
          `wallet: ${this.state.account}\n============\n` +
          error
      )
    } else if (error.message.indexOf('transaction failed') != -1) {
      const scanUri = `https://${
        this.state.network.name !== 'homestead'
          ? `${this.state.network.name}.`
          : ''
      }etherscan.io/tx/${error.transactionHash}`
      console.error(error)

      if (
        confirm(
          'Transactionに失敗しました。\n詳細をetherscanで確認しますか？\n(OKを押すとetherscanへ遷移します)\n' +
            scanUri
        )
      ) {
        window.location.href = scanUri
      }
    } else if (error.message.indexOf('Max supply over') != -1) {
      alert(
        'Mint指定数が総供給量をオーバーしたため失敗しました。\n最新のコントラクト情報を確認するには、「DATA LODING」を押してください。'
      )
    } else {
      alert(
        '失敗しました。この画面をスクリーンショットで撮影し、運営までお知らせください。\n' +
          `wallet: ${this.state.account}\n` +
          error
      )
    }
  }

  isSoldOut() {
    return this.state.MAX_SUPPLY === this.state.totalSupply
  }

  render() {
    return (
      <React.Fragment>
        <OpenMetaMaskModal deepLink="https://metamask.app.link/dapp/play-nft.art/mints/toyboy" />

        <div className="heading">
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <p className="stage">
              [&nbsp;
              {this.isSoldOut()
                ? 'SOLD OUT'
                : this.state.pubSaleStart
                ? 'PUBLIC SALE NOW'
                : this.state.preSaleStart
                ? 'ALLOW LIST SALE NOW'
                : 'BEFORE SALES START'}
              &nbsp;/&nbsp;You&nbsp;are&nbsp;
              {this.isSoldOut() ? '' : this.state.isWhitelist ? 'AL' : 'Public'}
              &nbsp;User&nbsp;]
            </p>
          )}
          <h1 className="ttl mt10">toyboy Minting</h1>
          <p className="price">
            AL&nbsp;MINT&nbsp;:&nbsp;{this.props.wlMintPrice}
            &nbsp;ETH&nbsp;/&nbsp;Public&nbsp;MINT&nbsp;:&nbsp;
            {this.props.mintPrice}&nbsp;ETH
          </p>
        </div>

        <p className="supply">
          {this.state.isLoading ? 'XXXX' : this.state.totalSupply} /{' '}
          {this.state.MAX_SUPPLY}
        </p>

        {this.isSoldOut() ||
        (this.state.claimed >= this.state.mintLimit && this.state.mintLimit) ===
          0 ? (
          ''
        ) : this.state.isTransaction ? (
          <Transaction />
        ) : this.state.isLoading ? (
          ''
        ) : (
          <div className="quantity-ctl">
            <button
              className="btn decrease"
              onClick={this.decreaseQuantity.bind(this)}
            ></button>
            <p className="num">{this.state.quantity}</p>
            <button
              className="btn increase"
              onClick={this.increaseQuantity.bind(this)}
            ></button>
          </div>
        )}

        {this.isSoldOut() ? (
          <p className="supply">Thank you SOLD OUT</p>
        ) : this.state.claimed >= this.state.mintLimit ? (
          <p className="supply">
            Thank you full minted!
            <br />
            <span style={{ fontSize: '1.5rem' }}>
              Currently, the maximum number is {this.state.mintLimit} TB.
            </span>
          </p>
        ) : (
          ''
        )}

        {this.isSoldOut() || this.state.claimed >= this.state.mintLimit ? (
          ''
        ) : (
          <div className="buttons">
            {this.state.isWhitelist ? (
              <button
                className="default-btn"
                disabled={
                  !this.state.preSaleStart ||
                  this.state.isLoading ||
                  this.state.isTransaction
                }
                onClick={this.preMint.bind(this)}
              >
                AL&nbsp;MINT
              </button>
            ) : (
              ''
            )}
            <button
              className="default-btn"
              disabled={
                !this.state.pubSaleStart ||
                this.state.isLoading ||
                this.state.isTransaction
              }
              onClick={this.pubMint.bind(this)}
            >
              Public&nbsp;MINT
            </button>
          </div>
        )}
        <button
          className={`reload-btn ${this.state.isLoading ? 'loading' : ''}`}
          onClick={this.reload.bind(this)}
        >
          DATA&nbsp;LOADING
        </button>
        {this.state.account ? (
          <div
            style={{
              width: '100%',
              marginTop: '2rem',
              padding: '.5rem 1rem',
              background: '#fff',
            }}
          >
            {this.state.account ? (
              <p
                style={{
                  color: 'var(--main-color)',
                  fontSize: '.8rem',
                  textAlign: 'center',
                }}
              >
                🤖&nbsp;{this.state.account}
              </p>
            ) : (
              ''
            )}
            {this.state.claimed ? (
              <p
                style={{
                  marginTop: '.5rem',
                  color: 'var(--main-color)',
                  fontSize: '1.2rem',
                  textAlign: 'center',
                }}
              >
                {this.state.claimed}&nbsp;Minted&nbsp;🎉
              </p>
            ) : (
              ''
            )}
          </div>
        ) : (
          ''
        )}
      </React.Fragment>
    )
  }
}

ToyboyMint.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  wlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
}
export default ToyboyMint
