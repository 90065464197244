import React from 'react'

class bdpMemberArea extends React.Component {
  render() {
    return (
      <div className="team-area scroll-target" id="member">
        <section className="section">
          <h2 className="section-ttl">Team Member</h2>
          <div className="members">
            <div className="member">
              <div className="image">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/kamikaze.webp"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/kamikaze.png"
                    alt="kamikaze icon"
                  />
                </picture>
              </div>
              <h2 className="job">Founder & Creator</h2>
              <p className="name">kamikaze</p>
              <a
                href="https://twitter.com/kamikaze_ku92"
                target="_blank"
                rel="nofollow noreferrer noopener"
                className="tw-link"
              >
                @kamikaze_ku92
              </a>
            </div>

            <div className="member">
              <div className="image">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/genta.webp"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/genta.png"
                    alt="genta icon"
                  />
                </picture>
              </div>
              <h2 className="job">Developer</h2>
              <p className="name">Genta</p>
              <a
                href="https://twitter.com/genta_ikikata"
                target="_blank"
                rel="nofollow noreferrer noopener"
                className="tw-link"
              >
                @genta_ikikata
              </a>
            </div>

            <div className="member">
              <div className="image">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/soco.webp"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/soco.png"
                    alt="soco icon"
                  />
                </picture>
              </div>
              <h2 className="job">Marketer</h2>
              <p className="name">soco</p>
              <a
                href="https://twitter.com/soco_art"
                target="_blank"
                rel="nofollow noreferrer noopener"
                className="tw-link"
              >
                @soco_art
              </a>
            </div>

            <div className="member">
              <div className="image">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/charon.webp"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/charon.png"
                    alt="charon icon"
                  />
                </picture>
              </div>
              <h2 className="job">Supporter</h2>
              <p className="name">CharonCrypto</p>
              <a
                href="https://twitter.com/CharonCrypto"
                target="_blank"
                rel="nofollow noreferrer noopener"
                className="tw-link"
              >
                @CharonCrypto
              </a>
            </div>

            <div className="member">
              <div className="image">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/shinopi.webp"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/bdp/img/member/shinopi.png"
                    alt="shinopi icon"
                  />
                </picture>
              </div>
              <h2 className="job">Evangelist</h2>
              <p className="name">shinopi</p>
              <a
                href="https://twitter.com/shinoononchan"
                target="_blank"
                rel="nofollow noreferrer noopener"
                className="tw-link"
              >
                @shinoononchan
              </a>
            </div>
          </div>
        </section>

        <section className="section">
          <h2 className="section-ttl">Remarkable Point</h2>
          <div id="videoPlayer" className="player">
            <iframe
              src="https://www.youtube.com/embed/okx-HaX-ulE"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoPlay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="yt jp"
            ></iframe>
          </div>
          <p className="txt font-jp">
            kamikazeがパーツ1つ1つを3Dデータとして作成し、
            <br className="sp-only" />
            1,200種類のBDPを作り出しています。
            <br />
            あなただけの推しBDPを是非手にいれませんか？
          </p>
        </section>

        <section className="section">
          <h2 className="section-ttl">Unreveal Pods</h2>
          <div className="pods">
            <div className="pod">
              <video
                autoPlay
                muted
                playsInline
                loop
                poster="https://hecst7m7dsotzzlkifl3bflhkssa3q4wj7okw5pfjkytqvwifhta.arweave.net/OQUp_Z8cnTzlakFXsJVnVKQNw5ZP3Kt15UqxOFbIKeY/HUMAN.png"
              >
                <source
                  src="https://d2tnrjjpld3h2t.cloudfront.net/bdp/pod/HUMAN.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="pod">
              <video
                autoPlay
                muted
                playsInline
                loop
                poster="https://hecst7m7dsotzzlkifl3bflhkssa3q4wj7okw5pfjkytqvwifhta.arweave.net/OQUp_Z8cnTzlakFXsJVnVKQNw5ZP3Kt15UqxOFbIKeY/ELF.png"
              >
                <source
                  src="https://d2tnrjjpld3h2t.cloudfront.net/bdp/pod/ELF.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="pod">
              <video
                autoPlay
                muted
                playsInline
                loop
                poster="https://hecst7m7dsotzzlkifl3bflhkssa3q4wj7okw5pfjkytqvwifhta.arweave.net/OQUp_Z8cnTzlakFXsJVnVKQNw5ZP3Kt15UqxOFbIKeY/ONI.png"
              >
                <source
                  src="https://d2tnrjjpld3h2t.cloudfront.net/bdp/pod/ONI.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="pod">
              <video
                autoPlay
                muted
                playsInline
                loop
                poster="https://hecst7m7dsotzzlkifl3bflhkssa3q4wj7okw5pfjkytqvwifhta.arweave.net/OQUp_Z8cnTzlakFXsJVnVKQNw5ZP3Kt15UqxOFbIKeY/DEVIL.png"
              >
                <source
                  src="https://d2tnrjjpld3h2t.cloudfront.net/bdp/pod/DEVIL.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="pod">
              <video
                autoPlay
                muted
                playsInline
                loop
                poster="https://hecst7m7dsotzzlkifl3bflhkssa3q4wj7okw5pfjkytqvwifhta.arweave.net/OQUp_Z8cnTzlakFXsJVnVKQNw5ZP3Kt15UqxOFbIKeY/ZOMBIE.png"
              >
                <source
                  src="https://d2tnrjjpld3h2t.cloudfront.net/bdp/pod/ZOMBIE.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
            <div className="pod">
              <video
                autoPlay
                muted
                playsInline
                loop
                poster="https://hecst7m7dsotzzlkifl3bflhkssa3q4wj7okw5pfjkytqvwifhta.arweave.net/OQUp_Z8cnTzlakFXsJVnVKQNw5ZP3Kt15UqxOFbIKeY/ALIEN.png"
              >
                <source
                  src="https://d2tnrjjpld3h2t.cloudfront.net/bdp/pod/ALIEN.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
          <p className="txt font-jp">
            Reveal後に生まれるBDPはそれぞれ
            <br className="sp-only" />
            「Human / ELF / ALIEN / DEVIL / ONI / ZOMBIE」
            <br className="sp-only" />
            6種類のDNAを持っています。
            <br />
            Reveal前の時点では各DNAに対応した
            <br className="sp-only" />
            PODの状態でMINTされます。
            <br />
            <br />
            BDP GenXはRevealをホルダーである
            <br className="sp-only" />
            あなた自身のタイミングで行うことができます。
            <br />
            また、保有BDPのReveal後の姿は事前に同ページ内の
            <a href="#reveal" className="inner-link">
              Reveal機能
            </a>
            でご確認いただけます。
            <br />
            <br />
            手に入れたBDPをRevealさせるも良し、
            <br className="sp-only" />
            PODの状態で大切に保有したり、
            <br />
            「未開封状態」を売り文句に
            <br className="sp-only" />
            2次出品していただくのも良しです。
            <br />
            ※一度Revealさせた作品は、
            <br className="sp-only" />
            PODの状態に戻すことはできません
            <br />
            <br />
            みなさま各々の方法で、
            <br className="sp-only" />
            BDP GenXをお楽しみいただけると嬉しいです。
          </p>
        </section>
      </div>
    )
  }
}
export default bdpMemberArea
