import React from 'react'

class RvltdLoading extends React.Component {
  render() {
    return (
      <p className="stage load-effect">
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>L</span>
        <span>O</span>
        <span>A</span>
        <span>D</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
      </p>
    )
  }
}

export default RvltdLoading
