import React from 'react'
import PropTypes from 'prop-types'

import MintStart from './mintStart'
import MintNotStart from './mintNotStart'
import MintFinish from './mintFinish'

let provider, signer, contract, saleContract
class chimerativeMintModal extends React.Component {
  state = {
    abi: require('./abi/mononokeAbi.json'),
    abiSale: require('./abi/mononokeSaleAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isTransaction: false,
    isLoading: true,
    preSaleStart: false,
    pubSaleStart: false,
    mintLimitPre: 0,
    mintLimitPub: 0,
    claimedPre: 0,
    claimedPub: 0,

    startId: 0,
    endId: 0,
    mintId: 0,
    totalSupply: 0,
    maxSupply: 0,

    // ユーザーウォレット情報
    account: null,
    walletBalance: 0,

    // White List
    isWhiteList: false,
    merkleProof: null,
  }

  // Initialize
  setNetwork() {
    let network = {}
    console.log(this.props.network)

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'sepolia': // Sepolia
        network = {
          id: '0xaa36a7',
          name: 'sepolia',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress || !this.props.saleContractAddress)
            return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )

          saleContract = new ethers.Contract(
            this.props.saleContractAddress,
            this.state.abiSale,
            signer
          )

          this.viewUpdate()
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async walletBalance() {
    provider.getBalance(this.state.account).then((balance) => {
      this.setState({
        walletBalance: balance,
      })
    })
  }

  async checkWhitelist() {
    if (!this.state.account || !contract) return

    const uri = `https://ea2eduf711.execute-api.ap-northeast-1.amazonaws.com/pna_merkleproof?slug=chimerative_mononoke&address=${this.state.account}`

    let isWhiteList = false
    let merkleProof = []
    await fetch(uri)
      .then((response) => response.json())
      .then((data) => {
        ;(isWhiteList = data.isWhiteList), (merkleProof = data.merkleProof)
      })

    this.setState({
      isWhiteList: isWhiteList,
      merkleProof: merkleProof,
    })
  }

  isTransactionFunc(state) {
    this.setState({ isTransaction: state })
  }

  // Read Contract - Contract自体の状態を取得
  async setStartEndIds() {
    if (!saleContract) return

    const startId = Number(await saleContract.startId())
    const endId = Number(await saleContract.endId())
    const mintId = Number(await saleContract.mintId())

    const totalSupply = mintId - startId
    this.props.changeTotalSupply(totalSupply)
    const maxSupply = endId - startId
    this.props.changeMaxSupply(maxSupply)

    this.setState({
      startId: startId,
      endId: endId,
      mintId: mintId,
      totalSupply: totalSupply,
      maxSupply: maxSupply,
    })
  }

  async mintLimit() {
    if (!this.state.account || !saleContract) return

    const mintLimitPre = await saleContract.mintLimit(0)
    const mintLimitPub = await saleContract.mintLimit(1)
    this.setState({
      mintLimitPre: Number(mintLimitPre),
      mintLimitPub: Number(mintLimitPub),
    })
  }

  async claimed() {
    if (!this.state.account || !saleContract) return

    const claimedPre = await saleContract.claimed(this.state.account, 0)
    const claimedPub = await saleContract.claimed(this.state.account, 1)

    this.setState({
      claimedPre: Number(claimedPre),
      claimedPub: Number(claimedPub),
    })
  }

  async saleStart() {
    if (!this.state.account || !saleContract) return

    const preSaleStart = await saleContract.saleStart(0)
    const pubSaleStart = await saleContract.saleStart(1)

    this.setState({ preSaleStart: preSaleStart, pubSaleStart: pubSaleStart })
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setState({ isLoading: true })
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.checkWhitelist()
  }

  async viewUpdate() {
    this.setState({ isLoading: true })
    await this.setStartEndIds()
    await this.walletBalance()
    await this.saleStart()
    await this.mintLimit()
    await this.claimed()

    this.setState({ isLoading: false })
  }

  async componentDidMount() {
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          account: accounts[0].toLowerCase(),
        },
        async () => {
          await this.initialLoading()
          await this.viewUpdate()
        }
      )
    })
  }

  render() {
    return (
      <div className={`mint-wrapper ${this.props.modalOpen ? 'show' : ''}`}>
        <div
          className="overlay"
          onClick={this.props.modalCloseFunc.bind(this)}
        ></div>
        <div className="modal">
          <div className="top"></div>
          <div className="body">
            {!this.state.preSaleStart && !this.state.pubSaleStart ? (
              <MintNotStart
                contract={contract}
                isLoading={this.state.isLoading}
                isTransaction={this.state.isTransaction}
                viewUpdate={this.viewUpdate.bind(this)}
                maxSupply={this.state.maxSupply}
                totalSupply={this.state.totalSupply}
                wallet={{
                  address: this.state.account,
                  balance: this.state.walletBalance,
                }}
                whitelist={{ isWhitelist: this.state.isWhiteList }}
              />
            ) : this.state.endId < this.state.mintId ? (
              <MintFinish />
            ) : (
              <MintStart
                contract={saleContract}
                network={this.state.network}
                isLoading={this.state.isLoading}
                isTransaction={this.state.isTransaction}
                isTransactionFunc={this.isTransactionFunc.bind(this)}
                viewUpdate={this.viewUpdate.bind(this)}
                maxSupply={this.state.maxSupply}
                totalSupply={this.state.totalSupply}
                wallet={{
                  address: this.state.account,
                  balance: this.state.walletBalance,
                }}
                whitelist={{
                  isWhitelist: this.state.isWhiteList,
                  merkleProof: this.state.merkleProof,
                }}
                saleStart={{
                  pre: this.state.preSaleStart,
                  pub: this.state.pubSaleStart,
                }}
                mintLimit={{
                  pre: this.state.mintLimitPre,
                  pub: this.state.mintLimitPub,
                }}
                claimed={{
                  pre: this.state.claimedPre,
                  pub: this.state.claimedPub,
                }}
                mintPrice={{
                  pre: Number(this.props.wlMintPrice),
                  pub: Number(this.props.mintPrice),
                }}
              />
            )}
          </div>
          <div className="bottom"></div>
        </div>
        {this.state.isTransaction ? (
          <div className="transaction-box">
            Transaction Now...
            <div className="image" style={{ maxWidth: '500px' }}>
              <img
                src="https://hev3ezou2eip7y25y5cspk3w34xru5ie6cq4ljtfmvh4yuahlhua.arweave.net/OSuyZdTREP_jXcdFJ6t23y8adQTwocWmZWVPzFAHWeg"
                alt="Transaction Now"
                loading="lazy"
              />
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    )
  }
}

chimerativeMintModal.propTypes = {
  modalOpen: PropTypes.bool,
  changeMaxSupply: PropTypes.func,
  modalCloseFunc: PropTypes.func,
  changeTotalSupply: PropTypes.func,
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  saleContractAddress: PropTypes.string,
  wlMintPrice: PropTypes.number,
  mintPrice: PropTypes.number,
}
export default chimerativeMintModal
