import React from "react"
import PropTypes from "prop-types"

class YakuNft extends React.Component {

  render() {
    const nfts = this.props.nfts.map((nft, key) => <a
      className="nft"
      href={nft.os_url}
      target="_blank"
      rel="nofollow noreferrer noopener"
      key={key}
    >
      <img src={nft.image_uri} alt={nft.name} loading="lazy" />
    </a>)

    return nfts;
  }
}

YakuNft.propTypes = {
  nfts: PropTypes.array,
};
export default YakuNft
