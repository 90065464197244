import React from 'react'
import PropTypes from 'prop-types'

let provider, signer, contract
const jsonBaseUri =
  'https://generative-outputs.s3.ap-northeast-1.amazonaws.com/chimerative_mononoke'
class chimerativeAuctionDetail extends React.Component {
  state = {
    abi: require('./auctionAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: true,
    isTransaction: false,

    haveArcana: 0,

    contractAddress: '',
    tokenId: 0,
    isFinish: false,
    isStart: false,
    startArcana: 0,
    endArcana: 0,
    startTime: 0,
    endTime: 0,
    currentArcana: 0,
    metadata: {},
    attributes: [],
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  async fetchJsonData() {
    if (
      this.state.contractAddress ===
      '0x0000000000000000000000000000000000000000'
    )
      return

    await fetch(
      `${jsonBaseUri}/${this.state.tokenId}.json?t=${Math.floor(
        Date.now() / 1000
      )}`
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({
          metadata: data,
          attributes: 'attributes' in data ? data.attributes : [],
        })
      })
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  currentUnixTime() {
    return Math.floor(Date.now() / 1000)
  }

  // auction
  async currentArcana() {
    if (!this.state.isMetaMask) return

    const currentArcana =
      this.currentUnixTime() >= Number(this.state.startTime)
        ? await contract.currentArcana(this.props.auctionId).catch((error) => {
            const message = error.message
            if (message.indexOf('has already been bid') != -1) {
              return 0
            }
          })
        : 0

    this.setState({
      currentArcana: Number(currentArcana),
    })
  }

  async fetchAuctionDetail() {
    if (!this.state.isMetaMask) return

    const auctionDetail = await contract.auctionDetail(this.props.auctionId)

    this.setState(
      {
        contractAddress: auctionDetail.contractAddress,
        tokenId: Number(auctionDetail.tokenId),
        isFinish:
          auctionDetail.owner !== '0x0000000000000000000000000000000000000000',
        isStart: this.currentUnixTime() >= Number(auctionDetail.startTime),
        startArcana: Number(auctionDetail.startArcana),
        endArcana: Number(auctionDetail.endArcana),
        startTime: Number(auctionDetail.startTime),
        endTime: Number(auctionDetail.endTime),
      },
      async () => await this.fetchJsonData()
    )
  }

  // arcana
  async getArcana() {
    if (!this.state.isMetaMask || !this.state.account) return

    const haveArcana = await contract.getArcana()
    this.setState({ haveArcana: Number(haveArcana).toLocaleString() })
  }

  async bid() {
    if (!this.state.isMetaMask || !this.state.account) return
    this.setState({ isTransaction: true }, async () => {
      const tx = await contract.bid(this.props.auctionId).catch((error) => {
        const message = error.message
        if (message.indexOf('User denied transaction signature.') != -1) {
          alert('キャンセルしました')
        } else if (message.indexOf('Not enough Arcana holdings') != -1) {
          alert('アルカナが不足しています')
        } else if (message.indexOf('Operation not authorized') != -1) {
          alert('アルカナの操作が許可されていません')
        } else if (
          message.indexOf(
            'Cannot be set because it has already been bid on.'
          ) != -1
        ) {
          alert('既に落札されています')
        }

        this.setState({ isTransaction: false })
      })

      if (!tx) return

      await tx
        .wait()
        .then(async () => {
          await this.getArcana()
          await this.fetchAuctionDetail()
          await this.currentArcana()

          alert('bidが完了しました')
        })
        .catch((error) => {
          const message = error.message
          if (message.indexOf('reason="cancelled"') != -1) {
            alert('トランザクションをキャンセルしました')
          } else {
            alert('トランザクションに失敗しました')
          }
        })

      this.setState({ isTransaction: false })
    })
  }

  jstTimefromUnixTime(unixTime) {
    const date = new Date(unixTime * 1000)
    const jstDate = new Date(date.getTime() + 0 * 60 * 60 * 1000)

    return jstDate.toLocaleString('ja-JP')
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setState(
      {
        isLoading: true,
      },
      async () => {
        this.setNetwork()
        await this.setProvider()
        await this.checkMetaMask()
        await this.connectWallet()
        await this.getArcana()
        await this.fetchAuctionDetail()
        await this.currentArcana()
        this.setState({ isLoading: false })
      }
    )
  }

  async componentDidMount() {
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async () => {
      await this.initialLoading()
    })
  }

  findAttributeValue(traitType) {
    if (this.state.attributes.length <= 0) return 'UNKNOWN'

    for (let i = 0; i < this.state.attributes.length; i++) {
      if (this.state.attributes[i].trait_type === traitType) {
        return this.state.attributes[i].value
      }
    }
    return 'NONE'
  }

  render() {
    const buttonStyle = {
      width: 'fit-content',
      marginTop: '2rem',
      fontWeight: 'bold',
      alignSelf: 'flex-start',
      background: 'var(--main-color)',
      fontSize: '1.5rem',
      color: '#f3eada',
      padding: '.3rem 1rem',
      fontFamily: '"Sofia Sans Extra Condensed",sans-serif',
      cursor: 'pointer',
    }
    return (
      <main className="main auction auction-detail">
        <div className="container">
          <div className="section">
            <div className="section-inner">
              <h1 className="title">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/title.svg"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/title.svg"
                    alt="A.R.C.A's AUCTION "
                  />
                </picture>
              </h1>
              <p className="sub-ttl">
                We especially recommend this chimera! Please welcome it!
              </p>
              {!this.state.isMetaMask ? (
                <a
                  style={{
                    width: '100%',
                    fontSize: '1rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    textDecoration: 'underline',
                    marginTop: '1rem',
                  }}
                  href={`https://metamask.app.link/dapp/play-nft.art/chimerative/auction/${this.props.auctionId}`}
                  target="_blank"
                  rel="nofollow noopener noreferrer"
                >
                  PLEASE CONNECT METAMASK
                </a>
              ) : (
                <React.Fragment />
              )}

              {!this.state.isLoading ? (
                <div className="mt2 contents">
                  <div className="bar">
                    <picture>
                      <source
                        media="(max-width: 767px)"
                        srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-top-sp.webp"
                      />
                      <img src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-top.webp" />
                    </picture>
                  </div>
                  <div className="chimera">
                    <div className="number">
                      <div className="treat left"></div>
                      <p className="txt">
                        #{this.props.auctionId.toString().padStart(4, '0')}
                      </p>
                      <div className="treat right"></div>
                    </div>
                    <div className="auction-body">
                      <div className="left">
                        <div className="chimera-image">
                          <img
                            src={
                              'image' in this.state.metadata
                                ? this.state.metadata.image
                                : 'https://generative-outputs.s3.ap-northeast-1.amazonaws.com/chimerative_mononoke/unrevealed.gif'
                            }
                            alt="mononoke Chimera"
                          />
                        </div>
                      </div>
                      <div className="right">
                        <div className="trait-box">
                          <div className="double-line"></div>
                          <h2 className="trait">TRAIT</h2>
                          <div className="double-line"></div>
                        </div>
                        <div className="trait-detail">
                          <div className="content">
                            <h3 className="content-title">BACK</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('BACK')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">BODY</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('BODY')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">DNA</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('DNA')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">EFFECT</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('EFFECT')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">HEAD</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('HEAD')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">LEFT HEAD</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('LEFT HEAD')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">LEG</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('LEG')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">PARTNER</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('PARTNER')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">RIGHT HEAD</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('RIGHT HEAD')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">TAIL</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('TAIL')}
                            </p>
                          </div>
                          <div className="content">
                            <h3 className="content-title">WING</h3>
                            <p className="content-detail">
                              {this.findAttributeValue('WING')}
                            </p>
                          </div>
                        </div>
                        <div className="double-line mt1"></div>
                        {this.state.contractAddress ===
                        '0x0000000000000000000000000000000000000000' ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <div className="auction-area">
                            <div className="arcana">
                              <p className="num">
                                {!this.state.isStart || !this.state.isFinish
                                  ? this.state.currentArcana.toLocaleString()
                                  : !this.state.isStart
                                  ? 'BEFORE START'
                                  : 'FINISH'}
                              </p>
                              {!this.state.isStart || !this.state.isFinish ? (
                                <p className="unit">ARCANA</p>
                              ) : (
                                <React.Fragment></React.Fragment>
                              )}
                            </div>
                            {this.state.isTransaction ||
                            !this.state.isStart ||
                            this.state.isFinish ? (
                              <React.Fragment></React.Fragment>
                            ) : (
                              <button
                                className="buy-now"
                                onClick={this.bid.bind(this)}
                              >
                                <img
                                  src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/buy-button.svg"
                                  alt="BUY NOW"
                                />
                              </button>
                            )}
                          </div>
                        )}
                        {this.state.contractAddress ===
                        '0x0000000000000000000000000000000000000000' ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <React.Fragment>
                            {this.state.isTransaction ? (
                              <p
                                className="desc"
                                style={{
                                  fontSize: '1.2rem',
                                  fontWeight: 'bold',
                                  color: 'var(--main-color)',
                                }}
                              >
                                トランザクションを実行しています。
                                <br />
                                完了までこのままお待ち下さい。
                              </p>
                            ) : (
                              <React.Fragment></React.Fragment>
                            )}
                            <p className="desc">
                              ※アルカナを消費してオークションに入札できます。
                            </p>
                            <div className="tags">
                              <div className="box">
                                <div className="tag">START</div>
                                <p className="num">
                                  <span className="time">
                                    {this.jstTimefromUnixTime(
                                      this.state.startTime
                                    )}{' '}
                                    JST
                                  </span>
                                  <br />
                                  {this.state.startArcana.toLocaleString()}{' '}
                                  ARCANA
                                </p>
                              </div>
                              <div className="box">
                                <div className="tag">FINISH</div>
                                <p className="num">
                                  <span className="time">
                                    {this.jstTimefromUnixTime(
                                      this.state.endTime
                                    )}{' '}
                                    JST
                                  </span>
                                  <br />
                                  {this.state.endArcana.toLocaleString()} ARCANA
                                </p>
                              </div>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '1rem',
                              }}
                            >
                              <div
                                style={buttonStyle}
                                onClick={() =>
                                  this.setState(
                                    {
                                      isLoading: true,
                                    },
                                    async () => {
                                      await this.getArcana()
                                      await this.fetchAuctionDetail()
                                      await this.currentArcana()
                                      this.setState({ isLoading: false })
                                    }
                                  )
                                }
                              >
                                DATA LODING
                              </div>
                              <a
                                style={buttonStyle}
                                href={`${this.props.openseaUri}/${this.state.tokenId}`}
                                target="_blank"
                                rel="nofollow noopener noreferrer"
                              >
                                OpenSea
                              </a>
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="bar">
                    <picture>
                      <source
                        media="(max-width: 767px)"
                        srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-bottom-sp.webp"
                      />
                      <img src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-bottom.webp" />
                    </picture>
                  </div>

                  <div className="detail-have-arcana">
                    You&nbsp;have&nbsp;
                    <span className="num">{this.state.haveArcana}</span>
                    &nbsp;ARCANA
                  </div>
                </div>
              ) : (
                <p
                  style={{
                    fontSize: '2rem',
                    textAlign: 'center',
                    fontWeight: 'bold',
                    padding: '3rem 2rem',
                  }}
                >
                  AUCTION DATA LOADING...
                </p>
              )}

              <div className="title">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/title.svg"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/title.svg"
                    alt="A.R.C.A's AUCTION "
                  />
                </picture>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

chimerativeAuctionDetail.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  auctionId: PropTypes.number,
  openseaUri: PropTypes.string,
}
export default chimerativeAuctionDetail
