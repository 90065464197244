import React from 'react'
import PropTypes from 'prop-types'
import axios from 'axios'

class generativeMetadataEdit extends React.Component {
  state = {
    isLoading: false,
    isShow: false,
    nfts: [],
    partsList: {},
  }

  showMetadata() {
    if (this.state.isShow) {
      this.setState({ isShow: false })
    } else {
      this.setState({ isShow: true })
    }
  }

  fetchPartsList() {
    axios({
      method: 'GET',
      url: `https://pna-generative.s3.ap-northeast-1.amazonaws.com/${this.props.slug}/parts_list.json`,
      headers: {
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        Pragma: 'no-cache',
        Expires: '0',
      },
    })
      .then((response) => {
        this.setState({ partsList: response.data })
      })
      .catch((error) => {
        console.log(error.message)
      })
  }

  changeMetadata(e) {
    const target = e.currentTarget
    const value = target.value
    const attributeName = target.dataset.aname
    const attributeDetail = target.dataset.detail

    const parent = target.parentNode.parentNode.parentNode.parentNode.parentNode

    if (this.props.imageType === 'static') {
      const targetImage = parent.querySelector(
        `[data-meta="${attributeName}-${attributeDetail}"]`
      )
      let src = targetImage.src
      let split = src.split('/')
      const imageIndex = split.length - 1
      split[imageIndex] = `${value}`
      src = split.join('/')
      targetImage.src = src
    } else if (this.props.imageType === 'gif') {
      const targetLayer = target.dataset.layer
      const targetImageBox = parent.querySelectorAll('.image-move')[
        targetLayer - 1
      ]
      const targetImages = targetImageBox.querySelectorAll('img')
      targetImages.forEach((image) => {
        let src = image.src
        let split = src.split('/')
        // directoryを差し替え
        const aIndex = split.length - 3
        split[aIndex] = `${targetLayer}_${attributeName}`
        const dirIndex = split.length - 2
        split[dirIndex] = value

        src = split.join('/')
        image.src = src
      })
    }
  }

  saveMetadata(e) {
    const target = e.currentTarget
    const input = target.parentNode.querySelector('.select')
    const value = input.value
    const attributeName = input.dataset.aname
    const attributeDetail = input.dataset.detail
    const layerIndex = input.dataset.layer

    axios({
      method: 'PUT',
      url: `${this.props.baseURI}api/v1/generative/update_metadata`,
      params: {
        slug: this.props.slug,
        index: this.props.formIndex,
        layer_index: layerIndex,
        name: attributeName,
        detail: attributeDetail,
        value: value,
        type: this.props.imageType,
        padNum: this.props.padNum,
      },
    })
      .then(() => {
        alert('更新しました')
        this.setState({ isLoading: false })
      })
      .catch((error) => {
        console.log(error.response.data)
        this.setState({ isLoading: false })
      })
  }

  componentDidMount() {
    this.fetchPartsList()
  }

  render() {
    const contentType = this.props.imageType === 'gif' ? '' : '.png'

    const form = this.props.metadata.map((data, key) => (
      <div className="data" key={key}>
        <p className="name">{data.attribute_name}</p>
        <div className="meta-form">
          {this.state.partsList[
            `${(Number(key) + 1).toString().padStart(this.props.padNum, '0')}_${
              data.attribute_name
            }`
          ] ? (
            <select
              className="select"
              onChange={this.changeMetadata.bind(this)}
              defaultValue={`${data.rarity}_${data.attribute_detail}${contentType}`}
              data-aname={data.attribute_name}
              data-detail={data.attribute_detail}
              data-layer={key + 1}
            >
              <option value="">パーツを選択してください</option>
              {this.state.partsList[
                `${(Number(key) + 1)
                  .toString()
                  .padStart(this.props.padNum, '0')}_${data.attribute_name}`
              ].map((parts, index) => (
                <option value={parts} key={index}>
                  {parts}
                </option>
              ))}
            </select>
          ) : (
            ''
          )}
          {/*<input type="text" className="detail" defaultValue={data.attribute_detail} onChange={this.changeMetadata.bind(this)} data-index={key}  data-aname={data.attribute_name} data-detail={data.attribute_detail} data-rarity={data.rarity} data-layer={key + 1} />*/}
          <div
            className="submit"
            onClick={this.saveMetadata.bind(this)}
            disabled={this.state.isLoading}
          >
            save
          </div>
        </div>
      </div>
    ))

    return (
      <React.Fragment>
        <div className="edit-metadata">
          <div className="btn" onClick={this.showMetadata.bind(this)}>
            metadata
          </div>
        </div>
        <div className={`metadata ${this.state.isShow ? 'show' : ''}`}>
          {form}
        </div>
      </React.Fragment>
    )
  }
}

generativeMetadataEdit.propTypes = {
  baseURI: PropTypes.string,
  slug: PropTypes.string,
  formIndex: PropTypes.number,
  metadata: PropTypes.array,
  imageType: PropTypes.string,
  padNum: PropTypes.number,
}
export default generativeMetadataEdit
