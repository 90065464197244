import React from "react"
import PropTypes from "prop-types"
import keccak256 from 'keccak256'
import { MerkleTree } from 'merkletreejs'

let provider, signer, contract
class hagMint extends React.Component {
  state = {
    abi: require('./hagAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isTransaction: false,
    isLoading: true,

    saleHStart: false,
    saleGStart: false,
    publicStart: false,

    totalSupply: 0,
    maxSupply: 4444,
    mintLimitH: 0,
    mintLimitG: 0,
    mintLimitP: 0,
    mintedH: 0,
    mintedG: 0,

    // ユーザーウォレット情報
    account: null,
    walletBalance: 0,

    // White List
    hasHpass: false,
    HpassMerkleProof: null,
    hasGpass: false,
    GpassMerkleProof: null,

    // 数量コントロール
    quantity: 0,
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'rinkeby': // Rinkeby
        network = {
          id: '0x4',
          name: 'rinkeby',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, "any")

      provider.on("network", (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{
          chainId: this.state.network.id,
        }]
      }).then(() => {
        alert('Network has been changed. Please reconnect your wallet.')
      })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(({
        isConnected: true,
        account: accounts[0].toLowerCase(),
      }), () => {
        console.log(`Wallet connected: ${this.state.account}`)
        if (!this.props.contractAddress) return

        contract = new ethers.Contract(this.props.contractAddress, this.state.abi, signer)
        this.viewUpdate()
      })
    } catch (error) {
      console.error(error)
    }
  }

  async walletBalance() {
    provider.getBalance(this.state.account)
      .then((balance) => {
        this.setState(({
          walletBalance: balance,
        }))
      })
  }


  // MerkleTree
  async checkIncludeWhitelist(address) {
    const leafNodes = address.map((addr) => keccak256(addr))
    const tree = new MerkleTree(leafNodes, keccak256, { sortPairs: true })
    const rootHash = tree.getRoot() // ルートハッシュを取得

    const keccakAddr = keccak256(this.state.account)
    const hexProof = tree.getHexProof(keccakAddr)
    const result = tree.verify(hexProof, keccakAddr, rootHash)

    return {
      isWhiteListUser: result,
      hexProof: result ? hexProof : null
    }
  }

  async checkWhitelist() {
    this.setState({
      hasHpass: false,
      hasGpass: false,
      HpassMerkleProof: [],
      GpassMerkleProof: []
    })
  }



  isTransactionFunc(state) {
    this.setState({isTransaction: state})
  }

  // Read Contract - Contract自体の状態を取得
  async totalSupply() {
    if (!this.state.account || !contract) return

    try {
      let totalSupply = await contract.totalSupply()
      totalSupply = totalSupply.toNumber()

      this.setState({ totalSupply: totalSupply })
    } catch (error) {
      console.error(error)
    }
  }

  async maxSupply() {
    if (!this.state.account || !contract) return

    try {
      let maxSupply = await contract.MAX_SUPPLY()
      maxSupply = maxSupply.toNumber()

      this.setState({ maxSupply: maxSupply })
    } catch (error) {
      console.error(error)
    }
  }

  async mintLimit() {
    if (!this.state.account || !contract) return

    try {
      let mintLimitH = await contract.MINTLIMIT_H()
      let mintLimitG = await contract.MINTLIMIT_G()
      let mintLimitP = await contract.MINTLIMIT_P()

      this.setState({
        mintLimitH: mintLimitH.toNumber(),
        mintLimitG: mintLimitG.toNumber(),
        mintLimitP: mintLimitP.toNumber(),
      })
    } catch (error) {
      console.error(error)
    }
  }

  async minted() {
    if (!this.state.account || !contract) return

    try {
      let mintedH = await contract.mintedH(this.state.account)
      let mintedG = await contract.mintedG(this.state.account)

      this.setState({
        mintedH: mintedH,
        mintedG: mintedG
      })
    } catch (error) {
      console.error(error)
    }
  }

  async saleStart() {
    if (!this.state.account || !contract) return

    try {
      const saleHStart = await contract.saleHStart()
      const saleGStart = await contract.saleGStart()
      const publicStart = await contract.publicStart()

      this.setState({
        saleHStart: saleHStart,
        saleGStart: saleGStart,
        publicStart: publicStart
      })
    } catch (error) {
      console.error(error)
    }
  }

  // WriteContract
  async HpassMint(e) {
    if (this.state.isTransaction || !contract || !this.state.account || !this.state.saleHStart || !this.state.hasHpass || !this.state.HpassMerkleProof) return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    const canMintLimitH = this.canMintLimitH()
    if (quantity > canMintLimitH) {
      alert('The number of possible MINTs has been exceeded')
      return
    }

    const amount = this.props.saleHCost
    let payableAmount = String(((amount * 1000) * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)) {
      return alert('Funds are in short supply.')
    }

    // let setGasLimit = this.state.walletBalance
    // let gasLimit = ''

    // gasLimit = await contract.estimateGas.HPassMint(quantity, this.state.HpassMerkleProof, {
    //   value: ethers.utils.parseEther(payableAmount),
    //   gasLimit: setGasLimit,
    // }).catch(error => {
    //   console.error(error)
    //   alert('Failed to get a gas estimate.')
    // })

    // if (!gasLimit) return

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.HPassMint(quantity, this.state.HpassMerkleProof, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(`https://${ this.state.network.name !== 'homestead' ? `${this.state.network.name}.` : '' }etherscan.io/tx/${transaction.hash}`)
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false
      })
      this.viewUpdate()
    } catch (error) {
      console.error(error)
      this.setState({
        quantity: 0,
        isTransaction: false
      })
      // alert('MINT failed.')
      this.viewUpdate()
    }
  }

  async GpassMint(e) {
    if (this.state.isTransaction || !contract || !this.state.account || !this.state.saleGStart || !this.state.hasGpass || !this.state.GpassMerkleProof) return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    const canMintLimitG = this.canMintLimitG()
    if (quantity > canMintLimitG) {
      alert('The number of possible MINTs has been exceeded')
      return
    }

    const amount = this.props.saleGCost
    let payableAmount = String(((amount * 1000) * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)) {
      return alert('Funds are in short supply.')
    }

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.GPassMint(quantity, this.state.GpassMerkleProof, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(`https://${ this.state.network.name !== 'homestead' ? `${this.state.network.name}.` : '' }etherscan.io/tx/${transaction.hash}`)
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false
      })
      this.viewUpdate()
    } catch (error) {
      console.error(error)
      this.setState({
        quantity: 0,
        isTransaction: false
      })
      this.viewUpdate()
    }
  }

  async pubMint(e) {
    if (this.state.isTransaction || !contract || !this.state.account || !this.state.publicStart) return
    e.preventDefault()

    const quantity = this.state.quantity
    if (quantity <= 0) {
      alert('Please specify the number of MINT')
      return
    }

    if (quantity > this.state.mintLimitP) {
      alert('The number of possible MINTs has been exceeded')
      return
    }

    const amount = this.props.publicCost
    let payableAmount = String(((amount * 1000) * (quantity * 1000)) / 1000000) // 浮動小数対策

    if (ethers.utils.formatEther(this.state.walletBalance) < Number(payableAmount)) {
      return alert('Funds are in short supply.')
    }

    this.setState({ isTransaction: true })
    try {
      const transaction = await contract.publicMint(quantity, {
        value: ethers.utils.parseEther(payableAmount),
      })

      console.log(`https://${ this.state.network.name !== 'homestead' ? `${this.state.network.name}.` : '' }etherscan.io/tx/${transaction.hash}`)
      await transaction.wait() // トランザクション完了まで待つ
      this.setState({
        quantity: 0,
        isTransaction: false
      })
      this.viewUpdate()
    } catch (error) {
      console.error(error)
      this.setState({
        quantity: 0,
        isTransaction: false
      })
      // alert('MINT failed.')
      this.viewUpdate()
    }
  }



  // quantity Controll
  canMintLimitH() {
    return this.state.hasHpass && this.state.saleHStart ? this.state.mintLimitH - this.state.mintedH : 0
  }

  canMintLimitG() {
    return this.state.hasGpass && this.state.saleGStart ? this.state.mintLimitG - this.state.mintedG : 0
  }

  canMintLimit() {
    const canMintLimitP = this.state.publicStart ? this.state.mintLimitP : 0
    return Math.max(this.canMintLimitH(), this.canMintLimitG(), canMintLimitP)
  }

  async increaseQuantity() {
    if (this.state.quantity >= this.state.mintLimit) return
    if (this.state.quantity >= (this.state.maxSupply - this.state.totalSupply)) return
    if (this.state.mintLimitH <= 3 && this.state.quantity >= 3) await this.mintLimit()

    const canMintLimit = this.canMintLimit()
    this.setState({ quantity: this.state.quantity < canMintLimit ? this.state.quantity + 1 : canMintLimit })
  }

  decreaseQuantity() {
    const quantity = this.state.quantity > 0 ? this.state.quantity - 1 : 0
    this.setState({ quantity: quantity })
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setState({isLoading: true})
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
    await this.checkWhitelist()
    await this.maxSupply()
  }

  async viewUpdate() {
    this.setState({isLoading: true})
    await this.walletBalance()
    await this.totalSupply()
    await this.saleStart()
    await this.mintLimit()
    await this.minted()

    this.setState({isLoading: false})
  }

  async componentDidMount() {
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(({
        account: accounts[0].toLowerCase(),
      }), async () => {
        await this.initialLoading()
        await this.viewUpdate()
      })
    })
  }

  render() {
    const mintArea = <React.Fragment>
      <div className="pass-area">
        <div className={`pass ${this.state.hasHpass ? '' : 'disabled'}`}>
          <picture>
            <source type="image/webp" srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/hpass.webp" />
            <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/hpass.png" alt="H-PASS" />
          </picture>
          {this.state.saleHStart && this.state.quantity <= this.canMintLimitH() && this.canMintLimitH() > 0 ? <button className="mint-button" onClick={this.HpassMint.bind(this)}>MINT</button> : ''}
        </div>

        <div className={`pass ${this.state.hasGpass ? '' : 'disabled'}`}>
          <picture>
            <source type="image/webp" srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/gpass.webp" />
            <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/gpass.png" alt="G-PASS" />
          </picture>
          {this.state.saleGStart && this.state.quantity <= this.canMintLimitG() && this.canMintLimitG() > 0 ? <button className="mint-button" onClick={this.GpassMint.bind(this)}>MINT</button> : ''}
        </div>
      </div>
      {this.state.publicStart && this.state.quantity <= this.state.mintLimitP ? <div className="public-area"><button className="mint-button" onClick={this.pubMint.bind(this)}>PUBLIC SALE MINT</button></div> : ''}

      {this.state.quantity === 0 ? <p className="supply" style={{ fontSize: '3rem' }}>Price: {this.props.saleHCost} ETH / 1NFT</p> : <p className="supply" style={{ fontSize: '3rem' }}>Price: {this.props.saleHCost * this.state.quantity} ETH / { this.state.quantity}NFT</p>}

      {this.canMintLimit() > 0 ?
        <div className="quantity-ctl">
          <button className="btn decrease" onClick={this.decreaseQuantity.bind(this)}>
            <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/icon/decrease.svg" alt="Decrease BUTTON" />
          </button>
          <p className="quantity">{this.state.quantity}</p>
          <button className="btn increase" onClick={this.increaseQuantity.bind(this)}>
            <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/icon/increase.svg" alt="Increase BUTTON" />
          </button>
        </div> : <p className="supply" style={{ fontSize: '2rem' }}>Cannot be MINT yet.</p>
      }
    </React.Fragment>

    const additionalArea = <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
      <a className="mint-button" href="/docs/hag_terms" target="_blank">Terms</a>
      <a className="mint-button" href="https://docs.google.com/document/d/1G00mfl0U2cQL02XU-OVOJZZOr1vspeNJMQQlMnGulfI/edit?usp=sharing" target="_blank"  rel="nofollow noreferrer noopener">How to Mint?</a>
    </div>

    return <div className="mint-contents">
        <div className="left">
          <picture>
            <source type="image/webp" srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/main_image.webp" />
            <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/main_image.png" alt="HAG MAIN IMAGE" />
          </picture>
        </div>
      <div className="right">
        <div className="hag-icon">
          <picture>
            <source type="image/webp" srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/hag_icon.webp" />
            <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/hag_icon.png" alt="HAG ICON" />
          </picture>
        </div>
        {
          !this.state.isMetaMask ?
            <React.Fragment><p className="supply" style={{ fontSize: '3rem' }}>MetaMask Undefined</p><p className="supply" style={{ fontSize: '3rem' }}>Price: {this.props.saleHCost} ETH / 1NFT</p></React.Fragment> :
          this.state.isLoading ? <p className="supply" style={{ fontSize: '2rem' }}>LOADING NOW...</p> :
          this.state.maxSupply === this.state.totalSupply ?
            <p className="supply" style={{ fontSize: '3.5rem', textAlign: 'center' }}>SOLD OUT<br /> Thank you!</p> :
            mintArea
        }
        {this.state.isMetaMask ? <p className="supply">{this.state.totalSupply}/{this.state.maxSupply}</p> : <p className="supply">XXXX/XXXX</p>}
        {this.state.isMetaMask ? <button className="mint-button" onClick={this.viewUpdate.bind(this)}>DATA LOADING</button> : ''}
        {additionalArea}
      </div>
      <div className={`transaction-box ${this.state.isTransaction ? 'show': ''}`}>
        Transaction Now...
        <div className="image">
          <img src="https://d1tiw0ajeentab.cloudfront.net/mints/hag/img/hag_icon.png" alt="Transaction now" loading="lazy" />
        </div>
      </div>
    </div>
  }
}

hagMint.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  saleHCost: PropTypes.number,
  saleGCost: PropTypes.number,
  publicCost: PropTypes.number,
  wlSuffix: PropTypes.string,
}
export default hagMint
