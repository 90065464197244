import React from "react"
import axios from 'axios'

class MintsWatcherWL extends React.Component {
  state = {
    whitelistUsers: [],
  };

  // Axios
  fetchWhitelistUsers() {
    axios({
      method: 'GET',
      url: 'https://api.sssapi.app/SQvnLnaXrZAkHluhQlRSm',
    }).then((response) => {
      const whitelistUsers = response.data.map(data => data['Name'])
      this.setState({
        whitelistUsers: whitelistUsers,
      });
    }).catch((error) => {
      console.error('error', error);
    });
  }

  componentDidMount() {
    this.fetchWhitelistUsers();
  }

  render() {
    const whitelistUsers = this.state.whitelistUsers.map((user, key) => <p className="address" key={key}>{user}</p>)

    return whitelistUsers;
  }
}
export default MintsWatcherWL
