import React from 'react'
import PropTypes from 'prop-types'

import ChimeraCard from './ChimeraCard'

let provider, signer, contract
const jsonBaseUri =
  'https://generative-outputs.s3.ap-northeast-1.amazonaws.com/chimerative_mononoke'
class chimerativeAuction extends React.Component {
  state = {
    accordionOpen: false,
    abi: require('./auctionAbi.json'),
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: true,
    auctionChimeras: [],
    haveArcana: 0,
    page: this.props.page,

    auctionIds: this.props.auctionIds,
    pageSize:
      this.props.auctionIds.length % 12 === 0
        ? Math.floor(this.props.auctionIds.length / 12)
        : Math.floor(this.props.auctionIds.length / 12) + 1,
  }

  setPage(page) {
    this.setState({ page: page }, () => {
      this.auctionChimerasFetch()
    })
  }

  pageNext() {
    if (this.state.page + 1 >= this.state.pageSize) return
    this.setState({ page: this.state.page + 1 }, () => {
      this.auctionChimerasFetch()
    })
  }

  pagePrev() {
    if (this.state.page === 0) return
    this.setState({ page: this.state.page - 1 }, () => {
      this.auctionChimerasFetch()
    })
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  currentUnixTime() {
    return Math.floor(Date.now() / 1000)
  }

  // auction
  async currentArcana(id) {
    if (!this.state.isMetaMask) return

    const currentArcana = await contract.currentArcana(id).catch((error) => {
      const message = error.message
      if (
        message.indexOf('Cannot be set because it has already been bid on.') !=
        -1
      ) {
        return 0
      }
    })

    return Number(currentArcana)
  }

  async fetchAuctionDetail(id) {
    if (!this.state.isMetaMask) return

    const auctionDetail = await contract.auctionDetail(id)

    const currentArcana =
      this.currentUnixTime() >= Number(auctionDetail.startTime)
        ? await this.currentArcana(id)
        : 0

    return {
      auctionId: id,
      contractAddress: auctionDetail.contractAddress,
      tokenId: Number(auctionDetail.tokenId),
      isFinish:
        auctionDetail.owner !== '0x0000000000000000000000000000000000000000',
      isStart: this.currentUnixTime() >= Number(auctionDetail.startTime),
      startArcana: Number(auctionDetail.startArcana),
      endArcana: Number(auctionDetail.endArcana),
      startTime: Number(auctionDetail.startTime),
      endTime: Number(auctionDetail.endTime),
      currentArcana: currentArcana,
    }
  }

  async auctionChimerasFetch() {
    const pageSize = 12
    const startIndex = this.state.page * pageSize
    const endIndex = startIndex + pageSize
    const currentPageIds = this.state.auctionIds.slice(startIndex, endIndex)

    const auctionChimeras = await Promise.all(
      currentPageIds.map(async (id) => await this.fetchAuctionDetail(id))
    )

    this.setState({ auctionChimeras: auctionChimeras })
  }

  // arcana
  async getArcana() {
    if (!this.state.isMetaMask || !this.state.account) return

    const haveArcana = await contract.getArcana()
    console.log('haveArcana', Number(haveArcana))
    this.setState({ haveArcana: haveArcana.toString() })
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setState(
      {
        isLoading: true,
        auctionChimeras: [],
      },
      async () => {
        this.setNetwork()
        await this.setProvider()
        await this.checkMetaMask()
        await this.connectWallet()
        await this.getArcana()
        this.auctionChimerasFetch()
        this.setState({ isLoading: false })
      }
    )
  }

  async componentDidMount() {
    await this.initialLoading()

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async () => {
      await this.initialLoading()
    })
  }

  render() {
    return (
      <main className="main auction">
        <div className="container">
          <div className="section">
            <div className="section-inner">
              <h1 className="title">
                <picture>
                  <source
                    type="image/webp"
                    srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/title.svg"
                  />
                  <img
                    src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/title.svg"
                    alt="A.R.C.A's AUCTION "
                  />
                </picture>
              </h1>
              <p className="sub-ttl">
                We have ingested the genes of valuable organisms from the island
                of Horai. These chimaeras were created based on those genes.
              </p>

              <div className="contents">
                <div
                  className={`flow ${this.state.accordionOpen ? 'open' : ''}`}
                >
                  <h2
                    className="ttl"
                    onClick={() =>
                      this.setState({
                        accordionOpen: !this.state.accordionOpen,
                      })
                    }
                  >
                    A.R.C.A's AUCTION FLOW
                  </h2>
                  <p className="ttl-ja">A.R.C.Aオークションの流れ</p>
                  <div className="step">
                    <p className="txt-en">
                      At the A.R.C.A's auction, purchase transactions are made
                      using Arcana extracted from owned chimeras. For more
                      information about Arcana, please click
                      <a href="https://play-nft.art/staking/arcana">here</a>.
                    </p>
                    <p className="txt-ja">
                      A.R.C.Aオークションでは、ご自身の所有するキメラから抽出したアルカナを使用して購入取引します。
                      アルカナに関しては
                      <a href="https://play-nft.art/staking/arcana">
                        こちらのページ
                      </a>
                      をご参照ください。
                    </p>
                  </div>

                  <div className="step">
                    <p className="txt-en">
                      The amount of arcana required to purchase gradually
                      decreases from the initial price in a Dutch auction
                      format. Starting and ending prices vary by chimera.
                    </p>
                    <p className="txt-ja">
                      ダッチオークション形式で最初の数値から徐々に購入に必要なアルカナ量が減っていきます。
                      キメラによって開始数値、終了数値が異なります。
                    </p>
                  </div>

                  <div className="step">
                    <p className="txt-en">
                      Press BUY NOW on the product page at the time of your
                      desired price, and if the transaction is successfully
                      completed, you will get the chimera.
                    </p>
                    <p className="txt-ja">
                      ご希望の価格のタイミングで商品ページのBUY NOWを押して、
                      無事取引完了すればキメラを取得できます。
                    </p>
                  </div>

                  <div className="step">
                    <p className="txt-en">
                      Chimera to be auctioned will change every week. Please
                      watch for the timing of the desired chimera to appear and
                      purchase it.
                    </p>
                    <p className="txt-ja">
                      オークションに出品されるキメラは毎週変わります。
                      希望のキメラが登場するタイミングを見計らって購入してください。
                    </p>
                  </div>
                </div>
                <div className="bar">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-top-sp.webp"
                    />
                    <img src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-top.webp" />
                  </picture>
                </div>
                <section className="chimera-cards">
                  {this.state.isLoading ||
                  !this.state.isMetaMask ||
                  !this.state.isConnected ? (
                    <React.Fragment>
                      <p
                        style={{
                          width: '100%',
                          fontSize: '2rem',
                          textAlign: 'center',
                          fontWeight: 'bold',
                          padding: '3rem 2rem',
                        }}
                      >
                        AUCTION DATA LOADING...
                      </p>
                      {!this.state.isMetaMask ? (
                        <a
                          style={{
                            width: '100%',
                            fontSize: '1rem',
                            textAlign: 'center',
                            fontWeight: 'bold',
                            textDecoration: 'underline',
                            marginTop: '1rem',
                          }}
                          href="https://metamask.app.link/dapp/play-nft.art/chimerative/auction"
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                        >
                          PLEASE CONNECT METAMASK
                        </a>
                      ) : (
                        <React.Fragment />
                      )}
                    </React.Fragment>
                  ) : (
                    this.state.auctionChimeras.map((chimera, key) => (
                      <ChimeraCard key={key} chimera={chimera} />
                    ))
                  )}
                </section>
                <div className="bar">
                  <picture>
                    <source
                      media="(max-width: 767px)"
                      srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-bottom-sp.webp"
                    />
                    <img src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/auction/bar-bottom.webp" />
                  </picture>
                </div>

                {this.state.pageSize > 0 ? (
                  <div className="pagination">
                    <button
                      className="btn left"
                      onClick={this.pagePrev.bind(this)}
                    ></button>
                    <div className="circles">
                      {Array.from(
                        { length: this.state.pageSize },
                        (_, index) => index
                      ).map((page) => (
                        <button
                          className={`circle ${
                            page === this.state.page ? 'current' : ''
                          }`}
                          key={page}
                          onClick={this.setPage.bind(this, page)}
                        ></button>
                      ))}
                    </div>
                    <button
                      className="btn right"
                      onClick={this.pageNext.bind(this)}
                    ></button>
                  </div>
                ) : (
                  <React.Fragment></React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

chimerativeAuction.propTypes = {
  page: PropTypes.number,
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  auctionIds: PropTypes.array,
}
export default chimerativeAuction
