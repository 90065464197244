import React from 'react'
import PropTypes from 'prop-types'

class bdpMintArea extends React.Component {
  state = {
    isTransaction: false,
    isLoading: false,
    tokens: [],
    unrevealUri:
      'https://4f5zzmp6qaytlg3nxueogsradxu2ojomwv4e2nerg34sofjek3ka.arweave.net/4Xucsf6AMTWbbb0I40ogHemnJcy1eE00kTb5JxUkVtQ/',
    revealedUri:
      'https://y6rdmcesws32sg2woya6niynbhwsu7tkhw7pccr5ygwnvaetlmja.arweave.net/x6I2CJK0t6kbVnYB5qMNCe0qfmo9vvEKPcGs2oCTWxI/',
  }

  async checkRevealed(tokenId) {
    if (!this.props.account || !this.props.contract) return
    try {
      const revealed = await this.props.contract.revealed(tokenId)
      return revealed
    } catch (error) {
      console.error(error)
    }
  }

  async balanceOf() {
    if (!this.props.account || !this.props.contract) return
    const balanceOf = await this.props.contract.balanceOf(this.props.account)

    return balanceOf.toNumber()
  }

  // 所有しているBDPのtoken IDを確認し、各種データをtokensに格納する
  async setTokens() {
    if (!this.props.account || !this.props.contract) return
    try {
      const balanceOf = await this.balanceOf()
      if (balanceOf <= 0) return this.setState({ tokens: [] })

      const tokenIds = []
      for (let i = 0; i < balanceOf; i++) {
        const tokenId = await this.props.contract.tokenOfOwnerByIndex(
          this.props.account,
          i
        )
        const currentLoading = document.querySelector('#currentLoading')
        if (currentLoading)
          currentLoading.innerText = `Loading size ${tokenIds.length} / ${balanceOf}`

        tokenIds.push(tokenId.toNumber())
      }

      let tokens = []
      tokenIds.forEach(async (id) => {
        const revealed = await this.checkRevealed(id)
        const images = await this.fetchImageUri(id)

        tokens.push({
          tokenId: id,
          revealed: revealed.toNumber() === 1,
          unrevealImage: images.unrevealImage,
          revealedImage: images.revealedImage,
        })

        this.setState({ tokens: tokens })
      })
    } catch (error) {
      console.error(error)
    }
  }

  async fetchImageUri(tokenId) {
    let unrevealImage = ''
    let revealedImage = ''

    await fetch(`${this.state.unrevealUri}${tokenId}.json`)
      .then((response) => response.json())
      .then((data) => {
        unrevealImage = data.image
      })
      .catch((err) => {
        console.error(err)
      })

    await fetch(`${this.state.revealedUri}${tokenId}.json`)
      .then((response) => response.json())
      .then((data) => {
        revealedImage = data.image
      })
      .catch((err) => {
        console.error(err)
      })

    return {
      unrevealImage: unrevealImage,
      revealedImage: revealedImage,
    }
  }

  checkRevealedImage(e) {
    const target = e.currentTarget
    const imageDom = target.parentNode.parentNode.parentNode.querySelector(
      '.image'
    )
    if (imageDom.classList.contains('reveal')) {
      imageDom.classList.remove('reveal')
    } else {
      imageDom.classList.add('reveal')
    }
  }

  async reveal(e) {
    const target = e.currentTarget
    if (!target || !target.dataset) return
    const tokenId = target.dataset.id

    this.setState({ isTransaction: true })
    try {
      const transaction = await this.props.contract.reveal(tokenId)

      console.log(
        `https://${
          this.props.network.name !== 'homestead'
            ? `${this.props.network.name}.`
            : ''
        }etherscan.io/tx/${transaction.hash}`
      )
      await transaction.wait()
      this.setState({ isTransaction: false })
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({ isTransaction: false })
      this.viewUpdate()
    }
  }

  async allReveal() {
    const revealTargetIds = this.state.tokens
      .filter((token) => !token.revealed)
      .map((token) => token.tokenId)
    if (revealTargetIds.length <= 0)
      return alert('Reveal可能な対象が見つかりませんでした')

    if (
      !confirm(
        `tokenId ${revealTargetIds} のBDPをRevealします。よろしいですか？`
      )
    )
      return

    this.setState({ isTransaction: true })
    try {
      const transaction = await this.props.contract.batchReveal(revealTargetIds)

      const scanUri = `https://${
        this.props.network.name !== 'homestead'
          ? `${this.props.network.name}.`
          : ''
      }etherscan.io/tx/${transaction.hash}`
      console.log(scanUri)
      await transaction.wait()
      this.setState({ isTransaction: false })
      this.viewUpdate()
    } catch (error) {
      this.errorHandling(error)
      this.setState({ isTransaction: false })
      this.viewUpdate()
    }
  }

  errorHandling(error) {
    if (
      error.message ===
      'MetaMask Tx Signature: User denied transaction signature.'
    ) {
      alert('Revealをキャンセルしました')
    } else if (
      error.message.indexOf('insufficient funds for gas * price + value') != -1
    ) {
      alert(
        'Revealに必要な資金が不足しています。\n十分に資金があるにも関わらずこのメッセージが表示される場合は、この画面のスクリーンショットを運営までお知らせください。\n' +
          `wallet: ${this.props.account}\n============\n` +
          error
      )
    } else if (error.message.indexOf('transaction failed') != -1) {
      const scanUri = `https://${
        this.props.network.name !== 'homestead'
          ? `${this.props.network.name}.`
          : ''
      }etherscan.io/tx/${error.transactionHash}`
      console.error(error)

      if (
        confirm(
          'Transactionに失敗しました。\n詳細をetherscanで確認しますか？\n(OKを押すとetherscanへ遷移します)\n' +
            scanUri
        )
      ) {
        window.location.href = scanUri
      }
    } else {
      alert(
        '失敗しました。この画面をスクリーンショットで撮影し、運営までお知らせください。\n' +
          `wallet: ${this.props.account}\n` +
          error
      )
    }
  }

  async viewUpdate() {
    this.setState({ isLoading: true })
    await this.setTokens()
    this.setState({ isLoading: false })
  }

  async componentDidMount() {
    await this.viewUpdate()
  }

  async componentDidUpdate(props) {
    if (!props.reload && this.props.reload) {
      this.viewUpdate()
    }
  }

  sortTokens() {
    return this.state.tokens.length > 0
      ? this.state.tokens.sort(function (a, b) {
          return a.tokenId < b.tokenId ? -1 : 1
        })
      : []
  }

  render() {
    const loadingDom = (
      <p
        className="load-effect"
        style={{ fontSize: '1rem', display: 'flex', justifyContent: 'center' }}
      >
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>L</span>
        <span>O</span>
        <span>A</span>
        <span>D</span>
        <span>I</span>
        <span>N</span>
        <span>G</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
        <span>■</span>
      </p>
    )

    const transactionDom = (
      <p
        className="load-effect"
        style={{ fontSize: '1rem', display: 'flex', justifyContent: 'center' }}
      >
        <span>■</span>
        <span>■</span>
        <span>T</span>
        <span>R</span>
        <span>A</span>
        <span>N</span>
        <span>S</span>
        <span>A</span>
        <span>C</span>
        <span>T</span>
        <span>I</span>
        <span>O</span>
        <span>N</span>
        <span>　</span>
        <span>N</span>
        <span>O</span>
        <span>W</span>
        <span>■</span>
        <span>■</span>
      </p>
    )

    const tokens = this.sortTokens().map((token, key) => (
      <div className="token" key={key}>
        <div className={`image ${token.revealed ? 'reveal' : ''}`}>
          <img
            src={token.unrevealImage}
            className="unreveal"
            alt="BabyDollPankusuruGenerationX_0"
          />
          <img
            src={token.revealedImage}
            className="revealed"
            alt="BabyDollPankusuruGenerationX_0"
          />
        </div>
        <div className="content">
          <h3 className="name">
            BabyDollPankusuru
            <br />
            GenerationX_{token.tokenId}
          </h3>
          <div className="buttons">
            {!token.revealed ? (
              this.state.isTransaction ? (
                transactionDom
              ) : this.state.isLoading ? (
                loadingDom
              ) : (
                <React.Fragment>
                  <button
                    className="default-btn"
                    onClick={this.checkRevealedImage.bind(this)}
                  >
                    Check BDP
                  </button>
                  <button
                    className="default-btn"
                    onClick={this.reveal.bind(this)}
                    data-id={token.tokenId}
                  >
                    Reveal Start
                  </button>
                </React.Fragment>
              )
            ) : (
              <button className="default-btn" disabled>
                Revealed
              </button>
            )}
          </div>
          <a
            href={`https://opensea.io/ja/assets/ethereum/${this.props.contractAddress}/${token.tokenId}`}
            className="os-link"
            target="_blank"
            rel="nofollow noreferrer noopener"
          >
            Show OpenSea
          </a>
        </div>
      </div>
    ))

    return (
      <div className="reveal-area scroll-target" id="reveal">
        <section className="section">
          <h2 className="section-ttl">
            Reveal <br className="sp-only" />
            BDP GenX
          </h2>
          <p className="txt font-jp">
            BDP GenXはホルダーさま自身のタイミングで
            <br className="sp-only" />
            Revealを行うことができます。
            <br />
            Reveal後の姿もあわせてご確認いただけますので、
            <br className="sp-only" />
            お好きなタイミングでRevealをお楽しみください。
            <br />
            <span className="red small">
              ※一度Revealさせた作品は、PODの状態には戻りません
            </span>
          </p>

          {this.state.tokens.length <= 0 ? (
            <p
              className="txt font-jp"
              style={{ fontSize: '1rem', fontWeight: 'bold' }}
            >
              【所有しているBDPが見つかりませんでした】
            </p>
          ) : (
            ''
          )}

          {this.state.isLoading ? loadingDom : ''}
          {this.state.isLoading ? (
            <p className="txt font-jp" id="currentLoading"></p>
          ) : (
            ''
          )}

          {this.state.tokens.length >= 2 ? (
            <button
              className="default-btn"
              style={{ width: '100%', maxWidth: '500px', margin: '0 auto' }}
              onClick={this.allReveal.bind(this)}
            >
              Reveal All
            </button>
          ) : (
            ''
          )}

          <div className="tokens">{tokens}</div>
        </section>
      </div>
    )
  }
}

bdpMintArea.propTypes = {
  reload: PropTypes.bool,
  contract: PropTypes.object,
  network: PropTypes.object,
  account: PropTypes.string,
  contractAddress: PropTypes.string,
}
export default bdpMintArea
