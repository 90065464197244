import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'
import YakuNfts from './yakuNft'

class YakuChecker extends React.Component {
  state = {
    checkedNfts: [],
    yakus: this.props.yakus,
  };

  checkNft(e) {
    const checked = e.currentTarget.checked;
    const id = Number(e.currentTarget.dataset.id);
    let checkNftIds = this.state.checkedNfts;
    if (checked) {
      checkNftIds.push(id);
    } else {
      const index = checkNftIds.indexOf(id);
      checkNftIds.splice(index, 1)
    }

    this.setState(({
      checkedNfts: checkNftIds,
      yakus: checkNftIds.length > 0 ? this.state.yakus : this.props.yakus
    }), () => {
      if (checkNftIds.length > 0) this.fetchYakus();
    });
  }

  fetchYakus() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/yakus/`,
      params: {
        nfts: this.state.checkedNfts,
      }
    }).then((response) => {
      this.setState({
        yakus: typeof response.data.yakus !== 'undefined' && response.data.yakus.length > 0 ? response.data.yakus : [],
      });
    }).catch((error) => {
      console.error('error', error);
    });
  }

  checkEnName(yaku) {
    return yaku.en_name ? yaku.en_name : yaku.name;
  }

  render() {
    const nfts = this.props.nfts.map((nft, key) => <label className="nft" key={key}>
      <input type="checkbox" className="check" onChange={this.checkNft.bind(this)} data-id={nft.id} />
      <img src={nft.image_uri} alt={nft.name} loading="lazy" />
    </label>)

    const yakus = this.state.yakus.map((yaku, key) => <div className="yaku" key={key}>
      <h2 className="yaku-ttl">{this.props.lang === 'en' ? this.checkEnName(yaku) : yaku.name}&nbsp;({yaku.score}{this.props.lang === 'en' ? 'point ' : '点'})</h2>
      <div className="yaku-nfts">
        <YakuNfts nfts={yaku.nfts}/>
      </div>
    </div>);

    return <div className="yaku-checker">
        <div className="nft-lists">
          {nfts}
      </div>
      <p className="section-desc mt10">{this.props.lang === 'en' ? 'Count: ' : 'ヒット件数：'}{this.state.yakus.length}{this.props.lang === 'en' ? '' : '件'}</p>
        <div className="yaku-lists">
          {yakus}
        </div>
      </div>;
  }
}

YakuChecker.propTypes = {
  baseURI: PropTypes.string,
  nfts: PropTypes.array,
  yakus: PropTypes.array,
  lang: PropTypes.string,
};
export default YakuChecker
