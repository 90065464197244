import React from "react"
import PropTypes from "prop-types"
import axios from 'axios'

class NftsIndex extends React.Component {
  state = {
    creator: this.props.creatorPrams,
    event: this.props.eventPrams,
    nfts: [],
  };

  fetchNfts() {
    axios({
      method: 'GET',
      url: `${this.props.baseURI}/api/v1/nfts/`,
      params: {
        creator: this.state.creator,
        event: this.state.event,
      }
    }).then((response) => {
      this.setState({
        nfts: response.data.nfts,
      });
    }).catch((error) => {
      console.log(error.response.data);
      this.setState({ isLoading: false });
    });
  }

  changeCreator(e) {
    const value = e.currentTarget.value;
    this.setState(({
      creator: value,
    }), () => {
      this.fetchNfts();
    });
  }

  changeEvent(e) {
    const value = e.currentTarget.value;
    this.setState(({
      event: value,
    }), () => {
      this.fetchNfts();
    });
  }

  componentDidMount() {
    this.fetchNfts();
  }


  render() {
    const creators = this.props.creators.map((creator, key) => <option value={creator.public_id} key={key}>{creator.name}</option>);

    const events = this.props.events.map((event, key) => <option value={event.public_id} key={key}>{event.title}</option>);

    const nfts = this.state.nfts.map((nft, key) => <div className="nft-box" key={key}>
      <div className="image"><img src={nft.image_uri} alt="" loading="lazy" /></div>
      <h2 className="name">{nft.name}</h2>
      <p className={`network ${nft.network}`}>{nft.network}</p>
      <p className="owner">owner:<a href={nft.owner_uri} target="_blank" rel="nofollow noreferrer noopener">{nft.owner_name}</a></p>

      <p className="creator">creator:<a href={nft.creator.opensea_uri} target="_blank" rel="nofollow noreferrer noopener">{nft.creator.name}</a></p>

      <p className="price">price:{nft.current_amount ? nft.current_amount : '未出品'}</p>
      <p className="show-os"><a href={`https://opensea.io/assets/${nft.network == 'polygon' ? 'matic/' : ''}${nft.asset_contract_address}/${nft.token_id}`} target="_blank" rel="nofollow noreferrer noopener">Show OpenSea</a></p>
      <div className="flex items-center">
        <p className="btn"><a href={`/nfts/${nft.id}/edit`} className="defaultBtn">edit</a></p>
        {this.props.ENV === 'production' ? '' : <a className="btn ml10" rel="nofollow" data-method="post" href={`/nfts/${nft.id}/loading`}>loading</a>}
      </div>
    </div>);

    return <div className="">
      <div className="mb20">
        <select className="select mr10" onChange={this.changeCreator.bind(this)} defaultValue={this.props.creatorPrams}>
          <option value="">クリエイターで絞り込み</option>
          {creators}
        </select>

        <select className="select"  onChange={this.changeEvent.bind(this)} defaultValue={this.props.eventPrams}>
          <option value="">イベントで絞り込み</option>
          {events}
        </select>
      </div>

      <div className="nfts">{nfts}</div>
    </div>;
  }
}

NftsIndex.propTypes = {
  ENV: PropTypes.string,
  baseURI: PropTypes.string,
  creators: PropTypes.array,
  events: PropTypes.array,
  creatorPrams: PropTypes.string,
  eventPrams: PropTypes.string,
};
export default NftsIndex
