import React from 'react'
import PropTypes from 'prop-types'

class chimerativeMintNotStart extends React.Component {
  state = {}

  render() {
    return (
      <div className="inner" style={{ height: 'auto', maxHeight: '80dvh' }}>
        <h2 className="logo">
          <img
            src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/logo_full_color.svg"
            alt="Chimerative Monsters"
          />
        </h2>
        <div className="mint_price">
          {/*<picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/mint_price.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/mint_modal/mint_price.png"
              alt="Price"
            />
          </picture>*/}
          <p
            style={{
              fontSize: '2rem',
              fontWeight: 'bold',
              textAlign: 'center',
              color: 'var(--main-color)',
            }}
          >
            PRICE: 0.02 ETH
          </p>
          <p
            style={{
              marginTop: '.5rem',
              fontSize: '1rem',
              fontWeight: 'bold',
              textAlign: 'center',
              color: 'var(--main-color)',
            }}
          >
            PreSale 1MINT / PubSale 5MINT
          </p>
        </div>
        <div className="txt-box">
          <p className="message">
            Please wait a little longer for the sale to start...
          </p>
          <p className="address">
            Your wallet address:{' '}
            <span className="num">{this.props.wallet.address}</span>
          </p>
          {this.props.whitelist.isWhitelist ? (
            <p className="message">You are Allow List User</p>
          ) : (
            ''
          )}

          {this.props.isLoading ? (
            <p className="message">Data Loading...</p>
          ) : (
            ''
          )}
        </div>
        <button className="load-btn" onClick={() => this.props.viewUpdate()}>
          <picture>
            <source
              type="image/webp"
              srcSet="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/reload.webp"
            />
            <img
              src="https://d1tiw0ajeentab.cloudfront.net/mints/chimerative/img/btn/reload.png"
              alt="DATA LOAD"
            />
          </picture>
        </button>
        <p className="count">
          {this.props.totalSupply.toLocaleString()}/
          {this.props.maxSupply.toLocaleString()}
        </p>
      </div>
    )
  }
}

chimerativeMintNotStart.propTypes = {
  viewUpdate: PropTypes.func,
  isLoading: PropTypes.bool,
  isTransaction: PropTypes.bool,
  maxSupply: PropTypes.number,
  totalSupply: PropTypes.number,
  wallet: PropTypes.object,
  whitelist: PropTypes.object,
}
export default chimerativeMintNotStart
