import React from 'react'
import PropTypes from 'prop-types'

import OpenMetaMaskModal from '../../contract/openMetaMaskModal'

let provider, signer, contract
class RvltdTypeBeat extends React.Component {
  state = {
    abi: require('./rvltdAbi.json'),
    account: '',
    network: '',
    isMetaMask: false,
    isConnected: false,
    isLoading: false,

    nfts: [],

    totalSupply: 0,
    playIndex: 0,
    music: new Audio(),
    pageSize: 12,
    page: 0,
    playLoadingTag: 'loading',
    downloading: {},
  }

  // Initialize
  setNetwork() {
    let network = {}

    switch (this.props.network) {
      case 'goerli': // Goerli
        network = {
          id: '0x5',
          name: 'goerli',
        }
        break
      case 'ethereum': // Ethereum
        network = {
          id: '0x1',
          name: 'homestead',
        }
        break
      default:
        network = {}
    }

    this.setState({ network: network })
  }

  async setProvider() {
    if (window.ethereum?.isMetaMask) {
      provider = await new ethers.providers.Web3Provider(window.ethereum, 'any')

      provider.on('network', (_, oldNetwork) => {
        if (oldNetwork) window.location.reload()
      })
      signer = await provider.getSigner()
    }
  }

  // ウォレット接続関連
  async checkMetaMask() {
    if (window.ethereum?.isMetaMask) {
      this.setState({ isMetaMask: true })
    }
  }

  async connectWallet() {
    if (!this.state.isMetaMask) return

    const connectedNetwork = await provider.getNetwork()
    if (connectedNetwork.name !== this.state.network.name) {
      return ethereum
        .request({
          method: 'wallet_switchEthereumChain',
          params: [
            {
              chainId: this.state.network.id,
            },
          ],
        })
        .then(() => {
          alert('Network has been changed. Please reconnect your wallet.')
        })
    }

    try {
      //アカウントへの接続を要求
      const newAccounts = await ethereum.request({
        method: 'eth_requestAccounts',
      })
      const accounts = newAccounts

      this.setState(
        {
          isConnected: true,
          account: accounts[0].toLowerCase(),
        },
        () => {
          console.log(`Wallet connected: ${this.state.account}`)
          if (!this.props.contractAddress) return

          contract = new ethers.Contract(
            this.props.contractAddress,
            this.state.abi,
            signer
          )
        }
      )
    } catch (error) {
      console.error(error)
    }
  }

  async reload() {
    this.setState({ isLoading: true }, async () => {
      await this.viewUpdate()
      this.setState({ isLoading: false })
    })
  }

  // 読み込み時に1度だけ呼ぶ（ウォレット繋ぎ変え時にも実行）
  async initialLoading() {
    this.setNetwork()
    await this.setProvider()
    await this.checkMetaMask()
    await this.connectWallet()
  }

  async viewUpdate() {
    await this.totalSupply()
  }

  async componentDidMount() {
    this.setState({ isLoading: true }, async () => {
      await this.initialLoading()
      await this.viewUpdate()
      this.setState({ isLoading: false })
    })

    // ウォレットの繋ぎ変え
    ethereum.on('accountsChanged', async (accounts) => {
      console.log(`Change Wallet ${accounts[0]}`)
      this.setState(
        {
          account: accounts[0].toLowerCase(),
        },
        async () => {
          this.setState({ isLoading: true }, async () => {
            await this.initialLoading()
            await this.viewUpdate()
            this.setState({ isLoading: false })
          })
        }
      )
    })
  }

  // Contract Call
  async totalSupply() {
    if (!contract) return

    try {
      const totalSupply = await contract.totalSupply()
      this.setState({ totalSupply: totalSupply.toNumber() })

      await this.allNftsCheckLicenseType()
    } catch (error) {
      console.error(error)
    }
  }

  async checkLicenseType(tokenId) {
    if (!contract || tokenId > this.state.totalSupply) return

    try {
      return await contract.checkLicenseType(tokenId)
    } catch (error) {
      // alert(
      //   'コントラクトの呼び出しに失敗しました。\nページをリロードして再度お試しください'
      // )
    }
  }

  async allNftsCheckLicenseType() {
    if (!contract) return

    const nfts = await Promise.all(
      [...Array(this.state.pageSize)].map(async (_, i) => {
        const tokenId = i + this.state.page * this.state.pageSize + 1
        const response = await this.checkLicenseType(tokenId)
        return {
          licenseType: response,
          id: tokenId,
        }
      })
    )

    const cleanBlankNfts = nfts.filter(Boolean)
    this.setState({ nfts: cleanBlankNfts })
  }

  // 音楽周り
  audioPath(tokenId) {
    return `${this.props.musicBaseURI}/rec_${tokenId - 1}.wav`
  }

  async playButton(e) {
    const target = e.currentTarget
    const index = Number(target.dataset.index)
    if (this.state.playIndex === index) {
      this.pause(index)
    } else {
      this.play(index)
    }
  }

  async play(index) {
    this.setState({ playIndex: index })
    const audioPath = this.audioPath(index)

    const music = new Audio(audioPath)
    this.state.music.pause()
    this.setState({ music: music, playLoadingTag: 'loading' }, () => {
      this.state.music.play()
      this.state.music.addEventListener('canplaythrough', () => {
        this.loadTag()
      })
    })
  }

  loadTag() {
    this.setState({ playLoadingTag: 'pause' })
  }

  async pause(index) {
    this.state.playIndex === index + 1
    this.setState({ playIndex: 0 })

    this.state.music.pause()
  }

  async download(e) {
    const target = e.currentTarget
    const index = Number(target.dataset.index)
    const audioPath = this.audioPath(index)

    if (window.innerWidth < 767) {
      const link = document.createElement('a')
      link.href = audioPath
      link.target = '_blank'
      link.click()
      return
    }

    const xhr = new XMLHttpRequest()
    xhr.open('GET', audioPath)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        const blob = xhr.response
        const url = URL.createObjectURL(blob)

        const link = document.createElement('a')
        link.download = `rvltd_typebeat_${index}.wav`
        link.href = url
        link.click()

        URL.revokeObjectURL(url)
      }
    }

    xhr.onprogress = (event) => {
      if (event.lengthComputable) {
        const percent = (event.loaded / event.total) * 100

        const downloading = this.state.downloading
        downloading[index] = percent

        if (percent >= 100) {
          delete downloading[index]
          this.setState({ downloading: downloading })
        } else {
          this.setState({ downloading: downloading })
        }
      }
    }
    xhr.send()
  }

  // ページ周り
  pageChange(e) {
    const target = e.currentTarget
    const page = Number(target.dataset.page)
    this.setState({ page: page }, async () => {
      await this.allNftsCheckLicenseType().then(() => {
        window.scrollTo(0, 0)
      })
    })
  }

  render() {
    const nfts =
      this.state.nfts.length > 0 ? (
        this.state.nfts.map((nft, i) =>
          this.state.totalSupply >= nft.id ? (
            <div className="nft" key={i}>
              <div
                className="image"
                style={{
                  backgroundImage: `url(${this.props.imageBaseURI}/${
                    nft.id - 1
                  }.png)`,
                }}
              ></div>
              <div className="meta">
                <div className="play-area">
                  <h2 className="name">RVLTD #{('0000' + nft.id).slice(-4)}</h2>
                  <button
                    className={`play-button ${
                      this.state.playIndex === nft.id
                        ? this.state.playLoadingTag
                        : 'play'
                    }`}
                    onClick={this.playButton.bind(this)}
                    data-index={nft.id}
                  ></button>
                </div>
                <div className="buttons">
                  {nft.licenseType === 'Non-Exclusive' ? (
                    this.state.downloading[nft.id] ? (
                      <button
                        className="btn"
                        data-index={nft.id}
                        style={{ background: '#aaa', border: '#aaa' }}
                        disabled
                      >
                        Loading
                      </button>
                    ) : (
                      <button
                        onClick={this.download.bind(this)}
                        className="btn"
                        data-index={nft.id}
                      >
                        Download
                      </button>
                    )
                  ) : (
                    <button
                      className="btn"
                      disabled
                      style={{ background: '#aaa', border: '#aaa' }}
                    >
                      Exclusive
                    </button>
                  )}
                  <a
                    href={`https://opensea.io/assets/ethereum/${this.props.contractAddress}/${nft.id}`}
                    target="_blank"
                    rel="nofollow noreferrer noopener"
                    className="btn os"
                  >
                    OpenSea
                  </a>
                </div>
                {this.state.downloading[nft.id] ? (
                  <React.Fragment>
                    <div className="downloading">
                      <div
                        className="progress-bar"
                        style={{
                          width: `${
                            this.state.downloading[nft.id]
                              ? this.state.downloading[nft.id]
                              : 0
                          }%`,
                        }}
                      ></div>
                    </div>
                    <p className="progress">
                      Downloading: {Math.floor(this.state.downloading[nft.id])}%
                    </p>
                  </React.Fragment>
                ) : (
                  ''
                )}
              </div>
            </div>
          ) : (
            ''
          )
        )
      ) : this.state.isLoading ? (
        <p className="font-jp">利用可能なTYPE BEATを読み込んでいます</p>
      ) : (
        <p className="font-jp">利用可能なTYPE BEATが見つかりませんでした</p>
      )

    const pages =
      this.state.totalSupply >= this.state.pageSize
        ? [
            ...Array(
              Number(Math.floor(this.state.totalSupply / this.state.pageSize)) +
                1
            ),
          ].map((_, i) => (
            <button
              className={`page ${i === this.state.page ? 'current' : ''}`}
              key={i}
              data-page={i}
              onClick={this.pageChange.bind(this)}
            >
              {i + 1}
            </button>
          ))
        : ''

    return (
      <React.Fragment>
        <OpenMetaMaskModal deepLink="https://metamask.app.link/dapp/play-nft.art/musics/rvltd" />

        {this.state.nfts.length > 0 ? (
          <p className="font-jp">ページ: {this.state.page + 1}</p>
        ) : (
          ''
        )}

        <div className="nfts">{nfts}</div>
        <div className="pages">{pages}</div>
      </React.Fragment>
    )
  }
}

RvltdTypeBeat.propTypes = {
  network: PropTypes.string,
  contractAddress: PropTypes.string,
  imageBaseURI: PropTypes.string,
  musicBaseURI: PropTypes.string,
}
export default RvltdTypeBeat
